import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../../http/http";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
const Add_Busines_Assisgn = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError, clearErrors,
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showAssisgnModal, setShowAssignModal, handleAssisgnData } = props;

    const addAssisgn = () => {
        http(
            {
                method: "POST",
                url: `staff/add`,
                isSecure: true,
                body: {
                    first_name: formValues.first_name,
                    middle_name: formValues.middle_name,
                    last_name: formValues.last_name,
                    email: formValues.email,
                    sin: formValues.sin,
                    date_of_birth: formValues.date_of_birth,
                },
            },
            (res) => {
                toast.success("Add Assisgn Successfully")
                setShowAssignModal(false);
                handleAssisgnData();
                reset();
            },
            (err) => {

            }
        );
    }
    return (

        <Modal
            show={showAssisgnModal}
            onHide={setShowAssignModal}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >


            <div className="modal-header px-4 pt-2 pb-0 border-0 justify-content-between">
                <h4 className="fs-20">Add Assign</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body px-4 pb-0">
                <form onSubmit={handleSubmit(addAssisgn)}>
                    <div className="modal-body p-0">

                        <div className="spouse-form row mt-2 input_wrap">
                            <div className="row mb-3">
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">First Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        id="first_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."

                                        {...register("first_name", {
                                            required: "Required",

                                        })}

                                    />
                                    {errors?.first_name && <span className="error text-start w-100">{errors?.first_name.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">Middle Name</label>
                                    <input
                                        type="text"
                                        name="middle_name"
                                        id="middle_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register("middle_name", {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">Last Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        id="last_name"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register("last_name", {
                                            required: "Required",

                                        })}

                                    />
                                    {errors?.last_name && <span className="error text-start w-100">{errors?.last_name.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">Email <span className="text-danger">*</span></label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register("email", { required: "Required" })}

                                    />
                                    {errors?.email && <span className="error text-start w-100">{errors?.email.message}</span>}
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">SIN</label>
                                    <input
                                        type="text"
                                        name="sin"
                                        id="sin"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register("sin", {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                    <label htmlFor="" className="fs-18">Date of Birth</label>
                                    <input
                                        type="date"
                                        name="date_of_birth"
                                        id="date_of_birth"
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register("date_of_birth", {})}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer border-0 px-0 pb-4 mt-4">
                        <button type="submit" className="btn-blue">Add</button>
                    </div>
                </form>

            </div>
        </Modal>
    )
}
export default Add_Busines_Assisgn;