import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';


const Directors_Details = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    // const businessId = JSON.parse(localStorage.getItem("BusinessId"));
    const { directorModal, setDirectorModal, fetchDirData } = props;
    // const [inputFields, setInputFields] = useState([{ id: 0 }]);
    const [inputFields, setInputFields] = useState([{ id: 0, address: "", city: "", province: "", postalCode: "" }]);
    const [filterSearchData, setFilterSearchData] = useState(null);

    const [searchTerm, setSearchTerm] = useState(''); // For search input value
    const [names, setNames] = useState([]); // For API response data
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null); // To handle errors
    const [showModal, setShowModal] = useState(false); // To control modal visibility
    const [selectedClient, setSelectedClient] = useState(null); // Store the selected client
    const [clientProfile, setClientProfile] = useState(null);
    const [linkDirectorData, setLinkDirectorData] = useState([])
    const [isLinked, setIsLinked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shareHolder, setShareHolder] = useState('');
    const [isClientSinVisible, setIsClientSinSinVisible] = useState(false);
    const [selectedLinkOption, setSelectedLinkOption] = useState("0");
    const [contactProfile, setContactProfile] = useState(null);
    const businessId = (() => {
        const storedId = localStorage.getItem("BusinessId");
        try {
            return storedId ? JSON.parse(storedId) : null; // Parse only if storedId exists
        } catch (e) {
            console.error("Failed to parse BusinessId from localStorage:", e);
            return null; // Fallback to null
        }
    })();

    // Function to handle radio button changes
    // Function to handle radio button changes
    const handleLinkOptionChange = (event) => {
        const selectedOption = event.target.value;


        setSelectedLinkOption(selectedOption);
        setSearchTerm('');
        setNames([]);
        setClientProfile(null); // Clear previous client profile
        setContactProfile(null); // Clear previous contact profile
    };
    // Function to handle search input changes
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value) {
            handleSinSearch(event.target.value); // Trigger API call on search
        }
    };
    // Function to make API request when user searches
    // const handleSinSearch = async (searchQuery) => {
    //     try {
    //         setLoading(true); // Set loading to true when API call starts
    //         http(
    //             {
    //                 method: "POST",
    //                 url: "clients/search/clientlistingbyname",
    //                 isSecure: true,
    //                 body: {
    //                     search: searchQuery, // Sending search query as the body
    //                 }
    //             },
    //             (res) => {
    //                 setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
    //                 setLoading(false); // Set loading to false after data is fetched
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 setError("Failed to fetch names"); // Set error message
    //                 setLoading(false); // Ensure loading is set to false on error
    //             }
    //         );
    //     } catch (err) {
    //         console.error(err);
    //         setError("Failed to fetch names"); // Set error message
    //         setLoading(false); // Ensure loading is set to false on error
    //     }
    // };

    // Function to make API request based on selected option (Individual or Contact)
    const handleSinSearch = async (searchQuery) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            const url =
                selectedLinkOption === "1"
                    ? "clients/search/clientlistingbyname" // API for Individual
                    : "contact/search/contactbyname";      // API for Contact

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        search: searchQuery, // Sending search query as the body
                    }
                },
                (res) => {
                    setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch names"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch names"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };

    // // Filter the names based on the search term, filtering by the `full_name` field
    // const filteredNames = names.filter((client) =>
    //     client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    // Filter the names based on the search term, considering both `full_name` for clients and `first_name` & `company_name` for contacts
    const filteredNames = names.filter((item) => {
        // Check if `full_name` exists (client) and filter based on it
        if (item.full_name) {
            return item.full_name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        // If `full_name` does not exist, check for `first_name` or `company_name` (contact) and filter based on them
        return (
            (item.first_name && item.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.company_name && item.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });

    // Handle clicking on a name and determine whether it's a client or contact
    const handleNameClick = (item) => {
        setSelectedClient(item);

        const isClient = item.hasOwnProperty("full_name");
        const profileType = isClient ? "client" : "contact";


        handleClientProfile(item.id, profileType);
        setShowModal(true);
    };

    // // Function to handle clicking on a name and show the modal
    // const handleNameClick = (client) => {
    //     setSelectedClient(client); // Store the clicked client's information
    //     handleClientProfile(client.id); // Fetch the client profile using the selected client's ID
    //     setShowModal(true); // Show the modal
    // };


    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedClient(null); // Clear the selected client when modal closes
    };


    // const handleAddFields = () => {
    //     const newInputFields = [...inputFields, { id: inputFields[inputFields.length - 1].id + 1 }];
    //     setInputFields(newInputFields);
    // };

    // const handleRemoveFields = (id) => {
    //     if (id === 0) return; // Prevent deleting the field with ID 0
    //     const newInputFields = inputFields.filter((field) => field.id !== id);
    //     setInputFields(newInputFields);
    // };

    const handleAddFields = () => {
        const newField = { id: inputFields[inputFields.length - 1].id + 1, address: "", city: "", province: "", postalCode: "" };
        setInputFields([...inputFields, newField]);
    };

    const handleRemoveFields = (id) => {
        if (id === 0) return;
        setInputFields(inputFields.filter((field) => field.id !== id));
    };

    const handleAddressChange = (id, value) => {
        setInputFields((fields) =>
            fields.map((field) => (field.id === id ? { ...field, address: value } : field))
        );
    };



    const handleSelectAddress = (id, selectedAddress) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: selectedAddress }, (results, status) => {
            if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;

                const getComponent = (type) =>
                    addressComponents.find((component) => component.types.includes(type))?.long_name || "";

                setInputFields((fields) =>
                    fields.map((field) =>
                        field.id === id
                            ? {
                                ...field,
                                address: selectedAddress,
                                city: getComponent("locality"),
                                province: getComponent("administrative_area_level_1"),
                                postalCode: getComponent("postal_code"),
                            }
                            : field
                    )
                );

                // Update form values for these fields for each director
                setValue(`directors[${id}].address`, selectedAddress);
                setValue(`directors[${id}].city`, getComponent("locality"));
                setValue(`directors[${id}].province`, getComponent("administrative_area_level_1"));
                setValue(`directors[${id}].postal_code`, getComponent("postal_code"));
            }
        });
    };


    const addDirector = () => {
        const DirectorData = formValues.directors.map(director => ({
            // Extracting data for each dependent from the formValues
            first_name: director.first_name,
            middle_name: director.middle_name,
            last_name: director.last_name,
            sin: director.sin,
            date_of_birth: director.date_of_birth,
            email: director.email,
            phone_number: director.phone_number,
            address: director.address,
            city: director.city,
            province: director.province,
            postal_code: director.postal_code,
            shareholder_part: director.shareholder_part,
            primary_contact: director.primary_contact,

        }));

        // Submit the dependents data to the server
        http(
            {
                method: "POST",
                url: `corporate/director/${id}/add`,
                body: DirectorData,
                isSecure: true,
            },
            (res) => {
                toast.success(res.message);
                setDirectorModal(false);
                fetchDirData();
                // Update state or perform any necessary actions with the response data
                reset({ directors: [{ first_name: '', middle_name: '', last_name: '', sin: '', date_of_birth: '', email: '', phone_number: '', address: '', city: '', province: '', postal_code: '', shareholder_part: '' }] }); // Reset form with empty fields
                setInputFields([{ id: 0 }]); // Reset input fieldst the form after successful submission
            },
            (err) => {
                console.error("Error Add Dependent:", err);
            }
        );
    };


    // // Function to make API request to get client profile
    // const handleClientProfile = (clientId) => {
    //     try {
    //         setLoading(true); // Set loading to true when API call starts
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `clients/${clientId}/view`, // API call with selected client's ID
    //                 isSecure: true
    //             },
    //             (res) => {
    //                 setClientProfile(res.data || []); // Store the client profile data
    //                 setLoading(false); // Set loading to false after data is fetched
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 setError("Failed to fetch client profile"); // Set error message
    //                 setLoading(false); // Ensure loading is set to false on error
    //             }
    //         );
    //     } catch (err) {
    //         console.error(err);
    //         setError("Failed to fetch client profile"); // Set error message
    //         setLoading(false); // Ensure loading is set to false on error
    //     }
    // };



    const handleClientProfile = (id, type) => {
        // Dynamic URL for fetching client or contact profile
        const url = type === "client" ? `clients/${id}/view` : `contact/${id}/view`;

        try {
            setLoading(true); // Set loading to true before making the API request
            http(
                {
                    method: "GET",
                    url,
                    isSecure: true,
                },
                (res) => {
                    // Depending on the type, set the appropriate profile data
                    if (type === "client") {
                        setClientProfile(res.data); // Set client profile data
                    } else {
                        setContactProfile(res.data); // Set contact profile data
                    }
                    setLoading(false); // Set loading to false after fetching the data
                },
                (err) => {
                    console.error(err);
                    setError(`Failed to fetch ${type} profile`);
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError(`Failed to fetch ${type} profile`);
            setLoading(false); // Ensure loading is set to false on error
        }
    };





    const onHideHandler = () => {
        setDirectorModal(false);
        setInputFields([{ id: 0 }]); // Reset the input fields to the initial state
        reset(); // Reset the form
    };




    // Handle input value change
    const handleInputChange = (e) => {
        setShareHolder(e.target.value); // Update state when input changes
    };

    // Handle form submission for adding a shareholder
    // const addShareHolderHandle = (e) => {
    //     e.preventDefault(); // Prevent default form submission behavior

    //     // Assuming http is your API handler function
    //     http(
    //         {
    //             method: "POST",
    //             url: `clients/add_data/${clientProfile?.id}`, // Updated URL
    //             isSecure: true,
    //             body: {
    //                 share_holder: shareHolder, // Sending shareHolder value from the state
    //             },
    //         },
    //         (res) => {
    //             toast.success("Shareholder added successfully");
    //             setIsModalOpen(false); // Close the modal after success
    //             setShareHolder(shareHolder); // Set the shareHolder state

    //             // Call handleClientProfile and pass clientProfile.id
    //             if (clientProfile?.id) {
    //                 handleClientProfile(clientProfile.id); // Make sure the clientProfile.id is passed
    //             }


    //         },
    //         (err) => {
    //             toast.error("Failed to add Shareholder");
    //         }
    //     );
    // };

    const addShareHolderHandle = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        http(
            {
                method: "POST",
                url: `clients/add_data/${clientProfile?.id}`, // Use template literal for dynamic URL
                isSecure: true,
                body: {
                    share_holder: shareHolder, // Sending shareHolder value from the state
                },
            },
            (res) => {
                toast.success("Shareholder added successfully");
                setIsModalOpen(false); // Close the modal after success
                setShareHolder(shareHolder); // Update the shareholder state

                // Trigger the client profile fetch after adding a shareholder
                if (clientProfile?.id) {
                    handleClientProfile(clientProfile.id, "client"); // Ensure "client" type is passed
                }
            },
            (err) => {
                toast.error("Failed to add Shareholder");
            }
        );
    };


    // Handle the "Link to Director" button click
    const handleButtonClick = () => {
        if (!clientProfile?.share_holder) {
            // If shareHolder is empty, open the modal
            setIsModalOpen(true);
        }
    };

    // Handle linking to director
    // const handleLinkDirector = () => {
    //     const profileId = clientProfile?.id || contactProfile?.id; // Use clientProfile ID if available, otherwise fallback to contactProfile

    //     if (!profileId) {
    //         toast.error("No valid profile selected to link to director");
    //         return; // Exit if no profile ID is available
    //     }
    //     try {
    //         setLoading(true); // Set loading to true when API call starts
    //         http(
    //             {
    //                 method: "POST",
    //                 url: `corporate/director/${profileId}/${businessId}/addclient`, // API call for linking
    //                 isSecure: true,
    //                 body: {
    //                     link_to: selectedLinkOption
    //                 }
    //             },
    //             (res) => {
    //                 toast.success(res.message);


    //                 setLinkDirectorData(res || []); // Assuming the response has a 'data' field with the names list
    //                 setIsLinked(true);
    //                 setLoading(false); // Set loading to false after data is fetched
    //                 onHideHandler();
    //                 setShowModal(false);
    //                 fetchDirData();
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 toast.error("Failed to link to Director");
    //                 setLoading(false); // Ensure loading is set to false on error
    //             }
    //         );
    //     } catch (err) {
    //         console.error(err);
    //         setLoading(false); // Ensure loading is set to false on error
    //     }
    // };


    const handleLinkDirector = () => {
        if (!businessId) {
            toast.error("BusinessId is not set or invalid in localStorage.");
            return; // Exit if no BusinessId is available
        }

        const profileId = clientProfile?.id || contactProfile?.id; // Use clientProfile ID if available, otherwise fallback to contactProfile

        if (!profileId) {
            toast.error("No valid profile selected to link to director");
            return; // Exit if no profile ID is available
        }

        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "POST",
                    url: `corporate/director/${profileId}/${businessId}/addclient`, // API call for linking
                    isSecure: true,
                    body: {
                        link_to: selectedLinkOption,
                        id: contactProfile?.id || clientProfile?.id // Dynamically assign id

                    }
                },
                (res) => {
                    toast.success(res.message);
                    setLinkDirectorData(res || []); // Assuming the response has a 'data' field with the names list
                    setIsLinked(true);
                    setLoading(false); // Set loading to false after data is fetched
                    onHideHandler();
                    setShowModal(false);
                    fetchDirData();
                },
                (err) => {
                    console.error(err);
                    toast.error("Failed to link to Director");
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setLoading(false); // Ensure loading is set to false on error
        }
    };

    // Function to toggle the visibility of SIN number
    const toggleClientSinVisibility = () => {
        setIsClientSinSinVisible(!isClientSinVisible); // Toggle the state
    };


    const renderClientDetails = () => (
        <>
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">
                            {(clientProfile ? clientProfile.first_name : contactProfile?.first_name) || '--'}{' '}
                            {(clientProfile ? clientProfile.last_name : contactProfile?.last_name) || '--'}
                        </h6>
                        <span className="d-flex align-items-center gap-2">
                            <img src={require("../../../assets/images/email.png")} className="emailicon" alt="" />
                            <p className="fs-14 text-muted">
                                {(clientProfile ? clientProfile.email : contactProfile?.email) || '--'}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <hr className="my-3" />
            <div className="row">
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">First Name</p>
                    <h4 className="fs-18">{clientProfile?.first_name ? clientProfile?.first_name : "--"}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Last Name</p>
                    <h4 className="fs-18">{clientProfile?.last_name ? clientProfile?.last_name : "--"}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Email</p>
                    <h4 className="fs-18">{clientProfile?.email || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">
                        SIN
                        {isClientSinVisible ? (
                            <img
                                src={require("../../../assets/images/eye-hide.png")}
                                className="cursor-pointer ms-2 w--14"
                                alt="Hide SIN"
                                onClick={toggleClientSinVisibility} // On click, hide the SIN
                            />
                        ) : (
                            <img
                                src={require("../../../assets/images/eye.png")}
                                className="cursor-pointer ms-2 w--14"
                                alt="Show SIN"
                                onClick={toggleClientSinVisibility} // On click, show the SIN
                            />
                        )}
                    </p>

                    <h4 className="fs-18">
                        {clientProfile?.sin
                            ? isClientSinVisible
                                ? clientProfile.sin // Show full SIN if visible
                                : clientProfile.sin.replace(/\d(?=\d{2})/g, "*") // Mask SIN except last 2 digits
                            : "--"}
                    </h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Phone No.</p>
                    <h4 className="fs-18">{clientProfile?.primary_contact_person || '--'}</h4>
                </div>

                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Street Address</p>
                    <h4 className="fs-18">{clientProfile?.office_location || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">City</p>
                    <h4 className="fs-18">{clientProfile?.city || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Province</p>
                    <h4 className="fs-18">{clientProfile?.province || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Postal Code</p>
                    <h4 className="fs-18">{clientProfile?.postal_code || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Shareholder (%)</p>
                    <h4 className="fs-18">{clientProfile?.share_holder || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Date of Birth</p>
                    <h4 className="fs-18">{clientProfile?.date_of_birth ? moment(clientProfile.date_of_birth).format('DD MMM, YYYY') : '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Date of Landing</p>
                    <h4 className="fs-18">{clientProfile?.date_of_landing ? moment(clientProfile.date_of_landing).format('DD MMM, YYYY') : '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Marital Status</p>
                    <h4 className="fs-18">{clientProfile?.marital_status ? (clientProfile.marital_status === "1" ? "Married" : "Single") : '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Family Head</p>
                    <h4 className="fs-18">{clientProfile?.family_head || '--'}</h4>
                </div>

                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Gender</p>
                    <h4 className="fs-18">{clientProfile?.gender ? (clientProfile.gender === "1" ? "Male" : "Female") : '--'}</h4>
                </div>
                <div className="d-flex justify-content-between gap-4 mt-4">
                    <button
                        type="button"
                        className="btn btn-gray text-dark mt-3"
                        onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}
                    >
                        Go to Profile
                    </button>
                    <div className="d-flex align-items-center gap-2">
                        {clientProfile?.share_holder ? (
                            <button
                                type="button"
                                className="btn btn-lightblue mt-3"
                                onClick={handleLinkDirector} // Call the link function directly
                            >
                                {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-gray mt-3"
                                onClick={handleButtonClick} // Open modal to enter shareholder info
                            >
                                Enter Shareholder
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );

    const renderContactDetails = () => (
        <>
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">
                            {(contactProfile ? contactProfile.first_name : contactProfile?.first_name) || '--'}{' '}
                            {(contactProfile ? contactProfile.last_name : contactProfile?.last_name) || '--'}
                            {contactProfile?.primary_contact === "1" && (
                                <span className="fs-12 text-blue"> Primary</span>
                            )}
                        </h6>

                        <span className="d-flex align-items-center gap-2">
                            <p className="fs-14 text-muted">{contactProfile?.designation?.name ? contactProfile?.designation?.name : "--"}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">First Name</p>
                    <h4 className="fs-18">{contactProfile?.first_name || "--"}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Last Name</p>
                    <h4 className="fs-18">{contactProfile?.last_name || "--"}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Email</p>
                    <h4 className="fs-18">{contactProfile?.email || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Phone No.</p>
                    <h4 className="fs-18">{contactProfile?.phone_number || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Designation</p>
                    <h4 className="fs-18">{contactProfile?.designation?.name || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Street Address</p>
                    <h4 className="fs-18">{contactProfile?.address || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">City</p>
                    <h4 className="fs-18">{contactProfile?.city || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Province</p>
                    <h4 className="fs-18">{contactProfile?.province || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Postal Code</p>
                    <h4 className="fs-18">{contactProfile?.postal_code || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Notes</p>
                    <h4 className="fs-18">{contactProfile?.notes || '--'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Shareholder (%)</p>
                    <h4 className="fs-18">{contactProfile?.share_holder || '--'}</h4>
                </div>
                <div className="d-flex justify-content-between gap-4 mt-4">
                    {/* <button
                        type="button"
                        className="btn btn-gray text-dark mt-3"
                        onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}
                    >
                        Go to Profile
                    </button> */}
                    <div className="d-flex align-items-center gap-2">
                        {/* {clientProfile?.share_holder ? (
                            <button
                                type="button"
                                className="btn btn-lightblue mt-3"
                                onClick={handleLinkDirector} // Call the link function directly
                            >
                                {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-gray mt-3"
                                onClick={handleButtonClick} // Open modal to enter shareholder info
                            >
                                Enter Shareholder
                            </button>
                        )} */}
                        <button
                            type="button"
                            className="btn btn-lightblue mt-3"
                            onClick={handleLinkDirector} // Call the link function directly
                        >
                            {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
    return (
        <>
            <Modal
                show={directorModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <div className="modal-header border-0 justify-content-between align-items-center">
                    <h4 className="fs-20 fw-bold">Add Executive Details</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <div className="row mt-2">
                        <div className="col-md-6 dctor-search">
                            <label htmlFor="">Link To</label>
                            <div className="d-flex align-items-center gap-4 mt-2">
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="custom"
                                        value="0"
                                        checked={selectedLinkOption === "0"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="custom" className="fs-14">Custom</label>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="individual"
                                        value="1"
                                        checked={selectedLinkOption === "1"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="individual" className="fs-14">Individual</label>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="contact"
                                        value="2"
                                        checked={selectedLinkOption === "2"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="contact" className="fs-14">Contact</label>
                                </div>
                            </div>
                        </div>
                        {selectedLinkOption !== "0" && (
                            <div className="col-md-6 dctor-search">
                                <label htmlFor="">Search</label>
                                <div className="position-relative mt-2">
                                    <input
                                        type="text"
                                        className="form-input dtr-srch"
                                        placeholder="Search for a name"
                                        value={searchTerm}
                                        onChange={handleSearch} // Handle input changes
                                    />
                                    <i className="fa fa-search"></i>
                                    <i className="fa fa-times" onClick={() => setSearchTerm("")}></i>
                                    {searchTerm && (
                                        <ul>
                                            {loading ? (
                                                <li>Loading...</li>
                                            ) : error ? (
                                                <li>{error}</li>
                                            ) : filteredNames.length > 0 ? (
                                                filteredNames.map((item) => (
                                                    <li key={item.id} onClick={() => handleNameClick(item)}>
                                                        {item.full_name ? (
                                                            <>
                                                                <strong>{item.full_name}</strong>

                                                            </>
                                                        ) : (
                                                            <>
                                                                <strong>{item.first_name}</strong>
                                                                <strong>{item.company_name}</strong>
                                                            </>
                                                        )}
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No names found</li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}

                    </div>
                    <form onSubmit={handleSubmit(addDirector)}>
                        {inputFields.map((inputField, index) => (
                            <div className="row mt-4" key={inputField.id}>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">First Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].first_name`}
                                        id={`first_name${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].first_name`, { required: "Required", })}

                                    />
                                    {errors?.directors?.[index]?.first_name && (
                                        <span className="error">{errors.directors[index].first_name.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Middle Name</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].middle_name`}
                                        id={`middle_name${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].middle_name`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].last_name`}
                                        id={`last_name${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].last_name`, { required: "Required", })}
                                    />
                                    {errors?.directors?.[index]?.last_name && (
                                        <span className="error">{errors.directors[index].last_name.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Date of Birth</label>
                                    <input
                                        type="date"
                                        name={`directors[${index}].date_of_birth`}
                                        id={`date_of_birth${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].date_of_birth`, {})}

                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                    <input
                                        type="email"
                                        name={`directors[${index}].email`}
                                        id={`email${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].email`, { required: "Required", })}
                                    />
                                    {errors?.directors?.[index]?.email && (
                                        <span className="error">{errors.directors[index].email.message}</span>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Phone No.</label>
                                    <input
                                        type="number"
                                        name={`directors[${index}].phone_number`}
                                        id={`phone_number${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].phone_number`, {})}

                                    />
                                </div>
                                <div className="col-lg-8 col-md-12 mt-4">
                                    <label className="fs-18">Street Address</label>
                                    <PlacesAutocomplete
                                        value={inputField.address}
                                        onChange={(value) => handleAddressChange(inputField.id, value)}
                                        onSelect={(address) => handleSelectAddress(inputField.id, address)}
                                        searchOptions={{ componentRestrictions: { country: ["us", "ca"] } }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                            <div>
                                                <input
                                                    {...getInputProps({
                                                        placeholder: "Type address here...",
                                                        className: "form-input mt-2 google-address",
                                                    })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div>Loading...</div>}
                                                    {suggestions.map((suggestion) => {
                                                        const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, { className })}
                                                                key={suggestion.placeId}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>

                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Province</label>
                                    <input
                                        className="form-input mt-2"
                                        value={inputField.province}
                                        readOnly
                                        {...register(`directors[${inputField.id}].province`)}
                                    />
                                </div>

                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Postal Code</label>
                                    <input
                                        type="text"
                                        className="form-input mt-2"
                                        value={inputField.postalCode}
                                        readOnly
                                        {...register(`directors[${inputField.id}].postal_code`)}
                                    />
                                </div>

                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">City</label>
                                    <input
                                        type="text"
                                        className="form-input mt-2"
                                        value={inputField.city}
                                        readOnly
                                        {...register(`directors[${inputField.id}].city`)}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Shareholder(%)</label>
                                    <input
                                        type="text"
                                        name={`directors[${index}].shareholder_part`}
                                        id={`shareholder_part${inputField.id}`}
                                        className="form-input mt-2"
                                        placeholder="Type here..."
                                        {...register(`directors[${index}].shareholder_part`, {})}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 mt-3">
                                    <label className="fs-18">Primary Contact Number</label>
                                    <select
                                        className="form-input mt-2"
                                        name={`directors[${index}].primary_contact`} // Update name to match structure
                                        id={`primary_contact${inputField.id}`}
                                        {...register(`directors[${index}].primary_contact`)} // Register the field to be included in the form data
                                    >
                                        <option disabled>Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0" selected>No</option>
                                    </select>
                                </div>

                                <div className="col-lg-8 col-sm-12 mt-4 text-end align-content-center">
                                    {index !== 0 && <button type="button" onClick={() => handleRemoveFields(inputField.id)} className="text-danger bg-transparent border-0 mt-4">
                                        <i className="fa fa-trash me-1 text-danger"></i>
                                        Remove</button>}
                                </div>
                            </div>
                        ))}
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <button type="button" onClick={handleAddFields} className="p-0 add_field_button bg-transparent border-0 d-flex align-items-center gap-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 17.998">
                                        <g id="add" transform="translate(0.002 -0.003)">
                                            <path id="Subtraction_3" data-name="Subtraction 3" d="M9,18a9,9,0,1,1,9-9A9.011,9.011,0,0,1,9,18ZM5.515,8.238a.7.7,0,0,0,0,1.394H8.3V12.42a.7.7,0,1,0,1.394,0V9.632h2.786a.7.7,0,1,0,0-1.394H9.7V5.45a.7.7,0,0,0-1.394,0V8.238Z" transform="translate(-0.002 0.003)" fill="#004fc5" />
                                        </g>
                                    </svg>
                                    <p className="text-blue fw-semibold fs-14 text-end w-fit ms-auto cursor-pointer">Add More</p>
                                </button>
                            </div>
                        </div>
                        <div className="modal-footer border-0 px-0 pb-2 mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <a ><button className="btn-blue px-4 mb-2">Add</button></a>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="lg"
                contentClassName="mx-auto p-4 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                <div className="services-form row">
                    <div className="col-lg-12 p-0">
                        <form>
                            {clientProfile ? renderClientDetails() : contactProfile ? renderContactDetails() : <p>No details available</p>}
                        </form>
                    </div>
                </div>
            </Modal>
            <Modal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                centered
                size="md"
                contentClassName="mx-auto p-3 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <h4 className="fs-20">Add ShareHolder</h4>
                    <Modal.Header closeButton className="close-icon border-0 pe-2 pb-0"></Modal.Header>
                </div>

                <div className="services-form row">
                    <div className="col-lg-12 mt-3 p-0">
                        <form onSubmit={addShareHolderHandle}>
                            <div className="modal-footer border-0 justify-content-center p-0">
                                <label htmlFor="share_holder">Share Holder</label>
                                <input
                                    name="share_holder"
                                    id="share_holder"
                                    className="form-input mx-0 mt-1"
                                    type="text"
                                    value={shareHolder} // Controlled input bound to state
                                    onChange={handleInputChange} // Update state on input change
                                />
                            </div>
                            <button type="submit" className="btn-blue ms-auto mt-3">
                                Add
                            </button>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Directors_Details;