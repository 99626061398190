// App.js

class Auth {
    authenticated = false;
    key = "__assist_erp";

    login(data) {
        const user = JSON.stringify(data);
        localStorage.setItem(this.key, user);

        if (user) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    userInfo() {
        let user = JSON.parse(localStorage.getItem(this.key));
        return user;
    }

    token() {
        const user = JSON.parse(localStorage.getItem(this.key));
        const accessToken = user && user.access_token;
        return accessToken && accessToken.trim();
    }

    logout() {
        localStorage.removeItem(this.key);
        this.authenticated = false;
    }

    isUserAuthenticated() {
        return localStorage.getItem(this.key) ? true : false;
    }
    setLocalPermission(data) {
        const permission = JSON.stringify(data);
        localStorage.setItem("permission", permission);
    }

    setLocalPermissionIds(data) {
        const permissionIds = JSON.stringify(data);
        localStorage.setItem("permissionIds", permissionIds);
    }


    getPermissions() {
        // Retrieve the object from localStorage
        const storedData = localStorage.getItem("permission");

        if (!storedData) {

            return [];
        }

        try {
            // Parse the JSON data
            const parsedData = JSON.parse(storedData);
            const permissions = parsedData?.permissions || []; // Extract the permissions array

            return permissions;
        } catch (error) {
            console.error("Error parsing permissions from localStorage", error);
            return [];
        }
    }

    getPermissionsIds() {
        let permissionsIds = JSON.parse(localStorage.getItem("permissionIds")) || [];
        return permissionsIds;
    }

    // hasModulePermission(module) {
    //     // Parse permissions and ensure it is an array
    //     const permissions = this.getPermissions() || [];
    //     const validPermissions = Array.isArray(permissions) ? permissions : [];
    //     const permissionIds = this.getPermissionsIds() || [];
    //     const user = this.userInfo() || {};
    //     const hasFullAccess = permissionIds.some((id) => id?.id === user?.user?.id);
    //     if (!hasFullAccess) {
    //         return true;
    //     } else {
    //         // Group permissions by module
    //         const dynamicPermissions = validPermissions.reduce((acc, perm) => {
    //             const [permModule] = perm.split("_");
    //             (acc[permModule] = acc[permModule] || []).push(perm);
    //             return acc;
    //         }, {});
    //         const modulePermissions = dynamicPermissions[module] || [];
    //         const hasPermission = validPermissions.some((perm) => modulePermissions.includes(perm));
    //         // const hasFullAccess = !permissionIds.some((id) => id?.id == user.id);
    //         return hasPermission && hasFullAccess;
    //     }
    // }

    hasModulePermission(module) {
        // Parse permissions and validate them
        const permissions = Array.isArray(this.getPermissions()) ? this.getPermissions() : [];

        const permissionIds = this.getPermissionsIds() || [];

        const user = this.userInfo() || {};

        // Check if the user does not match any ID in permissionIds (grants full access)
        if (!permissionIds.some((id) => id?.id === user?.user?.id)) {
            return true;
        }

        // Group permissions by module
        const dynamicPermissions = permissions.reduce((acc, perm) => {
            const [permModule] = perm.split("_");
            (acc[permModule] = acc[permModule] || []).push(perm);
            return acc;
        }, {});


        // Check module-specific permissions
        const modulePermissions = dynamicPermissions[module] || [];

        const hasPermission = modulePermissions.some((perm) => permissions.includes(perm));

        return hasPermission;
    }



    innerPermission(type) {
        const permissions = Array.isArray(this.getPermissions()) ? this.getPermissions() : [];
        const permissionIds = this.getPermissionsIds() || [];
        const user = this.userInfo() || {};

        const hasTaskPermission = permissions.includes(type);

        const hasTaskFullAccess = permissionIds.every((id) => id.id !== user?.user?.id);


        return hasTaskPermission || hasTaskFullAccess;
    }
}

export default new Auth();
