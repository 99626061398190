import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    userProfileData,
    selectUser,
} from "../../../reduxTool/features/userSlice";
import { http } from "../../http/http";
import { Modal } from "react-bootstrap";

const SuperAdminHeader = ({ toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showEditModal, setShowEditModal] = useState(false);
    const [picture, setPicture] = useState({});
    const profileData = useSelector(
        (state) => state.user?.userProfileData?.data
    );
    const [errorMessage, setErrorMessage] = useState({
        first_name: "",
        last_name: "",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
    });
    const data = JSON.parse(localStorage.getItem("__assist_erp"));
    const [userData, setUserData] = useState(
        JSON.parse(localStorage.getItem("ProfileData")) || null
    );
    const [formValues, setFormValues] = useState({
        first_name: userData?.user?.first_name || "",
        last_name: userData?.user?.last_name || "",
        name: userData?.user?.name || "",
        email: userData?.user?.email || "",
        image: userData?.user?.image || "",
        password: userData.user?.password || "",
        password_confirmation: userData.user?.password_confirmation || "",
    });
    useEffect(() => {
        localStorage.setItem(
            "ProfileData",
            JSON.stringify({ user: formValues })
        );
    }, [formValues]);

    useEffect(() => {
        const storedUserData = JSON.parse(localStorage.getItem("ProfileData"));
        if (storedUserData) {
            setUserData(storedUserData);
            setFormValues({
                first_name: storedUserData.user?.first_name || "",
                last_name: storedUserData.user?.last_name || "",
                name: storedUserData.user?.name || "",
                email: storedUserData.user?.email || "",
                image: storedUserData.user?.image || "",
                password: storedUserData.user?.password || "",
                password_confirmation:
                    storedUserData.user?.password_confirmation || "",
            });
        }
    }, []);

    useEffect(() => {
        const storedImageUrl = localStorage.getItem("ProfileImageData");
        if (storedImageUrl) {
            setPicture({ url: storedImageUrl, file: null });
        }
    }, []);

    const onChangePicture = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setPicture({
                url: imageUrl,
                file: file,
            });

            setFormValues({
                ...formValues,
                image: imageUrl,
            });

            localStorage.setItem("ProfileImageData", imageUrl);
        } else {
            const storedImageUrl = localStorage.getItem("ProfileImageData");
            if (storedImageUrl) {
                setPicture({ url: storedImageUrl, file: null });
            }
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

        // Trigger validation on keyup event
        validateField(name, value);
    };
    const validateField = (fieldName, value) => {
        if (value.trim() === "") {
            setErrorMessage({
                ...errorMessage,
                [fieldName]: "This field is required.",
            });
        } else {
            setErrorMessage({
                ...errorMessage,
                [fieldName]: "",
            });
        }
    };
    const validateForm = () => {
        let isValid = true;

        Object.values(errorMessage).forEach((error) => {
            if (error.trim() !== "") {
                isValid = false;
            }
        });

        return isValid;
    };

    const userUpdateProfile = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        const formData = new FormData();
        if (picture.file) {
            formData.append("image", picture.file);
        }
        formData.append("first_name", formValues.first_name);
        formData.append("email", formValues.email);
        formData.append("last_name", formValues.last_name);
        formData.append("name", formValues.name);
        formData.append("password", formValues.password);
        formData.append(
            "password_confirmation",
            formValues.password_confirmation
        );

        const authToken = data?.access_token;

        const headers = {
            Authorization: `Bearer ${authToken}`,
        };

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/superadmin/profile-update`,
                formData,
                {
                    headers: {
                        ...headers,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status == 200) {
                dispatch(userProfileData(response.data));
                toast.success(response.data.message);
                setShowEditModal(false);
                // navigate("/superAdmin/edit_profile");
            }
        } catch (error) {
            console.error(error);
        }

        // Call fetchData after saving changes
        // fetchData();
    };

    // fetchData function moved outside of useEffect
    useEffect(() => {
        const fetchData = async () => {
            const authToken = data?.access_token;

            if (!authToken) {
                console.error("No auth token available.");
                return;
            }

            const headers = {
                Authorization: `Bearer ${authToken}`,
            };

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/superadmin/profile/1`,
                    { headers }
                );
                dispatch(userProfileData(response.data));
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchData(); // Call the function here
    }, [userProfileData]);

    useEffect(() => {
        // Fetch user profile data when component mounts
        dispatch(userProfileData());
    }, [dispatch]);

    const handleAdminLogout = () => {
        http(
            {
                method: "POST",
                url: "superadmin/logout",
                isSecure: true,
            },
            (res) => {
                if (res.status_code == 200) {
                    Auth.logout();
                    navigate("/superAdmin/login");
                    toast.success("logout Successfully.");
                }
            },
            (err) => {
                if (err.status_code == 401) {
                    navigate("/superAdmin/login");
                }
            }
        );
    };

    const showHandleEdit = () => {
        setShowEditModal(true);
    };
    const hideEditModal = () => {
        setShowEditModal(false);
    };

    const appendBaseURL = (imagePath) => {
        return imagePath ? `https://assisterp.com/backend/${imagePath}` : "";
    };
    // Define the breadcrumb based on the current location
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const renderBreadcrumb = () => {
        const pathnames = location.pathname.split("/").filter((x) => x); // Split the path and remove empty elements
        const startIndex = pathnames.indexOf("superAdmin") + 1; // Find the index of 'admin' and start rendering from the next path

        return (
            <nav aria-label="breadcrumb">
                <ol className="flex breadcrumb align-items-center mb-0">
                    {pathnames.slice(startIndex).map((path, index) => {
                        const routeTo = `/${pathnames
                            .slice(0, startIndex + index + 1)
                            .join("/")}`;
                        const isLast = index === pathnames.length - 1;
                        const displayName = capitalizeFirstLetter(path);
                        return (
                            <li
                                key={index}
                                className={`breadcrumb-item ${isLast ? "active" : ""
                                    }`}
                                aria-current={isLast ? "page" : undefined}
                            >
                                {isLast ? (
                                    <h6 className="mb-0 d-inline ">
                                        {displayName}
                                    </h6>
                                ) : (
                                    <Link
                                        to={routeTo}
                                        className="-link-dark text-decoration-none text-black"
                                    >
                                        {displayName}
                                    </Link>
                                )}
                            </li>
                        );
                    })}
                </ol>
            </nav>
        );
    };
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent py-2 px-4 header-main font-poppins bg-white">
            <div className="mobile-menu">
                <i
                    className="fas fa-align-left primary-text fs-4 me-3"
                    id="menu-toggle"
                    onClick={toggleSidebar}
                ></i>
            </div>

            <div className="left-header d-flex align-items-center">
                <h2 className="m-0 fs-24"> {renderBreadcrumb()}</h2>
            </div>

            <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 right-header d-flex align-items-center ">
                    <li>
                        <div className="messageicon position-relative">
                            <img
                                src={require("../../../assets/images/message.png")}
                                alt=""
                            />
                        </div>
                    </li>
                    <hr />
                    <li>
                        <div className="bellicon position-relative">
                            <img
                                src={require("../../../assets/images/bell.png")}
                                alt=""
                            />
                            <span className="d-block rounded-circle position-absolute top-0 end-0"></span>
                        </div>
                    </li>
                    <hr />

                    <li className="nav-item dropdown dropdown-profile">
                        <a
                            className="nav-link dropdown-toggle second-text fw-bold d-flex align-items-center profile"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div className="user-name me-2">
                                <h4 className="text-black">
                                    {profileData ? profileData?.name : "User"}
                                </h4>
                                <p>Super Admin </p>
                            </div>
                            {profileData && profileData.image ? (
                                <img
                                    className="userpicture"
                                    src={appendBaseURL(profileData.image)}
                                    alt="Profile"
                                />
                            ) : (
                                <img
                                    src={require("../../../assets/images//user-pic.png")}
                                    alt="Default"
                                />
                            )}
                        </a>

                        <ul
                            className="dropdown-menu position-absolute ps-2 pe-3 drop-profile"
                            aria-labelledby="navbarDropdown"
                        >
                            <li className="profile d-flex align-items-center justify-content-between">
                                <div className="left-dropprofile d-flex align-items-center">
                                    <img
                                        className="userpicture"
                                        src={require("../../../assets/images/user-pic.png")}
                                        alt=""
                                    />
                                    <div className="user-name ms-2">
                                        <h4 className="text-black">
                                            Kewin William
                                        </h4>
                                        <p>Super Admin</p>
                                    </div>
                                </div>
                                <img
                                    src={require("../../../assets/images/drop_up.png")}
                                    className="hide-profile"
                                    alt=""
                                />
                            </li>
                            <li>
                                <span className="d-flex align-items-center ">
                                    <img
                                        src={require("../../../assets/images/email.png")}
                                        className="me-2 my-3"
                                        alt=""
                                    />
                                    kewinwilliam88@gmail.com
                                </span>
                            </li>
                            <li className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 edit">
                                <div
                                    className="dropdown-item"
                                    onClick={() => showHandleEdit()}
                                >
                                    Edit Profile
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.001"
                                    height="16"
                                    viewBox="0 0 16.001 16"
                                >
                                    <g
                                        id="write"
                                        transform="translate(-7.998 -8)"
                                    >
                                        <path
                                            id="Path_3528"
                                            data-name="Path 3528"
                                            d="M8.341,27.144l-.336,2.779a.835.835,0,0,0,.832.945.821.821,0,0,0,.106-.007l2.779-.336a2.648,2.648,0,0,0,1.543-.758l7.2-7.2a.333.333,0,0,0,0-.471l-3.694-3.7a.333.333,0,0,0-.471,0L9.1,25.6a2.648,2.648,0,0,0-.758,1.543Z"
                                            transform="translate(0 -6.868)"
                                        />
                                        <path
                                            id="Path_3529"
                                            data-name="Path 3529"
                                            d="M42.715,10.23,40.992,8.506a1.728,1.728,0,0,0-2.444,0L36.93,10.124a.333.333,0,0,0,0,.471l3.7,3.7a.333.333,0,0,0,.471,0l1.618-1.618A1.728,1.728,0,0,0,42.715,10.23Z"
                                            transform="translate(-19.223 0)"
                                        />
                                        <path
                                            id="Path_3530"
                                            data-name="Path 3530"
                                            d="M38,52.667A.667.667,0,0,0,37.333,52H29.667a.667.667,0,0,0,0,1.333h7.667A.667.667,0,0,0,38,52.667Z"
                                            transform="translate(-14.001 -29.333)"
                                        />
                                    </g>
                                </svg>
                            </li>
                            <li
                                className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 change"
                                data-bs-toggle="modal"
                                data-bs-target="#change-password"
                            >
                                <div className="dropdown-item">
                                    Change Password
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.515"
                                    height="16"
                                    viewBox="0 0 13.515 16"
                                >
                                    <path
                                        id="Path_3567"
                                        data-name="Path 3567"
                                        d="M4009.646,845.272v1.219a3.269,3.269,0,0,1,1.7,1.183,3.31,3.31,0,0,1,.668,1.964v3.925a3.379,3.379,0,0,1-.271,1.282,3.334,3.334,0,0,1-.74,1.081,3.353,3.353,0,0,1-2.385.956H4001.9a3.351,3.351,0,0,1-2.385-.956,3.342,3.342,0,0,1-.74-1.081,3.388,3.388,0,0,1-.271-1.282v-3.925a3.3,3.3,0,0,1,.668-1.963,3.347,3.347,0,0,1,1.7-1.185v-1.219a4.4,4.4,0,0,1,2.711-4.055,4.382,4.382,0,0,1,4.778.952,4.373,4.373,0,0,1,1.288,3.1Zm-4.38-2.907a2.942,2.942,0,0,1,2.965,2.907v1.048h-5.947v-1.064a2.939,2.939,0,0,1,2.982-2.895Zm.7,10.109a.7.7,0,0,1-.207.5.707.707,0,0,1-1,0,.7.7,0,0,1-.207-.5v-1.754a.7.7,0,0,1,.207-.5.708.708,0,0,1,1,0,.7.7,0,0,1,.207.5Z"
                                        transform="translate(-3998.5 -840.883)"
                                        fillRule="evenodd"
                                    />
                                </svg>
                            </li>
                            <li className="bg-gray-li mt-1 py-2 d-flex align-items-center justify-content-between pe-3 logout">
                                <div
                                    className="dropdown-item"
                                    onClick={handleAdminLogout}
                                >
                                    Logout
                                </div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16.624"
                                    height="16.001"
                                    viewBox="0 0 16.624 16.001"
                                >
                                    <path
                                        id="Path_3568"
                                        data-name="Path 3568"
                                        d="M1239.32,1285.384a.616.616,0,0,0,0,1.232h4.88v3.824a3.718,3.718,0,0,1-.288,1.376,3.547,3.547,0,0,1-.784,1.152,3.48,3.48,0,0,1-1.176.768,3.537,3.537,0,0,1-1.376.264h-3.96a3.538,3.538,0,0,1-1.376-.264,3.634,3.634,0,0,1-1.168-.76,3.638,3.638,0,0,1-1.072-2.528v-8.888a3.72,3.72,0,0,1,.288-1.376,3.551,3.551,0,0,1,.784-1.152,3.484,3.484,0,0,1,1.176-.768,3.542,3.542,0,0,1,1.376-.264h3.968a3.592,3.592,0,0,1,3.608,3.552v3.832Zm7.784-2.152,2.336,2.328a.684.684,0,0,1,.136.2.643.643,0,0,1,.048.24.575.575,0,0,1-.048.232.592.592,0,0,1-.136.2l-2.336,2.328a.624.624,0,0,1-.432.168.6.6,0,0,1-.608-.608.624.624,0,0,1,.168-.432l1.28-1.272H1244.2v-1.232h3.312l-1.28-1.272a.608.608,0,0,1,0-.872.59.59,0,0,1,.2-.136.574.574,0,0,1,.232-.048.629.629,0,0,1,.24.04A.681.681,0,0,1,1247.1,1283.232Z"
                                        transform="translate(-1233 -1277.999)"
                                    />
                                </svg>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <Modal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                backdrop="static"
                centered
                className="profile-modal font-poppins"
            >
                <Modal.Header closeButton className="border-0"></Modal.Header>

                <div className="modal-body edit_prof">
                    <div className="pop-head">
                        <h4>Edit Profile</h4>
                        <p>
                            Please update your profile information in the
                            section provided below.
                        </p>
                    </div>
                    <form onSubmit={userUpdateProfile}>
                        <div className="pop-form mt-2 row">
                            <div className="col-md-12 mt-2">
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            name="image"
                                            accept="image/png, image/gif, image/jpeg"
                                            className="cursor-pointer opacity-0 position-absolute top-0 bottom-0 start-0 end-0 m-auto input-file"
                                            onChange={onChangePicture}
                                        />
                                        <label for="imageUpload"></label>
                                    </div>
                                    <div className="avatar-preview">
                                        <div id="imagePreview">
                                            {picture.url ? (
                                                <img
                                                    width="100%"
                                                    src={picture.url}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/user.png")}
                                                    alt="user"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-2">
                                <div className="form-group input-group position-relative mt-3">
                                    <label for="password" className="mb-1">
                                        First Name
                                    </label>
                                    <div className="icon-input w-100 rounded mt-2 position-relative">
                                        <img
                                            src={require("../../../assets/images/user.png")}
                                            alt="userIcon"
                                            className="position-absolute"
                                        />
                                        <input
                                            type="text"
                                            className="form-control border-0 py-3 w-100 bg-red"
                                            placeholder="First name"
                                            onChange={handleChange}
                                            name="first_name"
                                            value={formValues.first_name}
                                        />
                                        {errorMessage.first_name && (
                                            <p className="text-danger">
                                                {errorMessage.first_name}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mt-2">
                                <div className="form-group input-group position-relative mt-3">
                                    <label for="password" className="mb-1">
                                        Last Name
                                    </label>
                                    <div className="icon-input w-100 rounded mt-2 position-relative">
                                        <img
                                            src={require("../../../assets/images/user.png")}
                                            alt="userIcon"
                                            className="position-absolute"
                                        />
                                        <input
                                            type="text"
                                            className="form-control border-0 py-3 w-100 bg-red"
                                            placeholder="Last name"
                                            onChange={handleChange}
                                            name="last_name"
                                            value={formValues.last_name}
                                        />
                                        {errorMessage.last_name && (
                                            <p className="text-danger">
                                                {errorMessage.last_name}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="btn-groups mt-4">
                                <button
                                    type="button"
                                    class="btn cancel me-2"
                                    onClick={() => hideEditModal()}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn save-btn ms-2"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </nav>
    );
};
export default SuperAdminHeader;
