import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import { http } from "../../http/http";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import moment from "moment";
import { GlobalContext } from "../../../App";
import JoditEditor from "jodit-react";
import Auth from "../../../auth/Auth";

const config = {
    readonly: false, // Set to true if you want the editor to be read-only
    useSearch: false,
    spellcheck: false,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    minWidth: null,
    buttons: "bold,strikethrough,underline,italic,|,font,fontsize,brush,link,|,ul,ol,align,undo,redo",
    placeholder: "",
    controls: {
        fontsize: {
            list: ["8", "9", "10", "11", "12", "14", "16", "18", "24", "30", "36", "48", "60", "72", "96", "100"],
        },
        font: {
            command: "fontname",
            list: {
                "": "Default",
                "'Open Sans',sans-serif": "Open Sans",
                "Helvetica,sans-serif": "Helvetica",
                "Arial,Helvetica,sans-serif": "Arial",
                "Georgia,serif": "Georgia",
                "Impact,Charcoal,sans-serif": "Impact",
                "Tahoma,Geneva,sans-serif": "Tahoma",
                "'Times New Roman',Times,serif": "Times New Roman",
                "Verdana,Geneva,sans-serif": "Verdana",
            },
        },
    },
};
const Recurring_Task_Details = () => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
        setError,
        clearErrors,
        watch,
    } = useForm({ mode: "all" });

    const watchedStaffIds = watch("staff_id", []);
    const navigate = useNavigate();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [taskViewData, setTaskViewData] = useState([]);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [editTaskDetail, setEditTaskDetail] = useState({
        documents: [],
        start_date: "", // Ensure start_date is initialized
        end_date: "",
        start_time: "",
        end_time: "",
        estimation_time: "",
        time_spent: "",
        recurring: {
            reccuring_start_date: "",
            reccuring_email_automatic: "",
            reccuring_status: "",
            reccuring_end_date: "", // Prefill with existing value
            reccuring_expire: "", // Prefill with existing value ("1" for checked, "0" for unchecked)
            // Other recurring details...
        },
    });

    const [recurringRepeat, setRecurringRepeat] = useState("");

    const [deleteImageIDs, setDeleteImageIDs] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });

    const [assigneeIds, setAssigneeIds] = useState([]);
    const [showEditAssignOpen, setShowEditAssignOpen] = useState(false);
    const [interactionList, setInteractionList] = useState([]);
    const [priorityList, setPriorityList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [comment, setComment] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const userID = JSON.parse(localStorage.getItem("__assist_erp"));
    const location = useLocation();
    const { type } = location.state || {};
    const [showInderaction, setShowInderaction] = useState(false);
    const [showPriority, setShowPriority] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [assigned, setAssigned] = useState(false);
    const [showAssignOpen, setShowAssignOpen] = useState(false);
    const [showAssisgnModal, setShowAssignModal] = useState(false);
    const [pendingUpdate, setPendingUpdate] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState([]);
    const [subTaskList, setSubTAskList] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [searchSubTaskTerm, setSearchSubTaskTerm] = useState("");
    const initialSelectedOption = interactionList.find((option) => option.id === taskDetails.interaction_type_id);
    const initialPrioritySelectedOption = priorityList.find((option) => option.id === taskDetails.priority_id);
    const initialStatusSelectedOption = statusList.find((option) => option.id === taskDetails.status_task_id);
    const [dropdownState, setDropdownState] = useState({
        isOpen: false,
        search: "",
        selectedOption: initialSelectedOption,
        isPriorityOpen: false,
        prioritySearch: "",
        prioritySelectedOption: initialPrioritySelectedOption || null,
        isStatusOpen: false,
        statusSearch: "",
        statusSelectedOption: initialStatusSelectedOption || null,
    });

    const [timeFormats, setTimeFormats] = useState({
        start_time_format: "AM",
        end_time_format: "AM",
    });

    const [activeTab, setActiveTab] = useState("subtasks");
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [selectedAssignee, setSelectedAssignee] = useState("All");
    const [selectedPriority, setSelectedPriority] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [openDropdownStatusId, setOpenDropdownStatusId] = useState(null);
    const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
    const [filteredTasks, setFilteredTasks] = useState(subTaskList);
    const [filteredAssignees, setFilteredAssignees] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const isDropdownOpen = (taskId) => openDropdownStatusId === taskId;
    const displayAssignedToLabel = selectedAssignee === "All" ? "Assigned" : selectedAssignee;

    const style = {
        backgroundColor: taskViewData?.priority?.color_code || "#ffffff", // Default to white if color_code is not available
        padding: "5px 10px",
        borderRadius: "5px",
        color: "#fff", // Assuming text color should be white for better readability
        display: "inline-block",
    };
    // const handleDropdownOpen = (type) => {
    //     setDropdownState(prevState => ({
    //         ...prevState,
    //         isOpen: type === 'interaction' ? !prevState.isOpen : false,
    //         isPriorityOpen: type === 'priority' ? !prevState.isPriorityOpen : false,
    //         isStatusOpen: type === 'status' ? !prevState.isStatusOpen : false
    //     }));
    // };

    const handleSelectChange = (event) => {
        setRecurringRepeat(event.target.value);
    };

    const handleRecurringInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? (checked ? "1" : "0") : value;

        // Update nested state value for 'recurring'
        setEditTaskDetail((prevState) => ({
            ...prevState,
            recurring: {
                ...prevState.recurring,
                [name]: newValue,
            },
        }));
    };

    const convertHexToRGBA = (hex, opacity) => {
        if (typeof hex !== "string" || hex === null || hex.length === 0) {
            console.error("Invalid hex value:", hex);
            throw new TypeError("Hex value must be a non-empty string");
        }

        let r = 0,
            g = 0,
            b = 0;

        // 3 digits hex
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        } else {
            console.error("Invalid hex length:", hex);
            throw new Error("Hex value must be either 4 or 7 characters long");
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    const handleDropdownOpen = (type) => {
        if (type === "interaction") {
            if (!Auth.innerPermission("task_update")) {
                toast.error("You are not authorized to access the resource");
                return; // Stop further execution if no permission
            }
        }
        if (type === "priority" && !Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop further execution if no permission
        }

        if (type === "status" && !Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop further execution if no permission
        }
        setDropdownState((prevState) => ({
            ...prevState,
            isOpen: type === "interaction" ? !prevState.isOpen : prevState.isOpen,
            isPriorityOpen: type === "priority" ? !prevState.isPriorityOpen : prevState.isPriorityOpen,
            isStatusOpen: type === "status" ? !prevState.isStatusOpen : prevState.isStatusOpen,
        }));
    };

    useEffect(() => {
        if (initialSelectedOption) {
            setDropdownState((prevState) => ({ ...prevState, selectedOption: initialSelectedOption }));
        }
        if (initialPrioritySelectedOption) {
            setDropdownState((prevState) => ({ ...prevState, prioritySelectedOption: initialPrioritySelectedOption }));
        }
        if (initialStatusSelectedOption) {
            setDropdownState((prevState) => ({ ...prevState, statusSelectedOption: initialStatusSelectedOption }));
        }
    }, [initialSelectedOption, initialPrioritySelectedOption, initialStatusSelectedOption, setDropdownState]);

    const formatTimeAgo = (date) => {
        const now = moment();
        const commentDate = moment(date);
        const diffInMinutes = now.diff(commentDate, "minutes");
        const diffInHours = now.diff(commentDate, "hours");
        const diffInDays = now.diff(commentDate, "days");
        const diffInMonths = now.diff(commentDate, "months");
        const diffInYears = now.diff(commentDate, "years");

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? "s" : ""} ago`;
        } else if (diffInHours < 24) {
            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;
            return `${hours} hour${hours !== 1 ? "s" : ""} ${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} day${diffInDays !== 1 ? "s" : ""} ago`;
        } else if (diffInMonths < 12) {
            return `${diffInMonths} month${diffInMonths !== 1 ? "s" : ""} ago`;
        } else {
            return `${diffInYears} year${diffInYears !== 1 ? "s" : ""} ago`;
        }
    };

    const handleFormatChange = (e) => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Stop execution if no permission
        }

        const { name, value } = e.target;
        setTimeFormats({ ...timeFormats, [name]: value });

        if (name === "start_time_format") {
            updateStartTimeFormat(value);
        }
        if (name === "end_time_format") {
            updateEndTimeFormat(value);
        }
    };

    const handleInteractionChange = (option) => {
        if (option.id === "add-more") {
            setShowInderaction(true);
            setDropdownState((prevState) => ({ ...prevState, selectedOption: null }));
            setValue("interaction_type_id", "0");
        } else {
            setValue("interaction_type_id", option.id);
            setDropdownState((prevState) => ({ ...prevState, selectedOption: option }));
            updateIntreactinType(option.id);
        }
    };
    const filteredOptions = interactionList.filter((option) => option.name.toLowerCase().includes(dropdownState.search.toLowerCase()));
    const handleSelect = (option) => {
        setDropdownState((prevState) => ({
            ...prevState,
            selectedOption: option,
            isOpen: false,
            search: "",
        }));
        handleInteractionChange(option);
    };

    /* Add PriorityType Function */

    const handleSelectPriority = (option) => {
        setDropdownState((prevState) => ({
            ...prevState,
            prioritySelectedOption: option,
            isPriorityOpen: false,
            prioritySearch: "",
        }));
        handlePriorityChange(option);
    };
    const handlePriorityChange = (option) => {
        if (option.id === "add-more") {
            setShowPriority(true);
            setDropdownState((prevState) => ({ ...prevState, prioritySelectedOption: null }));
            setValue("priority_id", "0");
        } else {
            setValue("priority_id", option.id);
            setDropdownState((prevState) => ({ ...prevState, prioritySelectedOption: option }));
            updatePriorityType(option.id);
        }
    };

    const filteredOptionsPriority = priorityList.filter((option) => option.name.toLowerCase().includes(dropdownState.prioritySearch.toLowerCase()));

    /* Add PriorityType Function End */

    /* Add StatusType Function */

    const handleStatusChange = (option) => {
        if (option.id === "add-more") {
            setShowStatus(true);
            // Reset the select to the default value
            setDropdownState((prevState) => ({ ...prevState, statusSelectedOption: null }));
            setValue("status_task_id", "0");
        } else {
            setValue("status_task_id", option.id);
            setDropdownState((prevState) => ({ ...prevState, statusSelectedOption: option }));
            updateStatusType(option.id);
        }
    };

    const handleSelectStatus = (option) => {
        setDropdownState((prevState) => ({
            ...prevState,
            statusSelectedOption: option,
            isStatusOpen: false,
            statusSearch: "",
        }));
        handleStatusChange(option);
    };

    const filteredOptionsStatus = statusList.filter((option) => option.name.toLowerCase().includes(dropdownState.statusSearch.toLowerCase()));

    /* Add StatusType Function End */

    /* Add Assign Staff Function */

    const displayStaffNames = () => {
        if (selectedStaff.length > 0) {
            return selectedStaff.map((staff) => (
                <span key={staff.id} className="mt-2 bg-blue-light assignee_name" data-bs-toggle="tooltip" data-bs-placement="top" title={staff.name}>
                    {staff.name ? staff.name.charAt(0).toUpperCase() : ""}
                </span>
            ));
        }
        return (
            <span className="select-box fw-medium" onClick={handleAssigneeClick}>
                Unassigned
            </span>
        );
    };

    useEffect(() => {
        // Pre-select staff if needed
        const preSelectedStaff = assignData.filter((staff) => assigneeIds.includes(staff.id));
        setSelectedStaff(preSelectedStaff);
    }, [assigneeIds, assignData]);

    const toggleAssignee = (id) => {
        setAssigneeIds((prevIds) => {
            let updatedIds;
            if (prevIds.includes(id)) {
                updatedIds = prevIds.filter((assigneeId) => assigneeId !== id);
            } else {
                updatedIds = [...prevIds, id];
            }
            setPendingUpdate(true);
            return updatedIds;
        });
    };

    useEffect(() => {
        if (pendingUpdate) {
            updateStaffType(assigneeIds);
            setPendingUpdate(false);
        }
    }, [assigneeIds, pendingUpdate]);

    const toggleAssignOpen = () => {
        setShowAssignOpen((prevState) => !prevState);
    };

    const handleAssigneeClick = () => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        toggleAssignOpen(); // Open the assignee dropdown if permission is granted
    };


    /* Add Assign Staff Function End */

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setEditTaskDetail((prevDetail) => ({
    //         ...prevDetail,
    //         custom_field: {
    //             ...prevDetail.custom_field,
    //             [name]: value,
    //         },
    //     }));
    //     if (name === 'start_date') {
    //         updateStartTime(value);
    //     }
    // };

    const handleInputChange = (e) => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        const { name, value } = e.target;
        setEditTaskDetail((prevDetail) => {
            const updatedDetail = {
                ...prevDetail,
                custom_field: {
                    ...prevDetail.custom_field,
                    [name]: value,
                },
            };
            if (name === "start_date") {
                updatedDetail.start_date = value;
            }
            if (name === "end_date") {
                updatedDetail.end_date = value;
            }
            if (name === "start_time") {
                updatedDetail.start_time = value;
            }
            if (name === "end_time") {
                updatedDetail.end_time = value;
            }
            if (name === "estimation_time") {
                updatedDetail.estimation_time = value;
            }
            if (name === "time_spent") {
                updatedDetail.time_spent = value;
            }
            return updatedDetail;
        });

        // Debounced API call function
        debouncedApiCall(name, value);
    };

    // Use useCallback to ensure the debounced function is stable
    const debouncedApiCall = useCallback(
        debounce((name, value) => {
            if (name === "start_date") {
                updateStartDate(value); // Call API when start_date changes
            }
            if (name === "end_date") {
                updateEndDate(value); // Call API when end_date changes
            }
            if (name === "start_time") {
                updateStartTime(value); // Call API when start_time changes
            }
            if (name === "end_time") {
                updateEndTime(value); // Call API when end_time changes
            }
            if (name === "estimation_time") {
                updateEstimateTime(value); // Call API when estimation_time changes
            }
            if (name === "time_spent") {
                updateSpendTime(value); // Call API when time_spent changes
            }
        }, 1000),
        []
    );

    // const handleFileChange = (event) => {
    //     const files = Array.from(event.target.files);
    //     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    // };

    const handleTaskView = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/${id}/view`,
                    isSecure: true,
                },
                (res) => {
                    setTaskViewData(res?.data);
                    setShowLoader(false);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleTaskView();
    }, []);

    const deleteTask = () => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "DELETE",
                    url: `client/task/${id}/destroy`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    setShowLoader(false);
                    navigate("/admin/task");
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
            setShowLoader(false);
        }
    };

    const handleEditTask = async (id) => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/outertask/${id}/edit`,
                    isSecure: true,
                },
                (res) => {
                    setTaskViewData(res?.data);
                    // setEditTaskModal(true);
                    setEditTaskDetail(res?.data);

                    Object.keys(res?.data).forEach((key) => {
                        setValue(key, res?.data[key]);
                    });
                    setDataLoaded(true); // Mark data as loaded
                }
            );
        } catch (error) {
            console.error("Error fetching task data:", error);
        }
    };

    useEffect(() => {
        if (taskViewData?.id && !dataLoaded) {
            handleEditTask(taskViewData.id);
        }
    }, [taskViewData, dataLoaded]);

    const oepnEditModal = () => {
        setEditTaskModal(true);
    };

    const deleteImage = async (documentId, isNew = false) => {
        if (isNew) {
            setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== documentId));
        } else {
            setDeleteImageIDs((prevState) => [...prevState, documentId]);
            setEditTaskDetail((prevState) => ({
                ...prevState,
                documents: prevState.documents.filter((doc) => doc.id !== documentId),
            }));
        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {
                    setInteractionList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,
                },
                (res) => {
                    setPriorityList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    const updateTaskHandle = async () => {
        setShowLoader(true);
        try {
            const formData = new FormData();
            // Append recurring fields
            const endRecurringDate = editTaskDetail?.recurring?.reccuring_end_date;
            const isCheckboxChecked = editTaskDetail?.recurring?.reccuring_expire === "1";
            formData.append("name", editTaskDetail?.name);
            formData.append("description", editTaskDetail?.description);
            formData.append("interaction_type_id", taskDetails.interaction_type_id);
            formData.append("priority_id", taskDetails.priority_id);
            formData.append("status_task_id", taskDetails.status_task_id);
            formData.append("start_date", editTaskDetail?.start_date);
            formData.append("start_time", editTaskDetail?.start_time);
            formData.append("start_time_format", timeFormats?.start_time_format);
            formData.append("end_date", editTaskDetail?.end_date);
            formData.append("end_time", editTaskDetail?.end_time);
            formData.append("end_time_format", timeFormats?.end_time_format);
            formData.append("estimation_time", editTaskDetail?.estimation_time);
            formData.append("time_spent", editTaskDetail?.time_spent);
            formData.append("id", editTaskDetail?.id);
            // formData.append('client_id', editTaskDetail?.client_id);
            formData.append("task_type", editTaskDetail?.task_type);
            formData.append("image_delete_ids", deleteImageIDs.join(","));
            formData.append("reccuring_repeat", recurringRepeat);
            formData.append("reccuring_email_automatic", editTaskDetail?.recurring?.reccuring_email_automatic);
            formData.append("reccuring_status", editTaskDetail?.recurring?.reccuring_status);
            formData.append("reccuring_start_date", editTaskDetail?.recurring?.reccuring_start_date);

            // Check and append 'is_parent_task'
            if (editTaskDetail?.is_parent_task) {
                formData.append("is_parent_task", editTaskDetail.is_parent_task);
            }

            if (endRecurringDate && !isCheckboxChecked) {
                formData.append("reccuring_end_date", endRecurringDate);
            }
            formData.append("reccuring_expire", isCheckboxChecked ? 1 : 0);

            // Conditionally append user_id or client_id
            if (editTaskDetail?.task_type === "3") {
                // Self task
                formData.append("user_id", editTaskDetail?.user_id);
            } else {
                // Individual or business task
                formData.append("client_id", editTaskDetail?.client_id);
            }

            assigneeIds.forEach((id) => {
                formData.append("staff_id[]", id);
            });

            selectedFiles.forEach((file) => {
                formData.append("file[]", file);
            });
            // Append custom fields
            Object.keys(editTaskDetail.custom_field || {}).forEach((key) => {
                formData.append(`custom_field[${key}]`, editTaskDetail.custom_field[key]);
            });

            http(
                {
                    method: "POST",
                    // url: endpoint,
                    url: `client/outertask/update/${id}`,
                    isSecure: true,
                    body: formData,
                    contentType: "multipart/form-data",
                },
                (res) => {
                    // setTaskViewData(prevData => prevData.map(task =>
                    //     task.id === editTaskDetail.id ? { ...task, ...editTaskDetail } : task
                    // ));
                    toast.success(res.message)
                    setSelectedFiles([]);
                    setDeleteImageIDs([]);
                    setEditTaskModal(false);
                    setShowLoader(false);
                    handleTaskView();
                }
            );
        } catch (error) {
            console.error("Error fetching task data:", error);
            setShowLoader(false);
        }
    };

    const addCommentHandle = (event) => {
        setShowLoader(true);
        event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/${id}/comment/add`,
                    isSecure: true,
                    body: {
                        user_id: userID?.user?.id,
                        comment: comment,
                    },
                },
                (res) => {
                    toast.success(res.message);
                    setComment("");
                    setTimeout(() => {
                        setShowLoader(false);
                        listCommentHandle();
                    }, 500); // Adjust the time (in milliseconds) as needed
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
            setTimeout(() => {
                setShowLoader(false);
            }, 500);
        }
    };

    const listCommentHandle = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `task/${id}/comment`,
                    isSecure: true,
                },
                (res) => {
                    setCommentList(res?.comments);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateIntreactinType = (interactionTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_interaction_type/${id}`,
                    isSecure: true,
                    body: {
                        interaction_type_id: interactionTypeId,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updatePriorityType = (priorityTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_priority/${id}`,
                    isSecure: true,
                    body: {
                        priority_id: priorityTypeId,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateStatusType = (StatusTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${id}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusTypeId,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateStaffType = (StaffTypeId) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_assignee/${id}`,
                    isSecure: true,
                    body: {
                        staff_id: StaffTypeId,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateStartDate = (startDate) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_date/${id}`,
                    isSecure: true,
                    body: {
                        start_date: startDate,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };
    const updateEndDate = (EndDate) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_date/${id}`,
                    isSecure: true,
                    body: {
                        end_date: EndDate,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateStartTime = (StartTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time/${id}`,
                    isSecure: true,
                    body: {
                        start_time: StartTime,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateEndTime = (EndTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time/${id}`,
                    isSecure: true,
                    body: {
                        end_time: EndTime,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateStartTimeFormat = (StartTimeFormat) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_start_time_format/${id}`,
                    isSecure: true,
                    body: {
                        start_time_format: StartTimeFormat,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };
    const updateEndTimeFormat = (EndTimeFormat) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_end_time_format/${id}`,
                    isSecure: true,
                    body: {
                        end_time_format: EndTimeFormat,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateEstimateTime = (EstimateTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_estimation_time/${id}`,
                    isSecure: true,
                    body: {
                        estimation_time: EstimateTime,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    const updateSpendTime = (SpendTime) => {
        // event.preventDefault();
        try {
            http(
                {
                    method: "POST",
                    url: `task/update_time_spent/${id}`,
                    isSecure: true,
                    body: {
                        time_spent: SpendTime,
                    },
                },
                (res) => {
                    toast.success(res.message);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };

    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleAssisgnData();
        handleStatusData();
        listCommentHandle();
    }, []);

    useEffect(() => {
        if (editTaskDetail) {
            setTaskDetails((prevTaskDetails) => ({
                ...prevTaskDetails,
                interaction_type_id: editTaskDetail?.interactiontype?.id || 0,
                priority_id: editTaskDetail?.priority?.id || 0,
                status_task_id: editTaskDetail?.statustask?.id || 0,
            }));
        }
    }, [editTaskDetail?.interactiontype?.id, editTaskDetail?.priority?.id, editTaskDetail?.statustask?.id]);

    useEffect(() => {
        if (editTaskDetail) {
            setTimeFormats({
                start_time_format: editTaskDetail.start_time_format || "AM",
                end_time_format: editTaskDetail.end_time_format || "AM",
            });
        }
    }, [editTaskDetail]);

    useEffect(() => {
        if (editTaskDetail && editTaskDetail.assignees) {
            const ids = editTaskDetail.assignees.map((assignee) => assignee.id);
            setAssigneeIds(ids);
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);

    // Initialize the state with the value from editTaskDetail
    useEffect(() => {
        if (editTaskDetail && editTaskDetail.recurring && editTaskDetail.recurring.reccuring_repeat) {
            setRecurringRepeat(editTaskDetail.recurring.reccuring_repeat);
        }
    }, [editTaskDetail]);

    const handleAddSubtask = () => {
        localStorage.setItem("Outer_recurring_task_details", JSON.stringify(editTaskDetail));
        navigate("/admin/task/add_recurring_task?type=recurring_outer_subtask"); // Change the route after submission
    };
    const handleSearchChange = (e) => {
        setSearchSubTaskTerm(e.target.value);
    };

    const handleDropdownClick = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenDropdown(dropdownName); // Open the clicked dropdown and close others
            // event.stopPropagation();
        }
    };
    const handleStatusClick = () => handleDropdownClick("status");
    const handlePriorityClick = () => handleDropdownClick("priority");
    const handleNameClick = () => handleDropdownClick("Assigned");

    const handleStatusOptionClick = (value) => {
        setSelectedStatus(value);
        setIsStatusOpen(false);
    };

    const handlePriorityOptionClick = (value) => {
        setSelectedPriority(value);
        setIsPriorityOpen(false);
    };

    const handleAssigneeOptionClick = (value) => {
        setSelectedAssignee(value);
        setIsAssigneeOpen(false);
        setOpenDropdown(null);
    };
    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    // Memoized list of unique assignees
    const uniqueAssignees = useMemo(() => [...new Set(subTaskList?.flatMap((task) => task.assignees.map((assignee) => `${assignee.first_name} ${assignee.last_name}`)))], [subTaskList]);

    // Effect to filter assignees based on the search input
    useEffect(() => {
        setFilteredAssignees(uniqueAssignees.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssignees]);

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setAssigneeSearchInput(e.target.value); // Update search input state with the entered value
    };

    const toggleDropdown = (id) => {
        setOpenDropdownId((prevId) => (prevId === id ? null : id));
    };

    const subTaskOuterRecurringListHandle = () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/subtask/${id}/listing`,
                    isSecure: true,
                },
                (res) => {
                    const tasks = res?.data?.data;
                    setSubTAskList(tasks);

                    // Initialize selectedStatuses with the current statuses of fetched tasks
                    const initialStatuses = tasks.reduce((acc, task) => {
                        acc[task.id] = task.statustask; // Use the status task object from each task
                        return acc;
                    }, {});
                    setSelectedStatuses(initialStatuses);
                }
            );
        } catch (error) {
            console.error("Error fetching Comment data:", error);
        }
    };
    useEffect(() => {
        subTaskOuterRecurringListHandle();
    }, []);

    // Toggle the dropdown for a specific task
    const toggleStatusDropdown = (taskId) => {
        setOpenDropdownStatusId((prevId) => (prevId === taskId ? null : taskId)); // Open if closed, close if open
    };

    // Filtered data based on the search term
    const filteredData = assignData.filter((staff) => staff.name.toLowerCase().includes(searchSubTaskTerm.toLowerCase()));

    // Handle status select
    const handleStatusSelect = (option, taskId) => {
        if (!taskId) {
            toast.error("Task ID is missing. Please select a task first.");
            return;
        }

        // Close the dropdown
        setOpenDropdownStatusId(null);

        // Update the selected status state
        setSelectedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: option, // Update the selected status for the task
        }));

        // Call the function to update the subtask status
        updateSubTaskStatusType(option.id, taskId);
    };
    // Function to update the subtask status
    const updateSubTaskStatusType = (StatusId, taskId) => {
        if (!taskId) {
            console.error("Task ID is required but is undefined inside updateSubTaskStatusType.");
            toast.error("Task ID is missing.");
            return;
        }

        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${taskId}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusId,
                    },
                },
                (res) => {
                    toast.success(res.message || "Status updated successfully.");
                    subTaskOuterRecurringListHandle();
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Unexpected error updating status:", error);
            toast.error("Unexpected error occurred.");
        }
    };

    // Function to handle the staff selection
    const handleStaffSelect = (staff, taskId) => {
        // Debugging: Check if taskId is missing and log detailed error
        if (!taskId) {
            toast.error("Task ID is missing. Please select a task first.");
            return;
        }

        setOpenDropdownId(null); // Close the dropdown after selecting

        // Call the function to update the subtask staff assignment
        updateSubTaskStaffType(staff.id, taskId);
    };

    // Function to update the subtask with the selected staff member's ID
    const updateSubTaskStaffType = (staffId, taskId) => {
        // Ensure taskId is passed correctly
        if (!taskId) {
            console.error("Task ID is required but is undefined inside updateSubTaskStaffType.");
            toast.error("Task ID is missing.");
            return;
        }

        try {
            http(
                {
                    method: "POST",
                    url: `task/update_assignee/${taskId}`, // Use the passed task ID
                    isSecure: true,
                    body: {
                        staff_id: [staffId], // Send the selected staff ID in an array as requested
                    },
                },
                (res) => {
                    toast.success(res.message || "Staff assignment updated successfully.");
                    subTaskOuterRecurringListHandle();
                },
                (error) => {
                    console.error("Error updating staff assignment:", error);
                    toast.error("Failed to update staff assignment.");
                }
            );
        } catch (error) {
            console.error("Unexpected error updating staff assignment:", error);
            toast.error("Unexpected error occurred.");
        }
    };
    const handleSubtasksTabClick = () => {
        if (!Auth.innerPermission("task_update")) {
            toast.error("You are not authorized to access the resource");
            return; // Prevent further execution if no permission
        }
        setActiveTab("subtasks"); // Change the tab if permission is granted
    };

    const processFiles = (newFiles) => {
        const newFilePreviews = newFiles.map((file) => {
            const fileType = file.type;
            let preview = '';

            if (fileType === 'application/pdf') {
                preview = require('../../../assets/images/pdfimage.png'); // Replace with PDF image path
            } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                preview = require('../../../assets/images/docimage.png'); // Replace with DOC image path
            } else if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                preview = require('../../../assets/images/xlsimage.png'); // Replace with XLSX image path
            } else {
                preview = URL.createObjectURL(file);
            }
            return { file, preview }; // Return file with preview metadata
        });

        // Update state with new files and previews
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setImagePreviews((prevPreviews) => [...prevPreviews, ...newFilePreviews]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Optional: Add visual feedback for drag enter
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Optional: Remove visual feedback for drag leave
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const newFiles = Array.from(e.dataTransfer.files);

        // Avoid adding duplicates (match by name or some unique property)
        const filteredFiles = newFiles.filter(
            (file) => !editTaskDetail.documents.some((doc) => doc.name === file.name)
        );

        processFiles(filteredFiles);
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);

        // Avoid duplicates
        const filteredFiles = newFiles.filter(
            (file) => !editTaskDetail.documents.some((doc) => doc.name === file.name)
        );

        processFiles(filteredFiles);
    };

    useEffect(() => {
        let filtered = subTaskList;

        // Assuming `filtered` is your array of tasks
        if (selectedAssignee !== "All") {
            filtered = filtered.filter((task) =>
                // Check if any assignee's full name matches the selected assignee
                task.assignees.some((assignee) => `${assignee.first_name} ${assignee.last_name}` === selectedAssignee)
            );
        }

        if (selectedPriority !== "All") {
            filtered = filtered.filter((task) => task.priority?.id == selectedPriority);
        }

        if (selectedStatus !== "All") {
            filtered = filtered.filter((task) => task.statustask?.id == selectedStatus);
        }

        setFilteredTasks(filtered);
    }, [selectedStatus, selectedPriority, selectedAssignee, subTaskList]);

    return (
        <div className="right_section font-poppins">
            <div className="tasks-detail-area">
                <div className="row">
                    <div className="col-md-8 mt-4 ps-0">
                        <div className="row align-items-center task-detail-head">
                            <div className="col-sm-12 mt-2 l_dethead">
                                <h2 className="fs-20 fw-bold">Recurring Task Details</h2>
                            </div>
                            <div className="col-md-6 my-3 d-flex align-items-center gap-3">
                                <div className="ttl-name d-flex align-items-center justify-content-center">
                                    <p>
                                        {taskViewData?.client_self?.name
                                            ? taskViewData?.client_self?.name.charAt(0).toUpperCase() || "--" // Use `name` if available
                                            : `${taskViewData?.client_self?.first_name?.charAt(0)?.toUpperCase() || "--"}${taskViewData?.client_self?.last_name?.charAt(0)?.toUpperCase() || "--"}`}  {/* Use `first_name` and `last_name` if `name` is not available */}
                                    </p>
                                </div>
                                <div className="ttl-detail">
                                    <h4 className="fs-18 fw-semibold">
                                        {taskViewData?.client_self?.first_name} {taskViewData?.client_self?.last_name}
                                    </h4>

                                    {taskViewData?.task_type === "1" ? <p className="fs-16 text-secondary">Individual</p> : taskViewData?.task_type === "2" ? <p className="fs-16 text-secondary">Business</p> : taskViewData?.task_type === "3" && <p className="fs-16 text-secondary">Self</p>}
                                </div>
                            </div>
                            <div className="col-md-6 my-3 r_dethead">
                                <ul className="ps-0 d-flex align-items-center gap-2 justify-content-end">
                                    <li>
                                        <span
                                            className="whitespace-nowrap text-black"
                                        //  style={style}
                                        >
                                            {/* {taskViewData?.priority?.name} */}

                                            {taskViewData.priority && taskViewData.priority.color_code && taskViewData.priority.name && (
                                                <span
                                                    style={{
                                                        backgroundColor: convertHexToRGBA(taskViewData.priority.color_code, 0.2),
                                                        padding: "3px 10px",
                                                        borderRadius: "8px",
                                                        textAlign: "center",
                                                        width: "fit-content",
                                                        fontSize: "14px",
                                                        height: "30px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: taskViewData.priority.color_code,
                                                    }}
                                                >
                                                    {taskViewData.priority.name}
                                                </span>
                                            )}
                                        </span>
                                    </li>
                                    <li>
                                        <a
                                            className="file-edit"
                                            // onClick={() => handleEditTask(taskViewData?.id)}

                                            onClick={() => oepnEditModal()}
                                        >
                                            <img alt="" src={require("../../../assets/images/edit-icon.png")} />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="file-delete" onClick={() => deleteTask()}>
                                            <img alt="" src={require("../../../assets/images/delete-icon.png")} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 task-detail-area mt-2">
                                <h4 className="fs-18 fw-semibold">
                                    {taskViewData?.name} <span className="text-blue fs-14 fw-normal">{taskViewData?.statustask?.name}</span>
                                </h4>
                                {/* <p className="fs-14">Created by <b>{taskViewData?.client?.first_name} {taskViewData?.client?.last_name}</b> about 2 days ago</p> */}

                                {taskViewData?.task_type === "1" ? (
                                    <p className="fs-14">
                                        Created by{" "}
                                        <b>
                                            {taskViewData?.client?.first_name} {taskViewData?.client?.last_name}
                                        </b>{" "}
                                        about 2 days ago
                                    </p>
                                ) : taskViewData?.task_type === "2" ? (
                                    <p className="fs-14">
                                        Created by{" "}
                                        <b>
                                            {taskViewData?.client?.first_name} {taskViewData?.client?.last_name}
                                        </b>{" "}
                                        about 2 days ago
                                    </p>
                                ) : (
                                    taskViewData?.task_type === "3" && (
                                        <p className="fs-14">
                                            Created by <b>{taskViewData?.user?.name}</b> about 2 days ago
                                        </p>
                                    )
                                )}
                                {/* <p className="fs-18 mt-3 mb-2">{taskViewData?.description}</p> */}
                                <p
                                    className="mt-3 "
                                    dangerouslySetInnerHTML={{
                                        __html: taskViewData?.description,
                                    }}
                                />
                            </div>
                            {/* <div className="d-flex align-items-start gap-3 flex-wrap task-pics mt-3">
                                {taskViewData &&
                                    taskViewData?.documents?.map((taskImage, index) => {
                                        // Determine file type
                                        const filePath = taskImage?.document_path;
                                        const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                        const isPDF = filePath.match(/\.pdf$/i); // Check if the file is a PDF
                                        const isDOC = filePath.match(/\.docx?$/i); // Check if the file is a Word document
                                        const isXLS = filePath.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                        return (
                                            <div key={`file-${index}`}>
                                                {isImage ? (
                                                    // Display image preview
                                                    <img alt="Preview" src={filePath} />
                                                ) : isPDF ? (
                                                    // Display PDF icon or thumbnail
                                                    <img alt="PDF Preview" src={require("../../../assets/images/pdfimage.png")} />
                                                ) : isDOC ? (
                                                    // Display Word document icon or thumbnail
                                                    <img alt="DOC Preview" src={require("../../../assets/images/pdfimage.png")} />
                                                ) : isXLS ? (
                                                    // Display Excel document icon or thumbnail
                                                    <img alt="XLS Preview" src="/path/to/xls-icon.png" style={{ width: 100, height: 100 }} />
                                                ) : (
                                                    // Fallback for other file types or unsupported formats
                                                    <span>Unsupported file type</span>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div> */}
                            <div className="d-flex align-items-start gap-3 flex-wrap task-pics mt-3">
                                {taskViewData &&
                                    taskViewData?.documents?.map((taskImage, index) => {
                                        // Determine file type
                                        const filePath = taskImage?.document_path;
                                        const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                        const isPDF = filePath.match(/\.pdf$/i); // Check if the file is a PDF
                                        const isDOC = filePath.match(/\.docx?$/i); // Check if the file is a Word document
                                        const isXLS = filePath.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                        return (
                                            <div key={`file-${index}`}>
                                                {isImage ? (
                                                    // Display image preview
                                                    <img alt="Preview" src={filePath} />
                                                ) : isPDF ? (
                                                    // Display PDF icon or thumbnail
                                                    <img alt="PDF Preview" src={require("../../../assets/images/pdfimage.png")} />
                                                ) : isDOC ? (
                                                    // Display Word document icon or thumbnail
                                                    <img alt="DOC Preview" src={require("../../../assets/images/docimage.png")} />
                                                ) : isXLS ? (
                                                    // Display Excel document icon or thumbnail
                                                    <img alt="XLS Preview" src={require('../../../assets/images/xlsimage.png')} style={{ width: 100, height: 100 }} />
                                                ) : (
                                                    // Fallback for other file types or unsupported formats
                                                    <span>Unsupported file type</span>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>



                            <div className="col-md-12">
                                <hr className="task-hr" />
                            </div>
                            <div className="col-md-12 table_part p-0">
                                <div className="row">
                                    <h5 className="fs-18 fw-semibold">Schedule</h5>
                                    <div class="col-md-3 mt-3">
                                        <h6 className="fs-14 text-secondary">Repeat this Task</h6>

                                        {taskViewData?.recurring?.reccuring_repeat === "1" ? <h2 className="fs-18 mt-2">Weekly</h2> : taskViewData?.recurring?.reccuring_repeat === "2" ? <h2 className="fs-18 mt-2">Monthly</h2> : taskViewData?.recurring?.reccuring_repeat === "3" ? <h2 className="fs-18 mt-2">Half Yearly</h2> : taskViewData?.recurring?.reccuring_repeat === "4" ? <h2 className="fs-18 mt-2"> Yearly</h2> : taskViewData?.recurring?.reccuring_repeat === "5" && <h2 className="fs-18 mt-2"> Day</h2>}
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <h6 className="fs-14 text-secondary">Create First Task On</h6>
                                        <h2 className="fs-18 mt-2">{moment(taskViewData?.recurring?.reccuring_start_date).format("DD-MMM-YYYY")}</h2>
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <h6 className="fs-14 text-secondary">Expire</h6>

                                        {taskViewData?.recurring?.reccuring_expire === "0" ? <h2 className="fs-18 mt-2">Never Expires</h2> : taskViewData?.recurring?.reccuring_expire === "1" && <h2 className="fs-18 mt-2">Expires</h2>}
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <h6 className="fs-14 text-secondary">Send Email Automatically</h6>
                                        {taskViewData?.recurring?.reccuring_email_automatic === "0" ? <h2 className="fs-18 mt-2">No</h2> : taskViewData?.recurring?.reccuring_email_automatic === "1" && <h2 className="fs-18 mt-2">Yes</h2>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr className="task-hr" />
                            </div>
                        </div>
                        <div className="row comments-area">
                            <div className="col-md-12 client-tabs d-flex justify-content-between align-items-center gap-4 flex-wrap">
                                <ul className="nav nav-tabs border-0 d-flex align-items-center flex-wrap">
                                    <li className="nav-item me-2 mt-2">
                                        <button className={`nav-link fs-16 ${activeTab === "subtasks" ? "active" : ""}`}
                                            //  onClick={() => setActiveTab("subtasks")}
                                            onClick={handleSubtasksTabClick}
                                        >
                                            Subtasks
                                        </button>
                                    </li>
                                </ul>
                                {activeTab === "subtasks" && (
                                    <button className="add-new-button" onClick={handleAddSubtask}>
                                        <img src={require("../../../assets/images/plusicon.png")} alt="Add Subtask" />
                                        Add Subtask
                                    </button>
                                )}
                            </div>

                            {activeTab === "subtasks" && (
                                <div className="row">
                                    <div className="col-md-12 comment-title mt-3 mb-1">
                                        <h1 className="fs-20 fw-bold">Subtasks</h1>
                                    </div>
                                    <div className="col-md-12 drop_box d-flex align-items-center gap-3 justify-content-end mt-3">
                                        <div className="drop_box">
                                            <div className="dropdown" onClick={handleNameClick}>
                                                <div className="dropdown__selected">
                                                    <label className="fs-14">{displayAssignedToLabel}</label>

                                                    <span className="dropdown__icon">
                                                        {openDropdown === "Assigned" ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className={`dropdown__options ${openDropdown === "Assigned" ? "dropdown__options--open" : ""}`} onClick={stopPropagation}>
                                                    <input
                                                        type="text"
                                                        value={assigneeSearchInput} // Bind the input value to the search state
                                                        onChange={handleSearchInputChange} // Handle input change
                                                        onMouseDown={stopPropagation}
                                                        placeholder="Search"
                                                    />
                                                    <div key="all-name" className="dropdown__option" onClick={() => handleAssigneeOptionClick("All")}>
                                                        All
                                                    </div>
                                                    {filteredAssignees.map((name) => (
                                                        <div key={name} className="dropdown__option" onClick={() => handleAssigneeOptionClick(name)}>
                                                            {name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="drop_box">
                                            <div className="dropdown" onClick={handlePriorityClick}>
                                                <div className="dropdown__selected">
                                                    <label className="fs-14">{selectedPriority === "All" ? "Priority" : priorityList.find((option) => option.id == selectedPriority)?.name || "Priority"}</label>
                                                    <span className="dropdown__icon">
                                                        {openDropdown === "priority" ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className={`dropdown__options ${openDropdown === "priority" ? "dropdown__options--open" : ""}`}>
                                                    <div key="all-priority" className="dropdown__option" onClick={() => handlePriorityOptionClick("All")}>
                                                        All
                                                    </div>
                                                    {priorityList.map((option) => (
                                                        <div key={option.id} className="dropdown__option" onClick={() => handlePriorityOptionClick(option.id)}>
                                                            {option.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="drop_box">
                                            <div className="dropdown" onClick={handleStatusClick}>
                                                <div className="dropdown__selected">
                                                    <label className="fs-14">{selectedStatus === "All" ? "Status" : statusList.find((option) => option.id == selectedStatus)?.name || "Status"}</label>
                                                    <span className="dropdown__icon">
                                                        {openDropdown === "status" ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className={`dropdown__options ${openDropdown === "status" ? "dropdown__options--open" : ""}`}>
                                                    <div key="all-status" className="dropdown__option" onClick={() => handleStatusOptionClick("All")}>
                                                        All
                                                    </div>
                                                    {statusList.map((option) => (
                                                        <div key={option.id} className="dropdown__option" onClick={() => handleStatusOptionClick(option.id)}>
                                                            {option.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3 table_part">
                                        <div className="table-responsive subtask-table">
                                            <table className="w-100">
                                                <thead className="pb-2">
                                                    <tr>
                                                        <th>Subtask No.</th>
                                                        <th>Subtask</th>
                                                        <th>Time Spent</th>
                                                        <th>Assigned to</th>
                                                        <th>Priority</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredTasks &&
                                                        filteredTasks.map((subtaskdetail) => (
                                                            <tr>
                                                                <td>{subtaskdetail?.recurring?.reccuring_unique_id}</td>
                                                                <td>{subtaskdetail?.name}</td>
                                                                <td>{subtaskdetail?.time_spent}</td>
                                                                <td className="tableuser">
                                                                    {Array.isArray(subtaskdetail.assignees) && subtaskdetail.assignees.length > 0 && (
                                                                        <>
                                                                            <div className="d-flex align-items-center gap-1">
                                                                                <img src={require("../../../assets/images/user-pic.png")} alt="" />
                                                                                <p className="fs-16">
                                                                                    {subtaskdetail.assignees[0]?.first_name} {subtaskdetail.assignees[0]?.last_name}
                                                                                </p>
                                                                                <a onClick={() => toggleDropdown(subtaskdetail.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 28.001 28">
                                                                                        <g id="write" transform="translate(-7.998 -8)">
                                                                                            <path id="Path_3528" data-name="Path 3528" d="M8.6,33.775l-.587,4.863a1.462,1.462,0,0,0,1.457,1.654,1.436,1.436,0,0,0,.186-.012l4.863-.587a4.634,4.634,0,0,0,2.7-1.326l12.6-12.6a.583.583,0,0,0,0-.825l-6.465-6.47a.583.583,0,0,0-.825,0l-12.6,12.6a4.634,4.634,0,0,0-1.326,2.7Z" transform="translate(0 -4.292)" fill="#202529" />
                                                                                            <path id="Path_3529" data-name="Path 3529" d="M47.127,11.9,44.112,8.886a3.023,3.023,0,0,0-4.277,0L37,11.718a.583.583,0,0,0,0,.825l6.468,6.468a.583.583,0,0,0,.825,0l2.832-2.832A3.023,3.023,0,0,0,47.127,11.9Z" transform="translate(-12.014 0)" fill="#202529" />
                                                                                            <path id="Path_3530" data-name="Path 3530" d="M44.75,53.167A1.167,1.167,0,0,0,43.583,52H30.167a1.167,1.167,0,0,0,0,2.333H43.583A1.167,1.167,0,0,0,44.75,53.167Z" transform="translate(-8.751 -18.333)" fill="#202529" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </a>
                                                                            </div>

                                                                            <div className="">
                                                                                {openDropdownId === subtaskdetail.id && (
                                                                                    <div className="p-3 rounded-3 bg-white mt-3 position-absolute start-0 subdrop" style={{ zIndex: 1000 }} onClick={(e) => e.stopPropagation()}>
                                                                                        <ul className="p-0">
                                                                                            {/* Search Input */}
                                                                                            <div className="srchinp position-relative">
                                                                                                <input type="text" value={searchSubTaskTerm} onChange={handleSearchChange} className="custom-select-search form-input bg-transparent w-100 ps-5" placeholder="Search..." />
                                                                                                <i className="fa fa-search" style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)", width: "fit-content" }}></i>
                                                                                            </div>

                                                                                            {/* Map over assignData */}
                                                                                            {filteredData.length > 0 ? (
                                                                                                filteredData.map((staff, index) => (
                                                                                                    <li
                                                                                                        key={index}
                                                                                                        className="my-1"
                                                                                                        onClick={() => handleStaffSelect(staff, subtaskdetail.id)} // Add onClick to handle selection
                                                                                                        style={{ cursor: "pointer" }} // Optional: change cursor to pointer to indicate clickable
                                                                                                    >
                                                                                                        <p>{staff.name}</p>
                                                                                                    </li>
                                                                                                ))
                                                                                            ) : (
                                                                                                <li className="my-1">
                                                                                                    <p>No results found</p>
                                                                                                </li>
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </td>
                                                                {/* <td><span className="normal-badge">Normal</span></td> */}
                                                                <td>
                                                                    {subtaskdetail.priority && (
                                                                        <span
                                                                            style={{
                                                                                backgroundColor: convertHexToRGBA(subtaskdetail.priority.color_code, 0.2),
                                                                                padding: "3px 10px",
                                                                                borderRadius: "8px",
                                                                                textAlign: "center",
                                                                                width: "fit-content",
                                                                                fontSize: "14px",
                                                                                color: subtaskdetail.priority.color_code,
                                                                            }}
                                                                        >
                                                                            {subtaskdetail.priority.name}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td className="dropposition">
                                                                    <div
                                                                        className="statusbtn-new"
                                                                        onClick={() => toggleStatusDropdown(subtaskdetail.id)} // Toggle dropdown on click
                                                                    >
                                                                        <label>
                                                                            {selectedStatuses[subtaskdetail?.id]?.name || "Select Status"} {/* Display selected status */}
                                                                        </label>
                                                                        <svg
                                                                            className={`${isDropdownOpen(subtaskdetail.id) ? "" : "rot"}`} // Rotate icon based on state
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="9.447"
                                                                            height="7.875"
                                                                            viewBox="0 0 9.447 7.875"
                                                                        >
                                                                            <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                        </svg>
                                                                    </div>
                                                                    <div
                                                                        className={`st-dropdown mainshadow p-2 rounded-md bg-white ${isDropdownOpen(subtaskdetail.id) ? "show" : ""}`} // Toggle visibility
                                                                    >
                                                                        <ul className="p-0">
                                                                            {filteredOptionsStatus.map((option) => (
                                                                                <li
                                                                                    key={option.id}
                                                                                    className="fs-14"
                                                                                    onClick={() => handleStatusSelect(option, subtaskdetail.id)} // Update status on click
                                                                                >
                                                                                    {option.name}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4 overflow-hidden">
                        <div class="right-task-edit overflow-y-scroll">
                            <div className="row mt-4 px-4">
                                <label className="fs-18 px-1">Interaction Type</label>
                                <div className={`select-box t_blue ${dropdownState.isOpen ? "active" : ""}`} onClick={() => handleDropdownOpen("interaction")}>
                                    {dropdownState.selectedOption ? dropdownState.selectedOption.name : "Select"}
                                </div>
                                {dropdownState.isOpen && (
                                    <div className="options-container pb-2">
                                        <div className="srchinput position-relative">
                                            <input type="text" placeholder="Search..." value={dropdownState.search} onChange={(e) => setDropdownState((prevState) => ({ ...prevState, search: e.target.value }))} className="search-field t_blue" />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0" onClick={() => setDropdownState((prevState) => ({ ...prevState, isOpen: false }))}></i>
                                        </div>
                                        {filteredOptions.map((option) => (
                                            <div key={option.id} className="option" onClick={() => handleSelect(option)}>
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowInderaction(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 relative">
                                <label className="fs-18 px-1">Priority</label>

                                <div className={`select-box ${dropdownState.isPriorityOpen ? "active" : ""}`} onClick={() => handleDropdownOpen("priority")}>
                                    {dropdownState.prioritySelectedOption ? dropdownState.prioritySelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isPriorityOpen && (
                                    <div className="options-container pb-2">
                                        <div className=" srchinput position-relative">
                                            <input type="text" placeholder="Search..." value={dropdownState.prioritySearch} onChange={(e) => setDropdownState((prevState) => ({ ...prevState, prioritySearch: e.target.value }))} className="search-field t_blue" />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0" onClick={() => setDropdownState((prevState) => ({ ...prevState, isPriorityOpen: false }))}></i>
                                        </div>
                                        {filteredOptionsPriority.map((option) => (
                                            <div key={option.id} className="option d-flex align-items-center justify-content-between gap-2" onClick={() => handleSelectPriority(option)}>
                                                {option.name}

                                                <div
                                                    className="color-circle"
                                                    style={{
                                                        backgroundColor: option.color_code,
                                                        width: "8px",
                                                        height: "8px",
                                                        borderRadius: "100%",
                                                        border: option.color_code ? "none" : "1px solid #ccc",
                                                    }}
                                                ></div>
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowPriority(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1" onClick={handleAssigneeClick}>
                                    Assignee
                                </label>
                                <div className="d-flex align-items-center gap-1 flex-wrap select-box">{displayStaffNames()}</div>
                                {showAssignOpen && (
                                    <div className="options-container">

                                        <div className="srchinput position-relative">
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                placeholder="Search staff..."
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                            <i
                                                className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0"
                                                onClick={() => setShowAssignOpen(false)} // Close the modal
                                            ></i>
                                        </div>
                                        <ul className="px-3">
                                            {assignData &&
                                                assignData
                                                    .filter((staff) => staff.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                                    .map((staff) => (
                                                        <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                            <label className="fw-semibold text-black">
                                                                <img src={require("../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                                {staff?.name}
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                name="staff_id"
                                                                id={`staff_id_${staff.id}`}
                                                                value={staff.id} // Ensure the value is correctly set to staff.id
                                                                checked={assigneeIds.includes(staff.id)}
                                                                onChange={() => toggleAssignee(staff.id)}
                                                            />
                                                        </li>
                                                    ))}
                                            <li className="option text-blue d-flex align-items-center gap-1 text-blue mt-1 text-start px-0" onClick={() => setShowAssignModal(true)}>
                                                <span className="addplus">+</span> Add More
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div className="row mt-4 px-4 position-relative">
                                <label className="fs-18 px-1">Status</label>
                                <div className={`select-box ${dropdownState.isStatusOpen ? "active" : ""}`} onClick={() => handleDropdownOpen("status")}>
                                    {dropdownState.statusSelectedOption ? dropdownState.statusSelectedOption.name : "Select"}
                                </div>
                                {dropdownState.isStatusOpen && (
                                    <div className="options-container">
                                        <div className="srchinput position-relative">
                                            <input type="text" placeholder="Search..." value={dropdownState.statusSearch} onChange={(e) => setDropdownState((prevState) => ({ ...prevState, statusSearch: e.target.value }))} className="search-field" />
                                            <i className="fa fa-close position-absolute pe-3 align-content-center top-0 end-0" onClick={() => setDropdownState((prevState) => ({ ...prevState, isStatusOpen: false }))}></i>
                                        </div>
                                        {filteredOptionsStatus.map((option) => (
                                            <div key={option.id} className="option" onClick={() => handleSelectStatus(option)}>
                                                {option.name}
                                            </div>
                                        ))}
                                        <div
                                            className="option text-blue text-start d-flex align-items-center gap-1 py-2  mb-2"
                                            onClick={() => {
                                                handleDropdownOpen(null);
                                                setShowStatus(true);
                                            }}
                                        >
                                            <span className="addplus">+</span> Add More
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row px-4 date mt-4">
                                <h4 className="fs-18 px-1 fw-bold text-black">Start Date</h4>
                                <div className="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input
                                        type="date"
                                        name="start_date"
                                        className="form-input mt-1"
                                        // value={editTaskDetail?.start_date} // Ensure this is correctly bound
                                        // onChange={(e) => {
                                        //     setEditTaskDetail({ ...editTaskDetail, start_date: e.target.value });
                                        // }}
                                        value={editTaskDetail.start_date}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask mask="99:99" maskChar={null} name="start_time" placeholder="HH:MM" className="form-input mt-1" value={editTaskDetail.start_time} onChange={handleInputChange} />
                                </div>
                                <div className="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select className="form-input mt-1 px-3 bg-white" name="start_time_format" value={timeFormats.start_time_format} onChange={handleFormatChange}>
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div className="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2 cursor-pointer">Clear</p>
                                </div>
                            </div>

                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">End Date</h4>
                                <div class="col-md-7 ps-0 pe-2">
                                    <label>Date</label>
                                    <input type="date" name="end_date" class="form-input mt-1" value={editTaskDetail.end_date} onChange={handleInputChange} />
                                </div>
                                <div class="col-md-3 ps-0 pe-2">
                                    <label>Time</label>
                                    <InputMask mask="99:99" maskChar={null} name="end_time" className="form-input mt-1 " placeholder="HH:MM" value={editTaskDetail.end_time} onChange={handleInputChange} />
                                </div>
                                <div class="col-md-2 ps-0 pe-1">
                                    <label>Format</label>
                                    <select class="form-input mt-1 px-3 bg-white" name="end_time_format" value={timeFormats.end_time_format} onChange={handleFormatChange}>
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                </div>
                                <div class="col-md-12 ps-0 pe-2">
                                    <p className="text-danger text-end fs-14 mt-2 cursor-pointer">Clear</p>
                                </div>
                            </div>
                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">Estimation Time</h4>
                                <div class="row py-2 px-0">
                                    <div class="col-md-12 ps-0 pe-2">
                                        <input type="text" name="estimation_time" class="form-input mt-1 bg-white" placeholder="1w 1d 1h 1m" value={editTaskDetail.estimation_time} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div class="row px-4 date mt-4">
                                <h4 class="fs-16 px-1 fw-bold text-black">Time Spent</h4>
                                <div class="row py-2 px-0">
                                    <div class="col-md-12 ps-0 pe-2">
                                        <input type="text" name="time_spent" class="form-input mt-1 bg-white" placeholder="1w 1d 1h 1m" value={editTaskDetail.time_spent} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={editTaskModal} onHide={() => setEditTaskModal(false)} centered size="xl" contentClassName="mx-auto" className="modal modal-lg email-modal" backdrop="static" keyboard={false}>
                <div className="modal-body p-4 position-relative">
                    <form onSubmit={handleSubmit(updateTaskHandle)}>
                        <div className="row">
                            <div className="col-md-12 left-task-modal p-0">
                                <div className="d-flex align-items-end justify-content-between gap-4">
                                    <div className="w-100">
                                        <h4 className="fs-20">Edit Task</h4>
                                        <input
                                            type="text"
                                            className="w-bold text-black p-0 fs-3 h-0 det-input w-100"
                                            placeholder="Add Details"
                                            name="name"
                                            value={editTaskDetail?.name} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, name: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <Modal.Header closeButton className="close-icon border-0 p-0" />
                                </div>
                                <hr />
                                <div className="mt-3">
                                    <div className="input-group">

                                        <JoditEditor
                                            value={editTaskDetail?.description}

                                            config={config}
                                            onChange={(newContent) => setEditTaskDetail({ ...editTaskDetail, description: newContent })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="main-wrapper">
                                        <div
                                            className="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2"
                                            onDragOver={handleDragOver}
                                            onDragEnter={handleDragEnter}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}

                                        >
                                            <h4 className="fs-14">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                    <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529" />
                                                </svg>
                                                Drag &amp; drop here
                                            </h4>
                                            <div className="upload-wrapper cursor-pointer">
                                                <input type="file" id="file" name="file" multiple onChange={handleFileChange} />
                                                <i className="fa fa-upload me-2"></i>
                                                <span className="file-upload-text cursor-pointer">Browse Files</span>
                                            </div>
                                        </div>

                                        {/* <div className="image-preview-area">
                                            {editTaskDetail?.documents.map((preview, index) => (
                                                <div key={`preview-${preview.id}`} className="image-preview position-relative">
                                                    <img src={preview?.document_path} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}

                                            {selectedFiles.map((file, index) => (
                                                <div key={`file-${index}`} className="image-preview position-relative">
                                                    <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div> */}
                                        <div className="image-preview-area">
                                            {/* Existing documents */}
                                            {editTaskDetail?.documents.map((preview, index) => {
                                                const filePath = preview?.document_path;
                                                const isImage = filePath.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                                const isPDF = filePath.match(/\.pdf$/i); // Check if the file is a PDF
                                                const isDOC = filePath.match(/\.docx?$/i); // Check if the file is a Word document
                                                const isXLS = filePath.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                                return (
                                                    <div key={`preview-${index}`} className="image-preview position-relative">
                                                        {isImage ? (
                                                            <img
                                                                src={filePath}
                                                                alt="Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isPDF ? (
                                                            <img
                                                                src={require("../../../assets/images/pdfimage.png")}
                                                                alt="PDF Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isDOC ? (
                                                            <img
                                                                src={require("../../../assets/images/docimage.png")}
                                                                alt="DOC Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isXLS ? (
                                                            <img
                                                                src={require('../../../assets/images/xlsimage.png')}
                                                                alt="XLS Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : (
                                                            <span>Unsupported file type</span>
                                                        )}
                                                        <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                                <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                    <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                    <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            })}

                                            {/* New file previews */}
                                            {selectedFiles.map((file, index) => {
                                                const fileName = file.name;
                                                const isImage = fileName.match(/\.(jpeg|jpg|gif|png)$/i); // Check if the file is an image
                                                const isPDF = fileName.match(/\.pdf$/i); // Check if the file is a PDF
                                                const isDOC = fileName.match(/\.docx?$/i); // Check if the file is a Word document
                                                const isXLS = fileName.match(/\.xlsx?$/i); // Check if the file is an Excel file

                                                return (
                                                    <div key={`file-${index}`} className="image-preview position-relative">
                                                        {isImage ? (
                                                            <img
                                                                src={URL.createObjectURL(file)}
                                                                alt="Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isPDF ? (
                                                            <img
                                                                src={require("../../../assets/images/pdfimage.png")}
                                                                alt="PDF Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isDOC ? (
                                                            <img
                                                                src={require("../../../assets/images/docimage.png")}
                                                                alt="DOC Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : isXLS ? (
                                                            <img
                                                                src={require('../../../assets/images/xlsimage.png')}
                                                                alt="XLS Preview"
                                                                style={{ width: 100, height: 100 }}
                                                            />
                                                        ) : (
                                                            <span>Unsupported file type</span>
                                                        )}
                                                        <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                                <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                    <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                    <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                                </g>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="main-wrapper edittask">
                                        <hr className="my-4" />

                                        <div className="row bg-white flex-wrap p-3 shadow---main rounded-4">
                                            <div className="col-md-12">
                                                <h4 class="fs-18 font-semibold">Schedule</h4>
                                                <p className="fs-14 text-muted">Choose the terms and start time to ensure that your customer receives your task notification on the correct day.</p>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="" class="w-100">
                                                    Repeat this Task
                                                </label>
                                                <select name="reccuring_repeat" id="reccuring_repeat" className="form-input mt-3" value={recurringRepeat} onChange={handleSelectChange}>
                                                    <option value="" disabled="">
                                                        Select
                                                    </option>
                                                    <option value="1">Week</option>
                                                    <option value="2">Monthly</option>
                                                    <option value="3">Half Monthly</option>
                                                    <option value="4">Yearly</option>
                                                    <option value="5">Day</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="" class="w-100">
                                                    Create First Task On
                                                </label>
                                                <input type="date" className="form-input mt-3" name="reccuring_start_date" id="reccuring_start_date" value={editTaskDetail?.recurring?.reccuring_start_date} onChange={handleRecurringInputChange} />
                                            </div>

                                            <div className="col-md-4 mt-3">
                                                <label className="w-100">Task End On</label>
                                                <div className="d-flex align-items-center gap-2 mt-3">
                                                    <input
                                                        type="date"
                                                        className="form-input"
                                                        name="reccuring_end_date"
                                                        id="reccuring_end_date"
                                                        value={editTaskDetail?.recurring?.reccuring_end_date}
                                                        onChange={handleRecurringInputChange}
                                                        disabled={editTaskDetail?.recurring?.reccuring_expire === "1"} // Disable when expire is checked
                                                    />
                                                    <span>-</span>
                                                    <input type="checkbox" name="reccuring_expire" id="reccuring_expire" className="form-input w-fit h-24 w-24" checked={editTaskDetail?.recurring?.reccuring_expire === "1"} onChange={handleRecurringInputChange} />
                                                    <label for="reccuring_expire" className="fs-14">
                                                        Never Expires
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label class="w-100">Send Email Automatically</label>
                                                <select name="reccuring_email_automatic" id="reccuring_email_automatic" class="form-input mt-3" value={editTaskDetail?.recurring?.reccuring_email_automatic} onChange={handleRecurringInputChange}>
                                                    <option value="" disabled="">
                                                        Select
                                                    </option>
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </div>
                                            <div class="col-md-4 mt-3">
                                                <label for="" class="w-100">
                                                    Status
                                                </label>
                                                <select name="reccuring_status" id="reccuring_status" class="form-input mt-3" value={editTaskDetail?.recurring?.reccuring_status} onChange={handleRecurringInputChange}>
                                                    <option value="" disabled="">
                                                        Select
                                                    </option>
                                                    <option value="1">Active</option>
                                                    <option value="2">Inactive</option>
                                                    <option value="3">Stopped</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-btns gap-4 mt-4 d-flex align-items-center justify-content-between gap-2">
                                    {/* <div className="view-full">
                                        <button className="view-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 15 15">
                                                <path id="expand" d="M5.309.938a.938.938,0,0,1-.937.938h-2.5v2.5A.938.938,0,1,1,0,4.371V.938A.938.938,0,0,1,.938,0H4.371A.938.938,0,0,1,5.309.938ZM4.371,13.125h-2.5v-2.5a.938.938,0,0,0-1.875,0v3.434A.938.938,0,0,0,.938,15H4.371a.938.938,0,0,0,0-1.875Zm9.691-3.434a.938.938,0,0,0-.937.938v2.5h-2.5a.938.938,0,0,0,0,1.875h3.434A.938.938,0,0,0,15,14.063V10.629A.937.937,0,0,0,14.063,9.691Zm0-9.691H10.629a.938.938,0,0,0,0,1.875h2.5v2.5a.938.938,0,0,0,1.875,0V.938A.937.937,0,0,0,14.063,0Z" fill="#77a0df" />
                                            </svg>
                                            View in full page
                                        </button>
                                    </div> */}
                                    <span className="d-flex gap-2">
                                        <a>
                                            <button className="btn-cancel">Cancel</button>
                                        </a>
                                        <a>
                                            <button className="btn-blue" type="submit">
                                                Update
                                            </button>
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
};
export default Recurring_Task_Details;
