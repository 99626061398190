import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { handleDesignationData } from '../../http/help';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios';


const Add_Contact = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const businessId = JSON.parse(localStorage.getItem("BusinessId"));
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));
    const { contactModal, setContactModal, fetchContactData } = props;
    const [designationList, setDesignationList] = useState([]);
    const [primaryContact, setPrimaryContact] = useState("0");
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false); // To handle loading state
    const [error, setError] = useState(null);
    const [names, setNames] = useState([]);
    const [contactProfile, setContactProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [isLinked, setIsLinked] = useState(false);

    // Function to handle search input changes
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value) {
            handleSinSearch(event.target.value); // Trigger API call on search
        }
    };

    // Function to make API request when user searches
    const handleSinSearch = async (searchQuery) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "POST",
                    url: "contact/search/contactbyname",
                    isSecure: true,
                    body: {
                        search: searchQuery, // Sending search query as the body
                    }
                },
                (res) => {
                    setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch names"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch names"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };

    // Filter the names based on the search term, filtering by both `first_name` and `company_name` fields
    const filteredNames = names?.filter((contact) =>
        contact.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        contact.company_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Function to handle clicking on a name and show the modal
    const handleNameClick = (contact) => {
        setSelectedContact(contact); // Store the clicked client's information
        handleContactProfile(contact.id); // Fetch the client profile using the selected client's ID
        setShowModal(true); // Show the modal
    };
    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedContact(null); // Clear the selected client when modal closes
    };



    const handleDesignationList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `designation/listing`,
                    isSecure: true,
                },
                (res) => {
                    setDesignationList(res?.data?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        handleDesignationList();
    }, []);

    // const onSubmitContact = async (data) => {
    //     const payload = {
    //         client_type: "1",
    //         first_name: data.first_name || "",
    //         last_name: data.last_name || "",
    //         phone_number: data.phone_number,
    //         designation_id: data.designation_id, // assuming designation_id maps to designation
    //         primary_contact: primaryContact,
    //         address: data.address,
    //         city: data.city,
    //         province: data.province,
    //         postal_code: data.postal_code,
    //         notes: data.notes,
    //         email: data.email,
    //         flag: "1"
    //     };

    //     try {
    //         await http(
    //             {
    //                 method: "POST",
    //                 url: `contact/add`,
    //                 body: payload,
    //                 isSecure: true,
    //             },
    //             (res) => {
    //                 toast.success(res.message);
    //                 setContactModal(false);
    //                 fetchContactData();
    //             }
    //         );
    //     } catch (error) {
    //         console.error('Error adding contact:', error);
    //     }
    // };


    const onSubmitContact = async (data) => {
        const payload = {
            client_type: "1",
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            phone_number: data.phone_number,
            designation_id: data.designation_id,
            primary_contact: primaryContact,
            address: data.address,
            city: data.city,
            province: data.province,
            postal_code: data.postal_code,
            notes: data.notes,
            email: data.email,
            flag: "1"
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/contact/add`, payload, {
                headers: {
                    'Authorization': `Bearer ${userID?.access_token}`, // Add token if needed for secure requests
                    'Content-Type': 'application/json'
                }
            });

            // Check if the response indicates a success or failure
            if (res.data.success) {
                // Handle successful response
                toast.success(res.data.message);

                // Reset the form fields
                reset({
                    first_name: "",
                    last_name: "",
                    phone_number: "",
                    designation_id: "",
                    address: "",
                    city: "",
                    province: "",
                    postal_code: "",
                    notes: "",
                    email: ""
                });

                // Reset state values
                setAddress("");
                setCity("");
                setProvince("");
                setPrimaryContact("0");
                setContactModal(false);
                fetchContactData();
            } else {
                // Handle error response even if status is 200
                const errorData = res.data.error;

                // Display validation errors if available
                if (errorData) {
                    const errorMessages = Object.entries(errorData)
                        .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
                        .join('\n');

                    toast.error(`Error: ${errorMessages}`);
                } else {
                    // General error message if no specific validation errors
                    toast.error("An error occurred while adding the contact.");
                }
            }
        } catch (error) {
            // Log unexpected errors and show a generic error message
            console.error("Error adding contact:", error);
            toast.error("An unexpected error occurred.");
        }
    };

    const onSubmitContactLink = async () => {
        const payload = {
            id: contactProfile?.id,
            client_type: "1",
            first_name: contactProfile.first_name || "",
            last_name: contactProfile.last_name || "",
            phone_number: contactProfile.phone_number,
            designation_id: contactProfile.designation_id, // assuming designation_id maps to designation
            primary_contact: primaryContact,
            address: contactProfile.address,
            city: contactProfile.city,
            province: contactProfile.province,
            postal_code: contactProfile.postal_code,
            notes: contactProfile.notes,
            email: contactProfile.email,
            flag: "1",

        };

        try {
            await http(
                {
                    method: "POST",
                    url: `contact/linktobusiness/${businessId}`,
                    body: payload,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    setShowModal(false);
                    fetchContactData();
                    setContactModal(false);
                }
            );
        } catch (error) {
            console.error('Error adding contact:', error);
        }
    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';
                let selectedCity = '';

                // Extract the province (administrative_area_level_1), postal code, and city (locality)
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City name
                    }
                });

                // Set the selected address, province, postal code, and city in the form
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setValue('city', selectedCity);

                setProvince(selectedProvince); // Update the province state
                setCity(selectedCity); // Update the city state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleChange = (value) => {
        setAddress(value);
    };


    const onHideHandler = () => {
        setContactModal(false);

    };

    // Function to make API request to get client profile
    const handleContactProfile = (contactId) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "GET",
                    url: `contact/${contactId}/view`, // API call with selected client's ID
                    isSecure: true
                },
                (res) => {
                    setContactProfile(res.data || []); // Store the client profile data
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch client profile"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch client profile"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };



    return (
        <>
            <Modal
                show={contactModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between px-4 pt-4 pb-0">
                    <h4 className="fs-20 fw-semibold">Add Contact</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6 dctor-search">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-input dtr-srch"
                                    placeholder="Search for a name"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <i className="fa fa-search"></i>
                                <i className="fa fa-times"
                                    onClick={() => setSearchTerm('')}
                                ></i>


                                {searchTerm && (
                                    <ul>
                                        {loading ? (
                                            <li>Loading...</li>
                                        ) : error ? (
                                            <li>{error}</li>
                                        ) : filteredNames.length > 0 ? (
                                            filteredNames.map((contact) => (
                                                <li key={contact.id} onClick={() => handleNameClick(contact)}>
                                                    <strong>{contact.first_name}</strong> {/* Display first name */}
                                                    {contact.company_name && (
                                                        <strong>{contact.company_name}</strong>// Display company name if available
                                                    )}
                                                </li>
                                            ))
                                        ) : (
                                            <li>No names found</li>
                                        )}
                                    </ul>
                                )}

                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmitContact)}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">First Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="first_name"
                                    {...register("first_name", {
                                        required: "Required",
                                        validate: (value) => value.trim() !== "" || "Cannot be empty or just spaces",
                                    })}
                                />
                                {errors?.first_name && <span className="error">{errors?.first_name.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="last_name"
                                    {...register("last_name", {
                                        required: "Required",
                                        validate: (value) => value.trim() !== "" || "Cannot be empty or just spaces",
                                    })}
                                />
                                {errors?.last_name && <span className="error">{errors?.last_name.message}</span>}
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Designation <span className="text-danger">*</span></label>
                                <select
                                    {...register('designation_id', {
                                        required: "Required",
                                    })}
                                    className={`form-input mx-0 mt-1 ${errors?.designation_id ? 'error-border' : ''}`} // Optional: Add error styling
                                >
                                    <option selected disabled value="">Select</option>
                                    {designationList && designationList.length > 0 ? (
                                        designationList.map((designation, index) => (
                                            <option key={index} value={designation.id}>
                                                {designation.name}
                                            </option>
                                        ))
                                    ) : (
                                        <option value="" disabled>Loading designations...</option>
                                    )}
                                </select>
                                {errors?.designation_id && <span className="error">{errors?.designation_id.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                                <input
                                    type="tel"
                                    name="phone_number"
                                    id="phone_number"
                                    maxLength={15}
                                    className="form-input mt-2"
                                    placeholder="Phone No."
                                    {...register("phone_number", {
                                        required: "Required",
                                        validate: (value) => value.trim() !== "" || "Cannot be empty or just spaces",

                                        minLength: {
                                            value: 7,
                                            message: "Phone number must be at least 7 digits long"
                                        },
                                    })}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "e" || // Prevent 'e'
                                            e.key === "E" || // Prevent 'E'
                                            e.key === "+" || // Prevent '+'
                                            e.key === "-" || // Prevent '-'
                                            e.key === "." || // Prevent '.'
                                            isNaN(Number(e.key)) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight"
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {errors?.phone_number && <span className="error">{errors?.phone_number.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                <input
                                    type="email"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    name="email"
                                    {...register("email",
                                        {
                                            required: "Required",
                                            validate: (value) => value.trim() !== "" || "Cannot be empty or just spaces",
                                        }
                                    )}
                                />
                                {errors?.email && <span className="error">{errors?.email.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">Primary Contact</label>
                                <select
                                    className="form-input mt-2"
                                    name="primary_contact"
                                    id="primary_contact"
                                    value={primaryContact} // Bind to state
                                    onChange={(e) => setPrimaryContact(e.target.value)} // Update state on change
                                >
                                    <option disabled>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-4">
                                <label className="fs-18">Street Address</label>

                                <PlacesAutocomplete
                                    value={address}
                                    onChange={handleChange}  // Handle input change here
                                    onSelect={handleSelect}  // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>

                                {errors.address && (
                                    <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.address.message}</span>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Province</label>
                                <input
                                    className="form-input mt-2"
                                    name="province"
                                    id="province"
                                    value={province} // Bind the province state to this input field
                                    readOnly
                                    {...register("province", {})}
                                />
                                {/* {errors.province && (
                                <span className="error-message text-denger">{errors.province.message}</span>
                            )} */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-4">
                                <label className="fs-18">Postal Code</label>
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    readOnly
                                    {...register("postal_code", {

                                    })}
                                />
                                {/* {errors.postal_code && (
                                <span className="error-message text-denger">{errors.postal_code.message}</span>
                            )} */}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    value={city} // Bind city state here
                                    readOnly
                                    {...register("city", {})}
                                />
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="fs-18">Note</label>
                                <textarea
                                    id=""
                                    className="mt-2"
                                    rows="5"
                                    cols="10"
                                    name="notes"
                                    {...register("notes", {})}
                                >

                                </textarea>
                            </div>
                            <div className="col-md-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue px-4 ms-auto">Add</button>
                            </div>
                        </div>
                    </form>

                </div>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="lg"
                contentClassName="mx-auto p-4 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header p-0 justify-content-between align-items-center border-0">
                    <div className="lhead d-flex align-items-center gap-3">
                        <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                        <div>
                            <h6 className="fs-18 fw-semibold">
                                {contactProfile?.first_name} {contactProfile?.last_name}
                                {contactProfile?.primary_contact === "1" && (
                                    <span className="fs-12 text-blue"> Primary</span>
                                )}
                            </h6>

                            <span className="d-flex align-items-center gap-2">
                                <p className="fs-14 text-muted">{contactProfile?.designation?.name}</p>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                        <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                    </div>
                </div>

                <hr className="mt-3 mb-1" />

                <div className="services-form row">

                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">First Name</p>
                        <h4 className="fs-18 fw-semibold">
                            {contactProfile?.first_name}
                        </h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Last Name</p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.last_name}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Email</p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.email}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Phone No.</p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.phone_number}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Primary Contact</p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.primary_contact === "1" ? "Yes" : "No"}</h4>
                    </div>
                    <div className="col-lg-12 p-0 mt-3">
                        <p className="fs-14">Note</p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.notes} </h4>
                    </div>
                    <hr className="mt-3" />
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Street Address </p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.address}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">City </p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.city}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Province </p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.province}</h4>
                    </div>
                    <div className="col-lg-4 p-0 mt-3">
                        <p className="fs-14">Postal Code </p>
                        <h4 className="fs-18 fw-semibold">{contactProfile?.postal_code}</h4>
                    </div>
                    <div className="d-flex justify-content-between gap-4">
                        <button type="button" className="btn btn-gray text-dark mt-3"
                        //  onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}
                        >Go to Profile</button>
                        <div className="d-flex align-items-center gap-2">
                            <button
                                type="button"
                                className="btn btn-lightblue mt-3"
                                onClick={onSubmitContactLink} // Call the link function directly
                            >
                                {loading ? "Linking..." : isLinked ? "Linked" : "Link to Contact"}
                            </button>
                            {/* {clientProfile?.share_holder ? (
                                        <button
                                            type="button"
                                            className="btn btn-lightblue mt-3"
                                            onClick={handleLinkDirector} 
                                        >
                                            {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-gray mt-3"
                                            onClick={handleButtonClick}
                                        >
                                            Enter Shareholder
                                        </button>
                                    )} */}
                        </div>
                    </div>

                </div>
            </Modal>

        </>



    )
}
export default Add_Contact;