import React, { useState, useEffect } from 'react';
// Assuming http is imported from a utility file
import { http } from '../../../http/http';

const CustomFieldForm = ({ tableName }) => {
    const [fieldTypes, setFieldTypes] = useState([]);
    const [dateFormats, setDateFormats] = useState([]);
    const [timeFormats, setTimeFormats] = useState([]);
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [minLength, setMinLength] = useState('');
    const [maxLength, setMaxLength] = useState('');
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [dateFormat, setDateFormat] = useState('');
    const [timeFormat, setTimeFormat] = useState('');
    const [required, setRequired] = useState(false);
    const [options, setOptions] = useState(['']);
    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');


    useEffect(() => {
        // Fetch metadata
        try {
            http({
                method: "GET",
                url: `custom-field-meta-data`,
                isSecure: true,
            }, (res) => {
                if (res.success) {
                    setFieldTypes(res.data.field_type);
                    setDateFormats(res.data.date_formats);
                    setTimeFormats(res.data.time_formats);
                }
            });
        } catch (error) {
            console.error('There was an error fetching the metadata!', error);
        }
    }, []);

    const handleAddOption = () => {
        setOptions([...options, '']);
    };

    const handleRemoveOption = index => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    const handleOptionChange = (index, value) => {
        const newOptions = options.map((option, i) => (i === index ? value : option));
        setOptions(newOptions);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessages([]);
        setSuccessMessage('');

        const newErrors = [];

        if (!type) {
            newErrors.push('Type is required.');
        }

        if (!title || title.length > 255) {
            newErrors.push('Title is required and must be less than 255 characters.');
        }

        if (placeholder && placeholder.length > 255) {
            newErrors.push('Placeholder must be less than 255 characters.');
        }

        if (defaultValue && defaultValue.length > 255) {
            newErrors.push('Default value must be less than 255 characters.');
        }

        if (['checkbox', 'radio', 'dropdown'].includes(type)) {
            if (options.some(option => !option)) {
                newErrors.push('All options must be filled out.');
            }
            if (defaultValue && !options.includes(defaultValue)) {
                newErrors.push('Default value must be one of the options.');
            }
        }

        if (newErrors.length > 0) {
            setErrorMessages(newErrors);
            return;
        }

        const formData = {
            type,
            title,
            description,
            placeholder,
            default_value: defaultValue,
            min_length: minLength,
            max_length: maxLength,
            min_date: minDate,
            max_date: maxDate,
            date_format: dateFormat,
            time_format: timeFormat,
            required: required ? '1' : '0',
            options,
        };

        try {
            http({
                method: "POST",
                url: `custom-field/${tableName}`,
                isSecure: true,
                data: formData,
            }, (res) => {
                if (res.success) {
                    setSuccessMessage('Custom field created successfully.');
                    resetForm();
                } else {
                    setErrorMessages([res.message]);
                }
            });
        } catch (error) {
            if (error.response && error.response.data.errors) {
                setErrorMessages(Object.values(error.response.data.errors).flat());
            } else {
                setErrorMessages(['An unexpected error occurred.']);
            }
        }
    };

    const resetForm = () => {
        setType('');
        setTitle('');
        setDescription('');
        setPlaceholder('');
        setDefaultValue('');
        setMinLength('');
        setMaxLength('');
        setMinDate('');
        setMaxDate('');
        setDateFormat('');
        setTimeFormat('');
        setRequired(false);
        setOptions(['']);
        setErrorMessages([]);
    };

    return (
        <div>
            {/* <h1 className="fs-20 font-weight-bold">Custom Fields: {tableName}</h1> */}
            <div className="accord-head p-0 d-flex align-items-start justify-content-between pt-4 px-4">
                <h4 className="fs-20 fw-semibold">Custom Fields</h4>
                {/* <button className="add-new" onClick={handleAddField}>Create New Fields</button> */}
            </div>
            {/* {successMessage && <div className="alert alert-success">{successMessage}</div>} */}
            <div className="row px-3">
                <div className="col-md-12 mt-2">
                    <form className="px-3" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="type" className="w-100">Select Field Type</label>
                            <select className="form-input mt-1" id="type" name="type" value={type} onChange={(e) => setType(e.target.value)} required>
                                <option value="">Select Type</option>
                                {Object.entries(fieldTypes).map(([key, value]) => (
                                    <option key={key} value={key}>{value}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="input-grp">
                                <label htmlFor="title">Title:</label>
                                <input type="text" className="form-input mt-1" id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required maxLength="255" />
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="input-grp">
                                <label htmlFor="description">Description:</label>
                                <textarea rows="3" cols="10" className="mt-1" id="description" name="description" value={description} onChange={(e) => setDescription(e.target.value)} maxLength="60000"></textarea>
                            </div>
                        </div>

                        {['email', 'password', 'number', 'textarea', 'text'].includes(type) && (
                            <>
                                <div className="col-md-12 mt-3">
                                    <div className="input-grp">
                                        <label htmlFor="placeholder">Placeholder:</label>
                                        <input type="text" className="form-input mt-1" id="placeholder" name="placeholder" value={placeholder} onChange={(e) => setPlaceholder(e.target.value)} maxLength="255" />
                                    </div>
                                </div>
                                <div className="form-group default_value mt-3">
                                    <label htmlFor="default_value">Default Value:</label>
                                    <input type="text" className="form-input" id="default_value" name="default_value" value={defaultValue} onChange={(e) => setDefaultValue(e.target.value)} maxLength="255" />
                                </div>
                                <div className="form-group min_length mt-3">
                                    <label htmlFor="min_length">Minimum Length:</label>
                                    <input type="number" className="form-input" id="min_length" name="min_length" value={minLength} onChange={(e) => setMinLength(e.target.value)} />
                                </div>
                                <div className="form-group max_length mt-3">
                                    <label htmlFor="max_length">Maximum Length:</label>
                                    <input type="number" className="form-input" id="max_length" name="max_length" value={maxLength} onChange={(e) => setMaxLength(e.target.value)} />
                                </div>
                            </>
                        )}
                        {['checkbox', 'radio', 'dropdown'].includes(type) && (
                            <div lassName="form-group options mt-3">
                                <label>Options:</label>
                                {options.map((option, index) => (
                                    <div key={index} className="d-flex align-items-center mt-2">
                                        <input type="text" className="form-input" value={option} onChange={(e) => handleOptionChange(index, e.target.value)} placeholder={`Option ${index + 1}`} maxLength="255" />
                                        <button type="button" className="btn btn-link text-danger ms-2" onClick={() => handleRemoveOption(index)}>-</button>
                                    </div>
                                ))}
                                <button type="button" className="btn btn-link mt-2" onClick={handleAddOption}>Add Option</button>
                            </div>
                        )}
                        {type === 'date' && (
                            <>
                                <div className="form-group date_format mt-3">
                                    <label htmlFor="date_format">Date Format:</label>
                                    <select className="form-input" id="date_format" name="date_format" value={dateFormat} onChange={(e) => setDateFormat(e.target.value)}>
                                        <option value="">Select Date Format</option>
                                        {dateFormats.map((format, index) => (
                                            <option key={index} value={format}>{format}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group min_date mt-3">
                                    <label htmlFor="min_date">Minimum Date:</label>
                                    <input type="date" className="form-input" id="min_date" name="min_date" value={minDate} onChange={(e) => setMinDate(e.target.value)} />
                                </div>
                                <div className="form-group max_date mt-3">
                                    <label htmlFor="max_date">Maximum Date:</label>
                                    <input type="date" className="form-input" id="max_date" name="max_date" value={maxDate} onChange={(e) => setMaxDate(e.target.value)} />
                                </div>
                            </>
                        )}
                        {type === 'time' && (
                            <div className="form-group">
                                <label htmlFor="time_format">Time Format:</label>
                                <select className="form-control" id="time_format" name="time_format" value={timeFormat} onChange={(e) => setTimeFormat(e.target.value)}>
                                    <option value="">Select Time Format</option>
                                    {timeFormats.map((format, index) => (
                                        <option key={index} value={format}>{format}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="col-md-12 mt-3">
                            <input type="checkbox" className="input-grp d-flex align-items-center" id="required" name="required" checked={required} onChange={(e) => setRequired(e.target.checked)} />
                            <label className="form-check-label" htmlFor="required">Required</label>
                        </div>

                        {errorMessages.length > 0 && (
                            <div className="text-danger">
                                {errorMessages.map((msg, index) => (
                                    <div key={index}>{msg}</div>
                                ))}
                            </div>
                        )}

                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                            <button className="add-new" type="submit">Save Custom Form</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CustomFieldForm;
