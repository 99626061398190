import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import CustomForms from './customization/CustomForms';
import CustomFields from './customization/CustomFields';
import TagServices from './customization/TagServices';
import Users from './userrole/Users';
import Roles from './userrole/Roles';
import Permissions from './userrole/Permissions';
import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
import Auth from "../../../auth/Auth";

const Setting = () => {
    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');
    const [selectedSubComponent, setSelectedSubComponent] = useState('');
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const [modulePermissions, setModulePermissions] = useState({});
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));

    const hasAnyPermissionForModules = (modules) => {
        // Check if the user has full access
        const hasFullAccess = !permissionIds?.some((id) => id?.id === userID?.user?.id);

        // If full access, immediately return true
        if (hasFullAccess) return true;

        // Check if user has any permission for the specified modules
        return modules.some((module) => {
            const modulePerms = modulePermissions[module] || [];
            return permission?.permissions?.some((perm) => modulePerms.includes(perm));
        });
    };
    const handleSubComponentSelect = (subComponent, item) => {
        setActiveItem(item);
        setSelectedSubComponent(subComponent);
    };


    const renderSelectedSubComponent = () => {
        switch (selectedSubComponent) {
            case 'CustomFormsComponent':
                return <CustomForms />;
            case 'CustomFieldsComponent':
                return <CustomFields />;
            case 'CurrenciesComponent':
                return <TagServices />;
            case 'UsersComponent':   // Added UsersComponent case
                return <Users />;
            case 'RolesComponent':   // Added RolesComponent case
                return <Roles />;
            case 'PermissionsComponent':   // Added RolesComponent case
                return <Permissions />;
            // case 'PermissionsComponent':  // Added PermissionsComponent case
            //     return <PermissionsComponent />;
            default:
                return null;
        }
    };
    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission, setModulePermissions);
        handlePermissionIdsData(setPermissionIds)
    }, []);
    return (
        <div className="right_section font-poppins pr--0 mt-2">
            <div className="settingpage">
                <div className="setting_left mt-3">
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll">
                        <h4 className="fs-20 fw-semibold pt-4 pb-2 px-4">Setting</h4>
                        <div className="setting-drop">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.116" height="18" viewBox="0 0 18.116 18">
                                            <g id="Organization" transform="translate(-2 -2.128)">
                                                <path id="Path_3908" data-name="Path 3908" d="M15.587,3.034a.906.906,0,0,0-1.11-.883L3.608,4.66a.906.906,0,0,0-.7.883v13.68H2.453a.453.453,0,0,0,0,.906H7.435V17.411a.906.906,0,0,1,.906-.906h1.812a.906.906,0,0,1,.906.906v2.717h4.529ZM6.076,7.447a.453.453,0,0,0-.453.453v.906a.453.453,0,0,0,.453.453h.906a.453.453,0,0,0,.453-.453V7.9a.453.453,0,0,0-.453-.453Zm-.453,3.17a.453.453,0,0,1,.453-.453h.906a.453.453,0,0,1,.453.453v.906a.453.453,0,0,1-.453.453H6.076a.453.453,0,0,1-.453-.453Zm.453,2.264a.453.453,0,0,0-.453.453v.906a.453.453,0,0,0,.453.453h.906a.453.453,0,0,0,.453-.453v-.906a.453.453,0,0,0-.453-.453ZM8.341,7.9a.453.453,0,0,1,.453-.453H9.7a.453.453,0,0,1,.453.453v.906a.453.453,0,0,1-.453.453H8.793a.453.453,0,0,1-.453-.453Zm.453,2.264a.453.453,0,0,0-.453.453v.906a.453.453,0,0,0,.453.453H9.7a.453.453,0,0,0,.453-.453v-.906a.453.453,0,0,0-.453-.453Zm-.453,3.17a.453.453,0,0,1,.453-.453H9.7a.453.453,0,0,1,.453.453v.906a.453.453,0,0,1-.453.453H8.793a.453.453,0,0,1-.453-.453Zm3.17-5.888a.453.453,0,0,0-.453.453v.906a.453.453,0,0,0,.453.453h.906a.453.453,0,0,0,.453-.453V7.9a.453.453,0,0,0-.453-.453Zm-.453,3.17a.453.453,0,0,1,.453-.453h.906a.453.453,0,0,1,.453.453v.906a.453.453,0,0,1-.453.453h-.906a.453.453,0,0,1-.453-.453Zm.453,2.264a.453.453,0,0,0-.453.453v.906a.453.453,0,0,0,.453.453h.906a.453.453,0,0,0,.453-.453v-.906a.453.453,0,0,0-.453-.453Z" transform="translate(0 0)" fill-rule="evenodd" />
                                                <path id="Path_3909" data-name="Path 3909" d="M18,5.691V20.464h3.17a.453.453,0,0,0,0-.906h-.453V7.609a.906.906,0,0,0-.5-.81Z" transform="translate(-1.507 -0.336)" />
                                                <path id="Path_3910" data-name="Path 3910" d="M10.812,21.717H9V19h1.812Z" transform="translate(-0.659 -1.589)" />
                                            </g>
                                        </svg>
                                        Organization</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.299" height="18" viewBox="0 0 17.299 18">
                                            <path id="Compliance" d="M10.263,12.087a2.083,2.083,0,0,1,1.15-1.879l1.87-.954a2.133,2.133,0,0,1,.954-.224h2.571V4.821A2.57,2.57,0,0,0,14.237,2.25H5.821A2.57,2.57,0,0,0,3.25,4.821V16.977a2.57,2.57,0,0,0,2.571,2.571h6.415a7.375,7.375,0,0,1-1.973-5.021ZM5.821,5.055h6.545a.7.7,0,1,1,0,1.4H5.821a.7.7,0,0,1,0-1.4Zm2.805,7.013H5.821a.7.7,0,1,1,0-1.4H8.627a.7.7,0,1,1,0,1.4Zm.935-2.805H5.821a.7.7,0,0,1,0-1.4h3.74a.7.7,0,0,1,0,1.4Zm10.6,2.194-1.87-.949a.707.707,0,0,0-.318-.076h-3.74a.71.71,0,0,0-.318.076l-1.87.949a.7.7,0,0,0-.383.626V14.53a6.024,6.024,0,0,0,3.866,5.615,1.621,1.621,0,0,0,.575.1,1.659,1.659,0,0,0,.58-.106,6.024,6.024,0,0,0,3.863-5.614V12.082A.7.7,0,0,0,20.165,11.457Zm-2.132,2.938L16.35,16.279a.7.7,0,0,1-1.047,0l-1.122-1.257a.7.7,0,0,1,1.047-.934l.6.67,1.159-1.3a.7.7,0,0,1,1.047.934Z" transform="translate(-3.25 -2.25)" />
                                        </svg>
                                        Takes & Compliance</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="options" transform="translate(-2 -2)">
                                                <g id="Group_2006" data-name="Group 2006" transform="translate(2 2)">
                                                    <path id="Path_3911" data-name="Path 3911" d="M2.9,5.6H5.766a2.683,2.683,0,0,0,5.067,0H19.1a.9.9,0,0,0,0-1.8H10.833a2.683,2.683,0,0,0-5.067,0H2.9a.9.9,0,1,0,0,1.8Z" transform="translate(-2 -2)" />
                                                    <path id="Path_3912" data-name="Path 3912" d="M19.1,10.8H16.234a2.683,2.683,0,0,0-5.067,0H2.9a.9.9,0,0,0,0,1.8h8.267a2.683,2.683,0,0,0,5.067,0H19.1a.9.9,0,0,0,0-1.8Z" transform="translate(-2 -2.7)" />
                                                    <path id="Path_3913" data-name="Path 3913" d="M19.1,17.8H10.833a2.683,2.683,0,0,0-5.067,0H2.9a.9.9,0,0,0,0,1.8H5.766a2.683,2.683,0,0,0,5.067,0H19.1a.9.9,0,0,0,0-1.8Z" transform="translate(-2 -3.4)" />
                                                </g>
                                            </g>
                                        </svg>

                                        Preferences</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.415" height="18" viewBox="0 0 14.415 18">
                                            <g id="Sales" transform="translate(-50.94)">
                                                <path id="Path_3917" data-name="Path 3917" d="M297.895,338.724c-.152,0-.3.138-.3.6s.152.6.3.6.3-.138.3-.6S298.047,338.724,297.895,338.724Z" transform="translate(-237.971 -326.805)" />
                                                <path id="Path_3918" data-name="Path 3918" d="M196.948,273.787c-.152,0-.3.138-.3.6s.152.6.3.6.3-.138.3-.6S197.1,273.787,196.948,273.787Z" transform="translate(-140.576 -264.153)" />
                                                <path id="Path_3919" data-name="Path 3919" d="M63.418,6.386a19.889,19.889,0,0,1-2.925-4.117A9.479,9.479,0,0,1,59.784.6L59.625,0l-.567.253a5.662,5.662,0,0,0-2.509,2.5,5.712,5.712,0,0,0-.525,2.529.814.814,0,0,1-1.39.579L53.58,4.8l-.363.5c-.038.052-.925,1.276-1.087,1.52a7.208,7.208,0,1,0,13.225,3.968A6.851,6.851,0,0,0,63.418,6.386Zm-3.495,7.486a1.238,1.238,0,0,1-1.288-1.35,1.289,1.289,0,1,1,2.576,0A1.238,1.238,0,0,1,59.924,13.871Zm-.706-4.916h1.17L57.078,13.8h-1.17Zm-4.134,1.281a1.289,1.289,0,1,1,2.576,0,1.289,1.289,0,1,1-2.576,0Z" transform="translate(0)" />
                                            </g>
                                        </svg>
                                        Sales</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.002" height="18" viewBox="0 0 18.002 18">
                                            <g id="Purchases" transform="translate(-2 -2.003)">
                                                <path id="Path_3914" data-name="Path 3914" d="M19.578,7.839a1.947,1.947,0,0,0-1.53-.739h-.361l.194-1.092a.643.643,0,0,0-.523-.746L12.539,4.42a.642.642,0,0,0-.744.521L11.41,7.1h-.655l.407-2.27a1.278,1.278,0,0,1,1.485-1.042l2.565.45-.3-1.7a.641.641,0,0,0-.746-.521L7.709,3.139a.634.634,0,0,0-.418.264.676.676,0,0,0-.109.482L7.745,7.1H6.462L5.909,4.7a.635.635,0,0,0-.624-.5H2.643a.643.643,0,0,0,0,1.286H4.771L6.79,14.237A1.943,1.943,0,0,0,8.7,15.748h8.249a1.943,1.943,0,0,0,1.91-1.511l1.093-4.751a1.92,1.92,0,0,0-.373-1.646ZM15.8,13.279H10.031a.643.643,0,0,1,0-1.286H15.8a.643.643,0,1,1,0,1.286Zm1.447-2.61H8.584a.643.643,0,0,1,0-1.286h8.667a.643.643,0,0,1,0,1.286Z" />
                                                <path id="Path_3915" data-name="Path 3915" d="M11.942,24.954a1.622,1.622,0,1,0,1.627,1.622A1.626,1.626,0,0,0,11.942,24.954Z" transform="translate(-2.969 -8.194)" />
                                                <path id="Path_3916" data-name="Path 3916" d="M23.92,24.954a1.622,1.622,0,1,0,1.627,1.622A1.626,1.626,0,0,0,23.92,24.954Z" transform="translate(-7.246 -8.194)" />
                                            </g>
                                        </svg>

                                        Purchases</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.306" height="18" viewBox="0 0 15.306 18">
                                            <path id="Path_3921" data-name="Path 3921" d="M694.676,1164.118a3.474,3.474,0,0,0,.217,1.376,3.517,3.517,0,0,0,.74,1.188,3.027,3.027,0,0,1,.657,2.078,3.252,3.252,0,0,1-.776,2.141,4.081,4.081,0,0,1-2.609,1.269c-1.413.126-2.828.225-4.262.225a37.434,37.434,0,0,1-4.261-.225,4.077,4.077,0,0,1-2.609-1.269,3.253,3.253,0,0,1-.777-2.141,3.027,3.027,0,0,1,.657-2.078,3.411,3.411,0,0,0,.957-2.564v-.387a5.038,5.038,0,0,1,1.154-3.464A6.379,6.379,0,0,1,688.6,1158h.08a6.352,6.352,0,0,1,2.726.621,6.474,6.474,0,0,1,2.193,1.736,5.005,5.005,0,0,1,1.08,3.374Zm-8.667,10.139c0-.45.416-.666.8-.756a24.58,24.58,0,0,1,3.64,0c.385.09.8.3.8.756a1.375,1.375,0,0,1-.193.594,1.357,1.357,0,0,1-.436.459,3.247,3.247,0,0,1-1.541.657,3.06,3.06,0,0,1-.907,0,3.242,3.242,0,0,1-1.542-.657,1.441,1.441,0,0,1-.438-.459,1.373,1.373,0,0,1-.192-.6Z" transform="translate(-680.989 -1158)" fill-rule="evenodd" />
                                        </svg>

                                        Reminders & Notifications</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>



                                {
                                    hasAnyPermissionForModules(['adminuser', 'role']) && (
                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <path id="Path_3920" data-name="Path 3920" d="M1357.83,1168.368a1.312,1.312,0,0,0-.8.279,1.289,1.289,0,0,0-.459.7h4.851a1.289,1.289,0,0,0-.459-.7,1.313,1.313,0,0,0-.8-.279Zm4.959.981h1.782a3.457,3.457,0,0,1,3.429,3.474s-.054.81-.072,1.935a.254.254,0,0,1-.036.135.286.286,0,0,1-.081.1c-.432.324-.828.585-.864.6a13.877,13.877,0,0,1-5.085,2.061.279.279,0,0,1-.171-.027.285.285,0,0,1-.126-.126,3.016,3.016,0,0,0-2.574-1.485,3.046,3.046,0,0,0-2.583,1.485.265.265,0,0,1-.126.117.288.288,0,0,1-.171.027,14,14,0,0,1-5.058-2.043l-.864-.6a.433.433,0,0,1-.09-.09.317.317,0,0,1-.027-.126c-.027-.468-.072-1.962-.072-1.962a3.457,3.457,0,0,1,3.429-3.474h1.773a2.684,2.684,0,0,1,2.628-2.349h2.331a2.684,2.684,0,0,1,2.628,2.349Zm4.905,7.38-.036.018a15.3,15.3,0,0,1-6.3,2.376.686.686,0,0,1-.459-.108.711.711,0,0,1-.279-.387,1.632,1.632,0,0,0-1.6-1.242h-.036a1.632,1.632,0,0,0-1.6,1.242.71.71,0,0,1-.279.387.686.686,0,0,1-.459.108,15.3,15.3,0,0,1-6.3-2.376.145.145,0,0,0-.081-.027.149.149,0,0,0-.09.009.243.243,0,0,0-.063.072.269.269,0,0,0-.018.09l.063,4.644a3.41,3.41,0,0,0,.99,2.439,3.6,3.6,0,0,0,1.116.756,3.649,3.649,0,0,0,1.314.27h10.845a3.649,3.649,0,0,0,1.314-.27,3.6,3.6,0,0,0,1.116-.756,3.41,3.41,0,0,0,.99-2.439l.072-4.644a.269.269,0,0,0-.018-.09.243.243,0,0,0-.063-.072.166.166,0,0,0-.063-.018A.168.168,0,0,0,1367.694,1176.729Zm-8.019,3.825a.679.679,0,0,1-1.359,0v-1.179a.674.674,0,0,1,.2-.477.664.664,0,0,1,.963,0,.674.674,0,0,1,.2.477Z" transform="translate(-1350 -1167)" fill-rule="evenodd" />
                                                </svg>
                                                User & Roles</Accordion.Header>
                                            <Accordion.Body className="p-2">
                                                <ul>

                                                    <li
                                                        className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'Users' ? 'active-set' : ''}`}
                                                        onClick={() => handleSubComponentSelect('UsersComponent', 'Users')}
                                                    >
                                                        Users
                                                    </li>


                                                    <li
                                                        className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'Roles' ? 'active-set' : ''}`}
                                                        onClick={() => handleSubComponentSelect('RolesComponent', 'Roles')}
                                                    >
                                                        Roles
                                                    </li>


                                                    {/* <li
                                                        className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'Permissions' ? 'active-set' : ''}`}
                                                        onClick={() => handleSubComponentSelect('PermissionsComponent', 'Permissions')}
                                                    >
                                                        Permissions
                                                    </li> */}

                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )
                                }

                                {hasAnyPermissionForModules(['form', 'custom_filed']) && (
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.93" height="18" viewBox="0 0 17.93 18">
                                                <g id="Customization" transform="translate(-1)">
                                                    <path id="Path_3897" data-name="Path 3897" d="M18.506,7.041l-1.312-.262a7.5,7.5,0,0,0-.571-1.38l.742-1.113A.527.527,0,0,0,17.3,3.62L15.31,1.631a.527.527,0,0,0-.665-.066l-1.113.742a7.507,7.507,0,0,0-1.38-.571L11.888.424A.527.527,0,0,0,11.371,0H8.559a.527.527,0,0,0-.517.424L7.779,1.736a7.51,7.51,0,0,0-1.38.571L5.286,1.565a.527.527,0,0,0-.665.066L2.631,3.62a.527.527,0,0,0-.066.665L3.308,5.4a7.507,7.507,0,0,0-.571,1.38l-1.312.262A.527.527,0,0,0,1,7.559v2.812a.527.527,0,0,0,.424.517l1.312.262a7.505,7.505,0,0,0,.571,1.38l-.742,1.113a.527.527,0,0,0,.066.665L4.62,16.3a.527.527,0,0,0,.665.066l1.091-.727v-3.31a4.922,4.922,0,1,1,7.177,0v3.31l1.091.727a.527.527,0,0,0,.665-.066L17.3,14.31a.527.527,0,0,0,.066-.665l-.742-1.113a7.508,7.508,0,0,0,.571-1.38l1.312-.262a.527.527,0,0,0,.424-.517V7.559A.527.527,0,0,0,18.506,7.041Z" />
                                                    <path id="Path_3898" data-name="Path 3898" d="M166.01,171.2a.433.433,0,0,0-.646.313l-.322,2.225h-1.406l-.321-2.225a.433.433,0,0,0-.646-.313,3.335,3.335,0,0,0,.209,5.889l.056.027V182.6a.527.527,0,0,0,.527.527h1.758a.527.527,0,0,0,.527-.527v-5.481l.056-.027a3.318,3.318,0,0,0,1.878-3A3.351,3.351,0,0,0,166.01,171.2Z" transform="translate(-154.375 -165.123)" />
                                                </g>
                                            </svg>

                                            Customization</Accordion.Header>
                                        <Accordion.Body className='p-2'>
                                            <ul>
                                                <li
                                                    className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'CustomForms' ? 'active-set' : ''}`}
                                                    onClick={() => handleSubComponentSelect('CustomFormsComponent', 'CustomForms')}
                                                >
                                                    Custom Forms
                                                </li>
                                                <li
                                                    className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'CustomFields' ? 'active-set' : ''}`}
                                                    onClick={() => handleSubComponentSelect('CustomFieldsComponent', 'CustomFields')}
                                                >
                                                    Custom Fields
                                                </li>
                                                <li
                                                    className={`fs-16 font-weight-bold my-2 cursor-pointer ${activeItem === 'TagServices' ? 'active-set' : ''}`}
                                                    onClick={() => handleSubComponentSelect('CurrenciesComponent', 'TagServices')}
                                                >
                                                    Tag Service
                                                </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                                }


                                <Accordion.Item eventKey="8">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.02" height="18" viewBox="0 0 11.02 18">
                                            <g id="_x34_0_x2C__Check_x2C__device_x2C__mobile_x2C__multimedia_x2C__phone" transform="translate(-24.475 -11)">
                                                <g id="Group_2005" data-name="Group 2005" transform="translate(24.475 11)">
                                                    <path id="Path_3904" data-name="Path 3904" d="M115.3,11h-7.572A1.726,1.726,0,0,0,106,12.724V27.276A1.726,1.726,0,0,0,107.724,29H115.3a1.726,1.726,0,0,0,1.724-1.724V12.724A1.726,1.726,0,0,0,115.3,11Zm-8.561,2.939h9.551V26.061h-9.551Z" transform="translate(-106 -11)" />
                                                    <path id="Path_3905" data-name="Path 3905" d="M154.082,202.247a1.1,1.1,0,0,0-1.881-.779l-.292.292a3.675,3.675,0,1,0,1.277,1.84l.573-.573A1.1,1.1,0,0,0,154.082,202.247Zm-1.469,2.427a2.94,2.94,0,1,1-1.229-2.389l-1.261,1.261-.52-.52a1.1,1.1,0,1,0-1.559,1.559l1.818,1.818a.367.367,0,0,0,.519,0l2.193-2.193a2.963,2.963,0,0,1,.037.464Z" transform="translate(-144.531 -194.02)" />
                                                    <path id="Path_3906" data-name="Path 3906" d="M146.367,111.735h2.939a.367.367,0,1,0,0-.735h-2.939a.367.367,0,0,0,0,.735Z" transform="translate(-144.531 -107.327)" />
                                                    <path id="Path_3907" data-name="Path 3907" d="M146.367,151.735h2.939a.367.367,0,1,0,0-.735h-2.939a.367.367,0,0,0,0,.735Z" transform="translate(-144.531 -145.857)" />
                                                </g>
                                            </g>
                                        </svg>


                                        Online Payments</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <path id="Integrations" d="M10,15a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1Zm11-4a2,2,0,0,0-2-2H16.816A2.964,2.964,0,0,1,17,10v4a3,3,0,0,1-3,3H10a2.966,2.966,0,0,1-1-.184V19a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2ZM5,3A2,2,0,0,0,3,5v8a2,2,0,0,0,2,2H7.184A2.966,2.966,0,0,1,7,14V10a3,3,0,0,1,3-3h4a2.963,2.963,0,0,1,1,.184V5a2,2,0,0,0-2-2Z" transform="translate(-3 -3)" />
                                        </svg>

                                        Integrations</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                    <Accordion.Header className='rounded-0 fs-16 fw-bold font-black'>
                                        <svg id="Developer_Space" data-name="Developer Space" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <path id="Path_3899" data-name="Path 3899" d="M7.75,0A3.75,3.75,0,1,0,11.5,3.75,3.754,3.754,0,0,0,7.75,0Z" transform="translate(-1)" />
                                            <path id="Path_3900" data-name="Path 3900" d="M16.436,21.985a.563.563,0,0,1-.422-.674l1.125-4.875a.563.563,0,0,1,1.1.253l-1.125,4.875A.561.561,0,0,1,16.436,21.985Z" transform="translate(-4 -4)" />
                                            <path id="Path_3901" data-name="Path 3901" d="M20.562,21.5a.563.563,0,0,1-.376-.981L21.6,19.25l-1.411-1.269a.563.563,0,0,1,.753-.837l1.875,1.687a.564.564,0,0,1,0,.837l-1.875,1.687a.562.562,0,0,1-.376.144Z" transform="translate(-5 -4.25)" />
                                            <path id="Path_3902" data-name="Path 3902" d="M13.437,21.5a.562.562,0,0,1-.376-.144l-1.875-1.687a.564.564,0,0,1,0-.837l1.875-1.687a.563.563,0,1,1,.753.837L12.4,19.25l1.411,1.269a.563.563,0,0,1-.376.981Z" transform="translate(-2.75 -4.25)" />
                                            <path id="Path_3903" data-name="Path 3903" d="M6.937,18a2.067,2.067,0,0,1,.68-1.531l1.877-1.69a2.055,2.055,0,0,1,2.552-.159,2.049,2.049,0,0,1,1.229-1.029A3.56,3.56,0,0,0,10.312,12H3.562A3.567,3.567,0,0,0,0,15.562v3a.563.563,0,0,0,.562.562H7.281A2.06,2.06,0,0,1,6.937,18Z" transform="translate(0 -3)" />
                                        </svg>
                                        Developer Space</Accordion.Header>
                                    <Accordion.Body className='p-2'>
                                        <ul>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Profile</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Branding</li>
                                            <li className='fs-16 font-weight-bold my-2 cursor-pointer'>Currencies</li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className="setting_right mt-3">
                    {renderSelectedSubComponent()}
                </div>

            </div>
        </div>
    )
}
export default Setting;