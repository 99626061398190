import React, { useState, useEffect, useContext } from "react";
import { http } from "../../../http/http";
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import CreatorComponent from "../../../servay/CreatorComponent";
import { toast } from "react-toastify";
import { GlobalContext } from "../../../../App";
import { Link, useParams, useNavigate } from "react-router-dom";
import { handlePermissionData, handlePermissionIdsData } from '../../../http/help';

const CustomForms = () => {
    const navigate = useNavigate();
    const [forms, setForms] = useState([]);
    const [currentForm, setCurrentForm] = useState(null);
    const [currentFormIndex, setCurrentFormIndex] = useState(null);
    const [showSurvey, setShowSurvey] = useState(false);
    const [showCreator, setShowCreator] = useState(false);
    const [surveys, setSurvey] = useState(null);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [customList, setCustomList] = useState([]);
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));

    // Function to check if user has 'task' related permissions or is allowed full access
    const hasCustomFormViewPermission = () => {
        // const taskPermissions = ["task_view", "task_create", "task_update", "task_delete"];
        const hasTaskPermission = permission?.permissions?.includes("form_view");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };
    // Function to check if user has 'form' related permissions or is allowed full access
    const hasCustomFormPermission = () => {
        const hasTaskPermission = permission?.permissions?.includes("form_create");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    // Function to check if user has 'form_delete' permission or is allowed full access
    const hasCustomFormDeletePermission = () => {
        const hasTaskPermission = permission?.permissions?.includes("form_delete");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    const hasCustomFormEditPermission = () => {
        const hasTaskPermission = permission?.permissions?.includes("form_update");
        const hasTaskFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);
        return hasTaskPermission || hasTaskFullAccess;
    };

    const saveFormToAPI = async (formJson) => {
        const requestData = {
            // tag_id: 1, // Set your tag_id as needed
            title: "form1",
            data: [formJson], // Wrap the formJson in an array
        };

        try {
            // First API call
            const firstApiResponse = await new Promise((resolve, reject) => {
                http(
                    {
                        method: 'POST',
                        url: `form/add`,
                        isSecure: true,
                        body: requestData,
                    },
                    (res) => {
                        if (res) {
                            resolve(res);
                        } else {
                            reject('Error in first API call');
                        }
                    }
                );
            });



            // Assuming firstApiResponse has a property named 'formId'
            const formIdFromFirstApiResponse = firstApiResponse.data.id;



            // Determine the name for the second API call
            const formName = formJson.title ? formJson.title : `Form_${formIdFromFirstApiResponse}`;





        } catch (error) {
            console.error('Error in API calls:', error);
        }
    };

    const handleSaveForm = async (formJson) => {
        await saveFormToAPI(formJson);
        await fetchFormsFromAPI(); // Ensure the forms are fetched after saving
        setShowCreator(false);
        setShowSurvey(false);
    };

    const fetchFormsFromAPI = async () => {
        try {
            const response = await new Promise((resolve, reject) => {
                http(
                    {
                        method: 'GET',
                        url: `form/listing`,
                        isSecure: true
                    },
                    (res) => {
                        if (res) {
                            resolve(res?.data?.data || []);
                        } else {
                            reject('Error in fetching forms');
                        }
                    }
                );
            });
            setForms(response);
        } catch (error) {
            console.error('Error fetching forms:', error);
        }
    };

    useEffect(() => {
        fetchFormsFromAPI();
    }, []);

    const handleShowSurvey = (index) => {
        const selectedForm = forms[index];

        http(
            {
                method: 'GET',
                url: `form/${selectedForm.id}/view`,
                isSecure: true
            },
            (res) => {

                const formData = res.data?.data ? res.data.data : null;
                if (formData && Array.isArray(formData) && formData.length > 0) {
                    setCurrentForm(formData[0]);
                    setShowSurvey(true);
                    setShowCreator(false);
                    setCurrentFormIndex(index);
                    setCustomList(formData[0])
                } else {
                    console.error('No form data found for form', selectedForm.id);
                }
            }
        );
    };
    const handleCreateForm = () => {
        if (!hasCustomFormPermission()) {  // Call the function here
            // Show an error message if the user lacks permission
            toast.error("You don’t have permission to create a form.");
            return;
        }
        setShowCreator(true);
        setShowSurvey(false);
        navigate(`/admin/setting/add_custom_form`, { state: { showCreator: true } });
    };


    const handleCompleteSurvey = (sender) => {
        const results = JSON.stringify(sender.data);
        localStorage.setItem(`surveyResults_${currentForm.title}`, results);
        alert('Survey results saved!');
    };

    const deleteServiceForm = async (formId) => {

        if (!hasCustomFormDeletePermission()) {  // Call the function here
            // Show a notification if the user lacks delete permission
            toast.error("You don’t have permission to delete this form.");
            return; // Exit the function
        }
        setShowLoader(true);
        try {
            await new Promise((resolve, reject) => {
                http(
                    {
                        method: "DELETE",
                        url: `form/${formId}/delete`,
                        isSecure: true,
                    },
                    (res) => {
                        if (res) {
                            resolve(res);
                        } else {
                            reject('Error deleting form');
                        }
                    }
                );
            });
            toast.success('Form deleted successfully');
            await fetchFormsFromAPI(); // Ensure the forms are fetched after deletion
            setShowLoader(false);
        } catch (error) {
            console.error('Error fetching Comment data:', error);
            setShowLoader(false);
        }
    }



    let survey;
    if (currentForm) {
        survey = new Model(currentForm);
        survey.onComplete.add(handleCompleteSurvey);
    }

    const handleEditForm = (index) => {
        if (!hasCustomFormEditPermission()) {  // Call the function here
            // Show a toast notification or message indicating no permission
            toast.error("You don’t have permission to edit this form.");
            return; // Prevent further execution
        }
        const selectedForm = forms[index];

        navigate(`/admin/setting/edit_servay_form/${selectedForm.id}`, { state: { form: selectedForm } });
    };
    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);


    return (
        <div className="bg-white shadow---main rounded-4 h_800">
            <div className="accord-head d-flex align-items-center justify-content-between px-4 pt-4">
                <span>
                    <h4 className="fs-20 fw-bold">Custom Forms</h4>
                    <p className='fs-16 t_blue'>Customization</p>
                </span>
                <button className="add-new"
                    onClick={handleCreateForm}
                // onClick={() => navigate(`/admin/setting/add_custom_form`)}


                ><i className="fa fa-plus me-2"></i>Add New</button>
            </div>
            {/* <div className="px-4">
                {showCreator && <CreatorComponent onSave={handleSaveForm} />}
            </div> */}
            <div className="accord-table px-4">
                {hasCustomFormViewPermission ? (
                    <div className="table-responsive">
                        <table className="w-100">
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Form Title</th>
                                    <th>No. of Fields</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(forms) && forms.map((form, index) => (
                                    <tr key={form.id}>
                                        <td onClick={() => handleShowSurvey(index)}>{index + 1}.</td>
                                        <td onClick={() => handleShowSurvey(index)}>{form.data && form.data[0]?.title || "No Title"}</td>
                                        <td onClick={() => handleShowSurvey(index)}>
                                            {form.data && form.data[0]?.pages ? form.data[0].pages.reduce((count, page) => count + page.elements.length, 0) : 0}
                                        </td>
                                        <td>--</td>
                                        <td className="d-flex align-items-center gap-2">
                                            <div className="editicon" onClick={() => handleEditForm(index)} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                    <g id="write" transform="translate(-7.998 -8)">
                                                        <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                                        <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                                        <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="deticon" onClick={() => deleteServiceForm(form.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                                    <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                                </svg>
                                            </div>
                                            {/* <button onClick={() => handleShowSurvey(index)}>Show Form {index + 1}</button> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showSurvey && survey && <Survey model={survey} />}
                    </div>
                ) : (
                    <div className="no-permission-message">
                        <p className="text-muted fs-16">You don’t have permission to view this information.</p>
                    </div>
                )}
            </div>

        </div>
    );
}
export default CustomForms;