import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { http } from "../../http/http";
const Payment = () => {
    const [paymentAmount, setPaymetAmount] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const { price, id } = location.state || {};

    const handleTitleChange = (e) => {
        const { value, name } = e.target;
        setPaymetAmount(value);
    };


    const inviocePreviewHandle = (event) => {
        event.preventDefault()
        http(
            {
                method: "POST",
                url: `payment/${id}/add`,
                isSecure: true,
                body: {
                    price: paymentAmount
                }
            },
            (res) => {
                toast.success(res?.message);
                navigate("/admin/invoice/invoice_detail");
            },
            (err) => { },
        );
    }
    return (
        <div className="right_section font-poppins">

            <section class="payment font-poppins">
                <div class="left-payment">
                    <div class="payment-text">
                        <h4 class="fs-20 fw-bold">Make a Payment</h4>
                        <h6 class="fs-14 text-secondary">Please make your invoice payment below.</h6>
                        <h2 class="fs-20 fs-20 mt-3 lh-base">Total Payable:</h2>
                        <h1 class="fs-30 fw-bold text-blue lh-0">${price}</h1>
                    </div>
                    <hr />
                    <form onSubmit={inviocePreviewHandle} class="mt-4">
                        <div class="input-area">
                            <label for="" class="fs-18 text-secondary">Amount</label>
                            <div class="input-box mt-3 position-relative">
                                <input
                                    type="text"
                                    class="form-input"
                                    placeholder="Enter Amount"
                                    onChange={handleTitleChange}
                                />
                                <p class="text-black position-absolute dollar-text">$</p>
                            </div>
                        </div>
                        <hr class="mt-4" />
                        {/* <div class="form-ttl mt-3">
                            <h2 class="fs-20 fw-bold lh-base">Add Your Card Details</h2>
                            <h6 class="fs-14 text-secondary mt-1 lh-base">Please make your invoice payment below.</h6>
                        </div> */}

                        {/* <div class="input-area mt-4">
                            <label for="" class="fs-18 text-secondary">Card Holder's Name</label>
                            <input type="text" class="form-input mt-3" placeholder="Enter Name" />
                        </div> */}

                        {/* <div class="row">
                            <div class="col-md-8">
                                <div class="input-area mt-4">
                                    <label for="" class="fs-18 text-secondary">Card Number</label>
                                    <input type="number" class="form-input mt-3" placeholder="Enter Name" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input-area mt-4">
                                    <label for="" class="fs-18 text-secondary">Expire On</label>
                                    <input type="date" class="form-input mt-3" placeholder="Enter Name" />
                                </div>
                            </div>
                        </div> */}

                        <div class="payment-btn mt-5">
                            <button type="submit">Make Payment</button>
                        </div>
                    </form>
                </div>
                <div class="right-payment d-flex align-items-center justify-content-center">
                    <div class="right-area-pay p-5">
                        <img src={require("../../../assets/images/atm.png")} alt="" />
                    </div>
                </div>
            </section>

        </div>
    )
}
export default Payment;