import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Marital_Status, Gender, Residency_Status, Client_Category, Occupation_Category, Refer_by_Client } from "../../../helpers/staticList";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Line } from 'rc-progress';

const AddIndividualClient = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const [picture, setPicture] = useState(null);

    const [inviteUserList, setInviteUserList] = useState([]);
    console.log(inviteUserList, "inviteUserList")
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customFormList, setCustomFormList] = useState([]);
    const [address, setAddress] = useState('');
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

    const fileInputRef = useRef(null);

    const handleChange = (value) => {
        setAddress(value);
    };

    const handleSelect = async (value) => {
        setAddress(value);
        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const formattedAddress = results[0].formatted_address;

                // Set the selected address in the form
                setValue('office_location', formattedAddress);

            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };






    const onChangePicture = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Validate file type
            if (!file.type.startsWith("image/")) {
                alert("Only image files (JPEG, PNG) are allowed.");
                handleRemovePicture(); // Clear invalid input
                return;
            }

            // Validate file size
            if (file.size > MAX_FILE_SIZE) {
                alert("File size exceeds 10 MB limit.");
                handleRemovePicture(); // Clear large file input
                return;
            }

            setPicture(file); // Set the valid file to state
        }
    };

    // Handle removing the picture
    const handleRemovePicture = () => {
        setPicture(null); // Clear the picture state
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input value to an empty string
        }
    };




    // const onChangeIdProof = (e) => {
    //     const files = Array.from(e.target.files); // Support multiple files

    //     files.forEach((file) => {
    //         // Restrict image files
    //         if (file.type.startsWith("image/")) {
    //             alert(`${file.name} is an image file and is not allowed.`);
    //             return;
    //         }

    //         // Validate file size
    //         if (file.size > MAX_FILE_SIZE) {
    //             alert(`${file.name} exceeds the 10 MB size limit.`);
    //             return;
    //         }

    //         // Add the file to the uploaded files state
    //         setUploadedFiles((prev) => [...prev, { file, size: (file.size / 1024).toFixed(1) + " KB" }]);

    //         // Simulate upload progress
    //         simulateUploadProgress(file);
    //     });
    // };

    // const simulateUploadProgress = (file) => {
    //     let progress = 0;

    //     const interval = setInterval(() => {
    //         progress += 10; // Increment progress by 10%
    //         setUploadProgress((prev) => ({
    //             ...prev,
    //             [file.name]: progress,
    //         }));

    //         if (progress >= 100) {
    //             clearInterval(interval); // Stop progress simulation at 100%
    //         }
    //     }, 200); // Simulate progress every 200ms
    // };

    // const handleRemoveFile = (fileName) => {
    //     // Remove the file from the uploaded files state
    //     setUploadedFiles((prev) => prev.filter((file) => file.file.name !== fileName));
    //     setUploadProgress((prev) => {
    //         const updatedProgress = { ...prev };
    //         delete updatedProgress[fileName];
    //         return updatedProgress;
    //     });
    // };

    const onChangeIdProof = (e) => {
        const file = e.target.files[0]; // Get the first file (single file upload)

        if (!file) return; // No file selected

        // Restrict image files
        if (file.type.startsWith("image/")) {
            alert(`${file.name} is an image file and is not allowed.`);
            return;
        }

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
            alert(`${file.name} exceeds the 10 MB size limit.`);
            return;
        }

        // Clear previously uploaded files and set the new file
        setUploadedFiles([{ file, size: (file.size / 1024).toFixed(1) + " KB" }]);

        // Simulate upload progress
        simulateUploadProgress(file);
    };

    const simulateUploadProgress = (file) => {
        let progress = 0;

        const interval = setInterval(() => {
            progress += 10; // Increment progress by 10%
            setUploadProgress((prev) => ({
                ...prev,
                [file.name]: progress,
            }));

            if (progress >= 100) {
                clearInterval(interval); // Stop progress simulation at 100%
            }
        }, 200); // Simulate progress every 200ms
    };

    const handleRemoveFile = () => {
        // Clear the uploaded file
        setUploadedFiles([]);
        setUploadProgress({});
    };

    const handleCheckboxCustomForm = (event) => {
        const value = parseInt(event.target.value, 10); // Parse the value as an integer

        setSelectedOptions((prevSelectedOptions) => {
            if (prevSelectedOptions.includes(value)) {
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                return [...prevSelectedOptions, value];
            }
        });
    };

    const handleCustomFormList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `form/listing`,
                    isSecure: true,
                },
                (res) => {
                    setCustomFormList(res?.data?.data);
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleCustomFormList();
    }, []);



    const handleIndividualSubmit = async () => {
        const formData = new FormData();

        if (picture) {
            formData.append("profile_picture", picture);
        }
        if (uploadedFiles.length > 0) {
            // Append each file in uploadedFiles individually
            uploadedFiles.forEach(({ file }) => {
                formData.append("id_proof", file);
            });
        }

        // Append form IDs from selectedOptions state
        selectedOptions.forEach(id => {
            formData.append('form_id[]', id);
        });


        // Append other form data fields
        Object.entries(formValues).forEach(([key, value]) => {
            formData.append(key, value);
        });

        http(
            {
                method: "POST",
                url: "add-individual",
                isSecure: true,
                contentType: "multipart/form-data",
                body: formData
            },
            (res) => {
                toast.success("Individual Client Add Successfully")
                reset();
                navigate(`/admin/client/individual_profile/${res?.data[0]?.id}`);
                window.location.reload();
            },
            (err) => {

            }
        );

    };


    const handleInviteUserList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `lists/adminusers`,
                    isSecure: true,
                },
                (res) => {
                    setInviteUserList(res?.data?.data)
                },
            )
        } catch (error) {

        }
    };
    useEffect(() => {
        handleInviteUserList();
    }, []);

    return (
        <div className="right_section font-poppins">
            <div className="individual-form-area py-3">
                <div className="row individual-head align-items-center">
                    <div className="col-md-12 my-2">
                        <h4 className="fs-20 fw-semibold">Add Individual Client</h4>
                    </div>
                    <div className="col-md-12">
                        <div className="main-form-area p-4 mt-3 bg-white shadow-lg">
                            <form
                                onSubmit={handleSubmit(handleIndividualSubmit)}
                            >
                                <div className="row form-group">
                                    <div className="col-md-12">
                                        <h6 className="fs-20 fw-semibold">Basic Details</h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            First Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("first_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.first_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.first_name &&
                                            errors.first_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Middle Name
                                        </label>
                                        <input
                                            type="text"
                                            name="middle_name"
                                            id="middle_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("middle_name", {
                                            })}
                                        />
                                        {errors.middle_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="middlename">
                                            Last Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("last_name", {
                                                required: true,
                                            })}
                                        />
                                        {errors.last_name?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.last_name &&
                                            errors.last_name.type === "pattern" && (
                                                <p
                                                    role="alert"
                                                    className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                                >
                                                    At least add one alphabetic
                                                </p>
                                            )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="middlename">SIN </label>
                                        <input
                                            type="password"
                                            name="sin"
                                            id="sin"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            maxLength={9} // Limit to 9 digits
                                            inputMode="numeric" // Display numeric keyboard on mobile devices
                                            {...register("sin", {
                                                pattern: {
                                                    value: /^\d+$/, // Allow only numbers

                                                },
                                            })}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                                            }}
                                        />

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="email">Email Address <span className="text-danger">*</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("email", {
                                                required: true,
                                                pattern: /\S+@\S+\.\S+/,
                                            })}
                                        />
                                        {errors.email?.type === "required" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1-fs-7 ms-1"
                                            >
                                                Required
                                            </p>
                                        )}
                                        {errors.email?.type === "pattern" && (
                                            <p
                                                role="alert"
                                                className="text-danger mb-0 pt-1 -fs-7 ms-1"
                                            >
                                                Incorrect email format
                                            </p>
                                        )}
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Birth
                                        </label>
                                        <input
                                            type="date"
                                            data-date-format="DD-MM-YYYY" id="date_of_birth"
                                            name="date_of_birth"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("date_of_birth", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Date of Landing
                                        </label>
                                        <input
                                            type="date"
                                            id="date_of_landing"
                                            name="date_of_landing"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("date_of_landing", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Marital Status
                                        </label>
                                        <select
                                            name="marital_status"
                                            id="marital_status"
                                            className="form-input mt-2"
                                            {...register("marital_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select Marital Status</option>
                                            {Marital_Status && Marital_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Marital Status Change Date
                                        </label>
                                        <input
                                            type="date"
                                            id="marital_status_change_date"
                                            name="marital_status_change_date"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "marital_status_change_date", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Office Location
                                        </label>
                                        <PlacesAutocomplete
                                            value={address}
                                            onChange={handleChange}  // Handle input change here
                                            onSelect={handleSelect}  // Handle address selection here
                                            searchOptions={{
                                                componentRestrictions: { country: ['us', 'ca'] },
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        {...getInputProps({
                                                            placeholder: 'Type address here...',
                                                            className: 'form-input mt-2 google-address',
                                                        })}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map((suggestion) => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                    })}
                                                                    key={suggestion.placeId}
                                                                >
                                                                    <span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Primary Contact Person
                                        </label>
                                        <input
                                            type="number"
                                            id="primary_contact_person"
                                            name="primary_contact_person"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "primary_contact_person", {



                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label for="fristname">
                                            Family Head
                                        </label>
                                        <input
                                            type="text"
                                            id="family_head"
                                            name="family_head"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register("family_head", {

                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Residency Status
                                        </label>
                                        <select
                                            name="residency_status"
                                            id="residency_status"
                                            className="form-input mt-2"
                                            {...register("residency_status", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Residency_Status && Residency_Status.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Referred By Client/Other
                                        </label>
                                        <select
                                            name="refer_by_client"
                                            id="refer_by_client"
                                            className="form-input mt-2"
                                            {...register("refer_by_client", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Refer_by_Client && Refer_by_Client.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Personal Account Manager
                                        </label>
                                        <select
                                            name="account_manager"
                                            id="account_manager"
                                            className="form-input mt-2"
                                            {...register("account_manager", {

                                            })}
                                        >
                                            <option value="" selected disabled>Select an Account Manager</option>
                                            {Array.isArray(inviteUserList) && inviteUserList.length > 0 ? (
                                                inviteUserList.map((user) => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.email}
                                                    </option>
                                                ))
                                            ) : (
                                                <option value="" disabled>No Account Managers Available</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Client Category
                                        </label>
                                        <select
                                            name="client_category"
                                            id="client_category"
                                            className="form-input mt-2"
                                            {...register("client_category", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Client_Category && Client_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}

                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">
                                            Occupation Category
                                        </label>
                                        <select
                                            name="occupation_category"
                                            id="occupation_category"
                                            className="form-input mt-2"
                                            {...register(
                                                "occupation_category", {



                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Occupation_Category && Occupation_Category.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="fristname">
                                            Self-Employed HST #
                                        </label>
                                        <input
                                            type="text"
                                            id="self_employed_hst_no"
                                            name="self_employed_hst_no"
                                            placeholder="Type here..."
                                            className="form-input mt-2"
                                            {...register(
                                                "self_employed_hst_no", {



                                            })}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <label htmlFor="firstname">Gender</label>
                                        <select
                                            name="gender"
                                            id="gender"
                                            className="form-input mt-2"
                                            {...register("gender", {

                                            })}
                                        >
                                            <option value=""
                                                selected
                                                disabled="disabled">Select</option>
                                            {Gender && Gender.getList().map((data) => (
                                                <option key={data.key} value={data.key}>
                                                    {data.value}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4"></div>

                                    <div className="col-lg-6 mt-3 h-au">
                                        <label htmlFor="firstname">Upload Documents</label>
                                        <div className="upload-input position-relative mt-2">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper justify-content-center add-ind cursor-pointer">
                                                        <input
                                                            type="file"
                                                            name="id_proof"
                                                            // multiple // Enable multiple file uploads
                                                            accept=".pdf,.doc,.docx,.xls,.xlsx" // Explicitly allow non-image file types
                                                            onChange={onChangeIdProof}
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text cursor-pointer">Browse Files</span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="upload-description">
                                                        Supports: PDF, DOC, XLS | Max Size: 10 MB
                                                    </p>
                                                </div>

                                                {uploadedFiles.length > 0 && (
                                                    <div className="uploaded-files-list">
                                                        {uploadedFiles.map(({ file, size }) => (
                                                            <div key={file.name} className="file-upload-item">
                                                                <div className="d-flex align-items-center justify-content-start gap-3">
                                                                    <img src={file.type.includes("pdf") ? require("../../../assets/images/PDF_file_icon.png") : require("../../../assets/images/Google_Docs_Logo_512px.png")
                                                                    } alt="File Icon" className="file-icon" />

                                                                    <div className="file-info">
                                                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                                                            <div>
                                                                                <div className="file-name">Uploading: </div>
                                                                                <div className="file-size mt-1">{file.name}  |  Size: {size}</div>
                                                                            </div>
                                                                            <div className="text-end">
                                                                                <i className="fa fa-times fs-14 text-muted cursor-pointer" onClick={() => handleRemoveFile(file.name)} title="Remove File"></i>
                                                                                <div className="progress-percentage fs-12 text-black mt-1">{uploadProgress[file.name] || 60}%</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress-container">
                                                                            <Line
                                                                                percent={uploadProgress[file.name] || 0}
                                                                                strokeWidth="2"
                                                                                strokeColor="#007bff"
                                                                                trailWidth="2"
                                                                                trailColor="#d3d3d3"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-4 mt-3 h-au">
                                        <label htmlFor="firstname">Upload Profile Picture</label>
                                        <div className="upload-input position-relative mt-2 text-center">
                                            <div className="main-wrapper">
                                                <div className="upload-main-wrapper mx-auto text-center">
                                                    <div className="upload-wrapper text-center add-ind cursor-pointer">
                                                        <input
                                                            type="file"
                                                            name="profile_picture"
                                                            onChange={onChangePicture}
                                                            ref={fileInputRef}
                                                        />
                                                        <i className="fa fa-upload me-2"></i>
                                                        <span className="file-upload-text cursor-pointer">Browse Files</span>
                                                    </div>
                                                    <h4 className="fs-14 mt-2">Drag & drop here</h4>
                                                    <p className="fs-14 text-muted">
                                                        Supports: JPEG, JPG, PNG
                                                        <br /> Max Size: 10 Mb
                                                    </p>
                                                </div>
                                                {picture && (
                                                    <div className="upload-thumb text-center mt-4 d-flex align-items-center justify-content-center">
                                                        <div className="uploaded-img picture rounded-4 d-flex align-items-center position-relative">
                                                            {picture.type.startsWith("image/") && (
                                                                <>
                                                                    <img
                                                                        src={URL.createObjectURL(picture)}
                                                                        alt="Uploaded"
                                                                        className="rounded-4"
                                                                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                                    />
                                                                </>
                                                            )}
                                                            <div
                                                                className="cancelimg"
                                                                onClick={handleRemovePicture}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <i className="fa fa-times text-white"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <label htmlFor="Required Forms" className="inv-title text-black mb-2">Required Forms</label>
                                        {customFormList && customFormList.map((option) => (
                                            <div key={option.id} className="d-flex align-items-center gap-2 mt-2">
                                                <input
                                                    type="checkbox"
                                                    value={option.id}
                                                    checked={selectedOptions.includes(option.id)}
                                                    onChange={handleCheckboxCustomForm}
                                                />
                                                <label className="text-black">{option.data[0]?.title}</label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-12 individual-footer">
                                        <div className="btn-groups mt-4">
                                            {/* <button
                                                        type="button"
                                                        className="btn cancel me-2"
                                                    >
                                                        Reset
                                                    </button> */}
                                            <button
                                                type="submit"
                                                className="add-new ms-2 px-4"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddIndividualClient;
