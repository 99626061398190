import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clientContatDetail } from "../../../reduxTool/features/userSlice";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";

const Add_Contact = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const { fetchConData, showcCntactModal, setShowContactModal } = props;
    const [usersContact, setUsersContact] = useState([]);
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');

    const handleNumericInput = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);

        // Allow only numeric characters (0-9) and certain control keys
        if (!/^[0-9]+$/.test(keyValue) && !["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight", "Home", "End"].includes(event.key)) {
            event.preventDefault();
        }
    };

    const onSubmit = () => {
        setShowLoader(true);
        const newUser = {
            ...formValues,
        };
        setUsersContact([...usersContact, newUser]);
        http(
            {
                method: "POST",
                url: `client/contact/${id}/add`,
                body: {
                    phone_number: formValues.phone_number,
                    unit_no: formValues.unit_no,
                    address: formValues.address,
                    city: formValues.city,
                    province: formValues.province,
                    postal_code: formValues.postal_code,
                    whatsapp_contact: formValues.whatsapp_contact,
                    client_since: formValues.client_since,
                    client_type: formValues.client_type

                },
                isSecure: true,
            },
            (res) => {
                dispatch(clientContatDetail(res));
                setShowContactModal(false);
                toast.success("Individual client contact successfully added");
                localStorage.setItem("userContacts", JSON.stringify(res?.data));
                reset();
                fetchConData();
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
            },
            (err) => {
                console.error("Error Add Contact:", err);
            }
        );
    }
    const onHideHandler = () => {
        setShowContactModal(false);

    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);
            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';
                let selectedCity = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City name
                    }
                });
                // Set the selected address, province, and postal code in the form
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setValue('city', selectedCity);
                setCity(selectedCity);
                setProvince(selectedProvince); // Update the province state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            // Improved error logging
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };

    const handleChange = (value) => {
        setAddress(value);
    };

    return (
        <Modal
            show={showcCntactModal}
            onHide={onHideHandler}
            centered
            size="xl"
            contentClassName="mx-auto"
            className="modal operating-modal font-poppins"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0 justify-content-between">
                <h4 className="fs-20 fw-semibold">Add Contact</h4>
                <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="advance-form row">
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Phone No. <span className="text-danger">*</span></label>
                            <input
                                type="tel"
                                name="phone_number"
                                id="phone_number"
                                maxLength={15}
                                className="form-input mt-2"
                                placeholder="Phone No."
                                {...register("phone_number", {
                                    required: "Required",
                                    pattern: {
                                        value: /^[0-9]*$/, // Regex for only numeric characters
                                        message: "Please enter only numeric characters"
                                    },
                                    minLength: {
                                        value: 7,
                                        message: "Phone number must be at least 7 digits long"
                                    },

                                })}
                                onKeyDown={handleNumericInput} // Attach event listener for numeric input
                            />
                            {errors.phone_number && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.phone_number.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Unit <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                name="unit_no"
                                id="unit_no"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("unit_no", {
                                    required: "Required",
                                })}
                            />
                            {errors.unit_no && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.unit_no.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Client Since <span className="text-danger">*</span></label>
                            <input
                                type="date"
                                name="client_since"
                                id="client_since"
                                className="form-input mt-2"
                                {...register("client_since", {
                                    required: "Required",
                                })}
                            />
                            {errors.client_since && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.client_since.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-12 mt-3">
                            <label className="fs-18">Client Type <span className="text-danger">*</span></label>
                            <select
                                name="client_type"
                                id="client_type"
                                className="form-input mt-2"
                                {...register("client_type", {
                                    required: "Required",
                                })}

                            >
                                <option value="" disabled selected>Select</option>
                                <option value="Client" selected="">Client</option>
                                <option value="Director Only">Director Only</option>
                            </select>
                            {errors.client_type && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.client_type.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Whatsapp Contact No. <span className="text-danger">*</span></label>
                            <input
                                type="tel"
                                name="whatsapp_contact"
                                id="whatsapp_contact"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("whatsapp_contact", {
                                    required: "Required",
                                    pattern: {
                                        value: /^[0-9]*$/, // Regex for only numeric characters
                                        message: "Please enter only numeric characters"
                                    },
                                    minLength: {
                                        value: 7,
                                        message: "Phone number must be at least 7 digits long"
                                    },

                                })}
                                onKeyDown={handleNumericInput}
                            />
                            {errors.whatsapp_contact && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.whatsapp_contact.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">City</label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                value={city} // Bind the province state to this input field
                                readOnly
                                className="form-input mt-2"
                                placeholder="Type here..."
                                {...register("city", {

                                })}
                            />

                        </div>
                        <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                            <label className="fs-18">Street Address</label>

                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}  // Handle input change here
                                onSelect={handleSelect}  // Handle address selection here
                                searchOptions={{
                                    componentRestrictions: { country: ['us', 'ca'] },
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Type address here...',
                                                className: 'form-input mt-2 google-address',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                        })}
                                                        key={suggestion.placeId}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            {errors.address && (
                                <span className="text-danger mb-0 pt-1 -fs-7 ms-1">{errors.address.message}</span>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Province</label>
                            <input
                                className="form-input mt-2"
                                name="province"
                                id="province"
                                value={province} // Bind the province state to this input field
                                readOnly
                                {...register("province", {})}
                            />
                            {/* {errors.province && (
                                <span className="error-message text-denger">{errors.province.message}</span>
                            )} */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                            <label className="fs-18">Postal Code</label>
                            <input
                                type="text"
                                name="postal_code"
                                id="postal_code"
                                className="form-input mt-2"
                                placeholder="Type here..."
                                readOnly
                                {...register("postal_code", {

                                })}
                            />
                            {/* {errors.postal_code && (
                                <span className="error-message text-denger">{errors.postal_code.message}</span>
                            )} */}
                        </div>
                        <div className="col-lg-12 mt-4 mb-2">
                            <button type="submit" className="btn-blue ms-auto">Add</button>
                        </div>
                    </div>
                </form>

            </div>
        </Modal>
    )
}
export default Add_Contact;