import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { GlobalContext } from "../../App";
import { http } from "../http/http";
import { toast } from "react-toastify";
import { handlePermissionData, handlePermissionIdsData } from '../http/help';

const ClientTabs = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [clientId, setClientId] = useState(id);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [permission, setPermission] = useState(null);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem('__assist_erp'));

    useEffect(() => {
        if (id) {
            localStorage.setItem('clientId', id);
            setClientId(id);
        } else {
            const storedId = localStorage.getItem('clientId');
            if (storedId) {
                setClientId(storedId);
            }
        }
    }, [id]);


    const canViewTab = (requiredPermissions) => {
        const hasAnyPermission = requiredPermissions.some(permissionName =>
            permission?.permissions?.includes(permissionName)
        );

        const hasFullAccess = permissionIds?.every(id => id.id !== userID?.user?.id);

        return hasAnyPermission || hasFullAccess;
    };

    const handleTabClickWithPermission = (requiredPermissions, navigateUrl) => {
        if (canViewTab(requiredPermissions)) {
            navigate(navigateUrl);  // Navigate to the tab
        } else {
            toast.error("You do not have access to this tab!");  // Show toast message
        }
    };


    // Update id in state and show loader when a tab is clicked
    const handleTabClick = (newId, path) => {
        setClientId(newId);
        localStorage.setItem('clientId', newId);
        setShowLoader(true); // Show loader

        navigate(path, {
            state: {
                from: location.pathname
            }
        });
        // Hide loader after navigation
        setTimeout(() => setShowLoader(false), 1000);
    };

    const getTabClassName = (path) => {
        return location.pathname === path ? 'nav-link active' : 'nav-link';
    };



    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds)
    }, []);
    return (
        <div className="row client-detail-head align-items-center">
            <div className="col-md-12 client-detail-tabs">
                <ul className="nav nav-tabs border-0">
                    <li className="nav-item me-2 rounded mt-2">
                        <button
                            className={getTabClassName(`/admin/client/individual_profile/${clientId}`)}
                            onClick={() => {
                                handleTabClick(clientId);
                                navigate(`/admin/client/individual_profile/${clientId}`);
                            }}
                        >
                            Home
                        </button>


                    </li>
                    <li className="nav-item me-2 mt-2">

                        <button
                            className={getTabClassName('/admin/communication')}
                        // onClick={() =>
                        //     handleTabClickWithPermission(
                        //         ["communication_view", "communication_add", "communication_delete", "communication_update"],
                        //         '/admin/communication'
                        //     )
                        // }
                        >
                            Communication
                        </button>

                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/note')}
                            // onClick={() => navigate('/admin/client/note')}
                            onClick={() =>
                                handleTabClickWithPermission(
                                    ["note_view", "note_add", "note_delete", "note_update"],
                                    '/admin/client/note'
                                )
                            }
                        >
                            Notes
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/files')}
                        // onClick={() => navigate('/admin/files')}
                        // onClick={() =>
                        //     handleTabClickWithPermission(
                        //         ["file_view", "file_add", "file_delete", "file_update"],
                        //         '/admin/files'
                        //     )
                        // }
                        >
                            Files
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/individual_task')}
                            // onClick={() => navigate('/admin/client/individual_task')}
                            onClick={() =>
                                handleTabClickWithPermission(
                                    ["task_view", "task_add", "task_delete", "task_update"],
                                    '/admin/client/individual_task'
                                )
                            }

                        >
                            Tasks
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/Transactions')}
                        // onClick={() => navigate('/admin/client/Transactions')}
                        // onClick={() =>
                        //     handleTabClickWithPermission(
                        //         ["transaction_view", "transaction_add", "transaction_delete", "transaction_update"],
                        //         '/admin/client/Transactions'
                        //     )
                        // }
                        >
                            Transactions
                        </button>
                    </li>
                </ul>
            </div>
        </div>

    );
};

export default ClientTabs;