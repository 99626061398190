import React, { useState, useEffect, useContext } from "react";
import { http } from "../../../http/http";
import { toast } from "react-toastify";
import axios from 'axios';
import Auth from "../../../../auth/Auth";
import { GlobalContext } from "../../../../App";
import ReactPaginate from "react-paginate";
const Roles = () => {
    const token = JSON.parse(localStorage.getItem("__assist_erp"));
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const [rolesList, setRolesList] = useState([]);
    const [permissionRoleList, setPermissionRoleList] = useState([]);
    const [roleName, setRoleName] = useState(''); // Role name state
    const [description, setDescription] = useState(''); // Description state
    const [showAddRole, setShowAddRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null); // Store selected role details
    const [view, setView] = useState('roleList');
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPermissionPage, setCurrentPermissionPage] = useState(1);
    const [pagePermissionCount, setPagePermissionCount] = useState(1);
    const PermissionLimit = 200;

    const handlePermissionPageClick = (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPermissionPage(selectedPage);
    };
    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value); // Update search term as the user types
    };
    // Filter roles based on search term (matching name or permissions)
    const filteredRoles = rolesList.filter((role) => {
        const search = searchTerm.toLowerCase();

        const nameMatch = role.name.toLowerCase().includes(search); // Check role name
        const permissionMatch = role.permissions.some((permission) =>
            permission.toLowerCase().includes(search) // Check role permissions
        );

        return nameMatch || permissionMatch; // Return true if either matches
    });
    const processPermissions = (permissions) => {
        const groupedPermissions = {};

        // Loop through the permissions array and group them by module
        permissions.forEach((permission) => {
            const [module, action] = permission.split('_'); // Split the permission (e.g., 'user_create' -> ['user', 'create'])

            // Initialize the module if it doesn't exist in the object
            if (!groupedPermissions[module]) {
                groupedPermissions[module] = [];
            }

            // Push the action (e.g., 'create', 'view') to the module
            groupedPermissions[module].push(action);
        });

        return groupedPermissions;
    };

    // Define a mapping of action names for better display
    const actionNames = {
        create: 'Create',
        view: 'View',
        assign: 'Assign',
        edit: 'Edit',
        update: 'Update',
        delete: 'Delete'
    };

    // Only process permissions if selectedRole and its permissions are defined
    const groupedPermissions = selectedRole && selectedRole?.permissions
        ? processPermissions(selectedRole.permissions)
        : {}; // Default to an empty object if selectedRole or permissions are null


    // Toggle Add Role form with permission check
    const toggleAddRoleForm = () => {
        if (Auth.innerPermission("role_create")) {
            setView('addRole'); // Show Add Role form if permission exists
        } else {
            toast.error("You don’t have permission to add roles"); // Show error message if no permission
        }
    };



    const generatePermissionsArray = () => {
        const permissions = [];

        permissionRoleList.forEach((permission) => {
            const moduleName = permission.name.toLowerCase(); // Convert module name to lowercase

            // Loop through actions and push checked permissions
            Object.keys(permission.actionsState).forEach((action) => {
                if (permission.actionsState[action]) { // If action is checked
                    permissions.push(permission.actions[action]); // Use the action value directly (e.g., "tenantlogin_view")
                }
            });
        });

        return permissions; // Return the generated permissions array
    };






    const AddRolesHandle = async (e) => {
        e.preventDefault();
        // setShowLoader(true);

        const permissions = generatePermissionsArray();
        if (permissions.length === 0) {
            toast.error('Please select at least one permission.');
            return;
        }

        try {
            const res = await axios.post(
                `${process.env.REACT_APP_LOCAL_URL}/roles/add`,
                {
                    name: roleName,
                    description: description,
                    permissions: permissions,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token?.access_token}`,
                    },
                }
            );

            // Check if response status is successful
            if (res.status === 200) {
                toast.success('Role added successfully');
                await handleRolesList();  // Refresh the roles list
                setShowAddRole(false);  // Hide form and reset fields
                setView('roleList');
                setRoleName('');
                setDescription('');
                resetPermissions();
                // setShowLoader(false);
            } else {
                throw new Error('Unexpected response status');  // For non-200 statuses
            }
        } catch (error) {
            console.error('Error adding role:', error);

            // Distinguish server error messages if available
            if (error.response) {
                if (error.response.status === 500 && error.response.data?.message) {
                    toast.error(`Server Error: ${error.response.data.message}`);
                } else if (error.response.data?.message) {
                    const { name, permissions } = error.response.data.message;
                    if (name) {
                        toast.error(`Error: ${name[0]}`);
                    }
                    if (permissions) {
                        toast.error(`Error: ${permissions[0]}`);
                    }
                }
            } else {
                toast.error('An unexpected error occurred. Please try again.');
            }
        }
    };

    const resetPermissions = () => {
        setPermissionRoleList((prevList) =>
            prevList.map((permission) => {
                const updatedActionsState = Object.keys(permission.actionsState).reduce((acc, action) => {
                    acc[action] = false; // Reset all checkboxes to unchecked
                    return acc;
                }, {});

                return {
                    ...permission,
                    actionsState: updatedActionsState,
                    selectAll: false, // Reset the "Select All" checkbox
                };
            })
        );
    };


    const handleDeleteRole = async (roleId) => {
        if (!Auth.innerPermission("role_delete")) {
            toast.error("You don’t have permission to add a role"); // Display toast message
            return; // Exit function if the user lacks permission
        }
        try {
            await http(
                {
                    method: 'DELETE',
                    url: `roles/${roleId}/delete`, // Append the role ID to the URL
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message)

                    // After deletion, update the roles list to remove the deleted role
                    setRolesList((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
                    setView('roleList');
                }
            );
        } catch (error) {
            console.error('Error deleting role:', error);
        }
    };


    const handleRolesList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `roles`,
                    isSecure: true,
                },
                (res) => {
                    setRolesList(res?.data);
                },
            )
        }
        catch (error) {
            console.error('Error fetching staff data:', error);
        }
    };

    useEffect(() => {
        handleRolesList();

    }, []);






    const handlePermissionRoleList = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `permissions?page=${currentPermissionPage}&limit=${PermissionLimit}`,

                    isSecure: true,
                },
                (res) => {
                    const permissionsWithState = res?.data?.data.map(permission => {
                        const actionsState = Object.keys(permission.actions).reduce((acc, action) => {
                            acc[action] = false;  // All actions start unchecked
                            return acc;
                        }, {});

                        return {
                            name: permission.name,
                            actions: permission.actions,
                            actionsState,  // Dynamically manage the state of checkboxes
                            selectAll: false,  // Select All starts unchecked
                        };
                    });

                    setPermissionRoleList(permissionsWithState);
                },
            );
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };

    useEffect(() => {
        handlePermissionRoleList();
    }, [currentPermissionPage]);



    const togglePermission = (roleName, actionType) => {
        setPermissionRoleList((prevList) =>
            prevList.map((permission) => {
                if (permission.name === roleName) {
                    const updatedActionsState = {
                        ...permission.actionsState,
                        [actionType]: !permission.actionsState[actionType], // Toggle the action state dynamically
                    };

                    // Check if all actions are now checked
                    const allChecked = Object.values(updatedActionsState).every((isChecked) => isChecked);

                    return {
                        ...permission,
                        actionsState: updatedActionsState,
                        selectAll: allChecked, // Automatically update the "Select All" checkbox
                    };
                }
                return permission;
            })
        );
    };
    const toggleSelectAll = (roleName) => {
        setPermissionRoleList((prevList) =>
            prevList.map((permission) => {
                if (permission.name === roleName) {
                    const newSelectAllState = !permission.selectAll; // Toggle the selectAll state

                    // Update all actions under this role to match the new Select All state
                    const updatedActionsState = Object.keys(permission.actionsState).reduce((acc, action) => {
                        acc[action] = newSelectAllState; // Set all actions to match the selectAll state
                        return acc;
                    }, {});

                    return {
                        ...permission,
                        actionsState: updatedActionsState,
                        selectAll: newSelectAllState, // Reflect the new state in the selectAll checkbox
                    };
                }
                return permission;
            })
        );
    };

    const toggleGlobalSelectAll = () => {
        const allSelected = permissionRoleList.every(permission =>
            Object.values(permission.actionsState).every(value => value === true)
        );

        setPermissionRoleList(prevList =>
            prevList.map(permission => {
                const updatedActionsState = Object.keys(permission.actionsState).reduce((acc, action) => {
                    acc[action] = !allSelected; // Toggle based on current allSelected status
                    return acc;
                }, {});

                return {
                    ...permission,
                    actionsState: updatedActionsState,
                    selectAll: !allSelected, // Reflect the "Select All" state for this module
                };
            })
        );
    };

    // Function to fetch and display specific role details (roles/{id}/view)
    const handleRoleDetails = async (roleId) => {
        try {
            http(
                {
                    method: "GET",
                    url: `roles/${roleId}/view`, // API endpoint for fetching role details
                    isSecure: true,
                },
                (res) => {
                    setSelectedRole(res?.data); // Store the fetched role data
                    setView('roleDetails'); // Switch to the role details view
                }
            );
        } catch (error) {
            console.error('Error fetching role details:', error);
        }
    };
    const handleBackToList = () => {
        setView('roleList'); // Change the view to 'roleList'
    };


    // Function to fetch and display specific role details (roles/{id}/view) for editing
    // const handleEditRole = async (roleId) => {
    //     if (!Auth.innerPermission("role_update")) {
    //         toast.error("You don’t have permission to add a role"); // Display toast message
    //         return; // Exit function if the user lacks permission
    //     }
    //     try {
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `roles/${roleId}/edit`, // API endpoint for fetching role details
    //                 isSecure: true,
    //             },

    //             (res) => {
    //                 const roleData = res?.data;

    //                 setSelectedRole(roleData);
    //                 setRoleName(roleData.name);
    //                 setDescription(roleData.description);

    //                 // Map over permissionRoleList and compute actionsState
    //                 const updatedPermissionRoleList = permissionRoleList.map((permission) => {
    //                     const actionsState = Object.keys(permission.actions).reduce((acc, actionKey) => {
    //                         const permissionKey = `${permission.name.toLowerCase()}_${actionKey.toLowerCase()}`; // Ensure case alignment
    //                         acc[actionKey] = roleData.permissions.includes(permissionKey);
    //                         return acc;
    //                     }, {});

    //                     return {
    //                         ...permission,
    //                         actionsState,
    //                         selectAll: Object.values(actionsState).every((isChecked) => isChecked), // All actions selected or not
    //                     };
    //                 });

    //                 setPermissionRoleList(updatedPermissionRoleList);
    //                 setView("editRole");
    //             }
    //         );
    //     } catch (error) {
    //         console.error('Error fetching role details:', error);
    //     }
    // };

    const handleEditRole = async (roleId) => {
        if (!Auth.innerPermission("role_update")) {
            toast.error("You don’t have permission to edit a role");
            return;
        }

        try {
            http(
                {
                    method: "GET",
                    url: `roles/${roleId}/edit`, // API endpoint for fetching role details
                    isSecure: true,
                },
                (res) => {
                    const roleData = res?.data;

                    setSelectedRole(roleData);
                    setRoleName(roleData.name);
                    setDescription(roleData.description);

                    // Normalize roleData.permissions to lowercase for consistent matching
                    const normalizedRolePermissions = roleData.permissions.map((perm) =>
                        perm.toLowerCase()
                    );

                    // Update permissionRoleList to pre-select permissions
                    const updatedPermissionRoleList = permissionRoleList.map((permission) => {
                        const actionsState = Object.keys(permission.actions).reduce((acc, actionKey) => {
                            const permissionKey = permission.actions[actionKey]; // Use the permission action key directly

                            // Check if normalizedRolePermissions includes the permissionKey
                            acc[actionKey] = normalizedRolePermissions.includes(permissionKey.toLowerCase());

                            return acc;
                        }, {});

                        return {
                            ...permission,
                            actionsState,
                            selectAll: Object.values(actionsState).every(Boolean), // Check if all actions are selected
                        };
                    });

                    setPermissionRoleList(updatedPermissionRoleList);
                    setView("editRole");
                }
            );
        } catch (error) {
            console.error("Error fetching role details:", error);
        }
    };


    const handleGlobalSelectAll = () => {
        const isAllSelected = permissionRoleList.every(permission =>
            Object.values(permission.actionsState).every(checked => checked)
        );

        setPermissionRoleList(prevList =>
            prevList.map(permission => {
                const updatedActionsState = Object.keys(permission.actionsState).reduce((acc, action) => {
                    acc[action] = !isAllSelected; // Set all actions to the opposite state
                    return acc;
                }, {});

                return {
                    ...permission,
                    actionsState: updatedActionsState,
                    selectAll: !isAllSelected, // Reflect "Select All" for each module
                };
            })
        );
    };
    const handleUpdateRole = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const permissions = generatePermissionsArray(); // Generate the permissions array from the selected checkboxes

        // Ensure at least one permission is selected
        if (permissions.length === 0) {
            toast.error('Please select at least one permission.');
            return; // Prevent form submission if no permissions are selected
        }

        try {
            const res = await axios.put(
                `${process.env.REACT_APP_LOCAL_URL}/roles/update/${selectedRole?.id}`, // Use the role ID in the API URL
                {
                    name: roleName, // Role name from input
                    description: description, // Description from input
                    permissions: permissions, // Generated permissions array
                },
                {
                    headers: {
                        Authorization: `Bearer ${token?.access_token}`, // Add authentication if needed
                    },
                }
            );
            if (res.data && res.data.message) {
                toast.success(res.data.message); // Show the success message from the response
            }


            // After successful update, fetch the updated roles list
            await handleRolesList();

            // Switch back to the listing view by toggling the form
            setView('roleList');
            setRoleName('');
            setDescription('');
            resetPermissions(); // Reset the permissions after editing
        } catch (error) {
            console.error('Error updating role:', error);

            // Axios error handling
            if (error?.response?.data?.message) {
                const { name, permissions } = error.response.data.message;

                // Show validation errors in toast notifications
                if (name) {
                    toast.error(`Error: ${name[0]}`); // Display first error message for "name" field
                }
                if (permissions) {
                    toast.error(`Error: ${permissions[0]}`); // Display first error message for "permissions" field
                }
            } else {
                toast.error('An unexpected error occurred. Please try again.');
            }
        }
    };

    return (
        <>
            <div>
                {view === 'addRole' && (
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4 me-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 fw-semibold">Add Roles</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                        </div>
                        <form onSubmit={AddRolesHandle}>
                            <div className="px-2">
                                <div className="row">
                                    <div className="col-lg-6 mt-3">
                                        <label htmlFor="roleName">Role Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-input mt-1"
                                            value={roleName}
                                            onChange={(e) => setRoleName(e.target.value)}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <label htmlFor="roleDescription">Description</label>
                                        <textarea
                                            className="mt-1 form-input"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            cols="10"
                                            rows="5"
                                            placeholder="Type here..."
                                        />
                                    </div>
                                </div>
                                <hr className="my-3 mx-2" />
                                <div className="row">
                                    <div className="col-lg-12 d-flex align-items-center gap-2">
                                        <h4 className="fs-18 fw-semibold">Set Permissions</h4>
                                        <div className="selectall d-flex align-items-center gap-1">
                                            <input type="checkbox" checked={permissionRoleList.every(permission => Object.values(permission.actionsState).every(value => value === true))}
                                                onChange={toggleGlobalSelectAll} // Global select all logic
                                            />
                                            <label htmlFor="selectall" className="text-bue">Select all</label>
                                        </div>
                                    </div>
                                    <div className="col-md-10 mx-auto permboxes mt-4">
                                        <>
                                            {permissionRoleList.map((permission, index) => (
                                                <div key={index}>
                                                    <div className="permtitle d-flex align-items-center gap-2">
                                                        <div className="selectall d-flex align-items-center gap-2">
                                                            <input
                                                                type="checkbox"
                                                                id={`selectAll_${permission.name}`}
                                                                className=""
                                                                checked={permission.selectAll}
                                                                onChange={() => toggleSelectAll(permission.name)}
                                                            />
                                                            {/* <label htmlFor="selectall" className="fs-13 text-blue">Select all</label> */}
                                                        </div>
                                                        <h6 className="fs-18 fw-semibold">{permission.name}</h6>
                                                    </div>
                                                    <ul className="mt-2">
                                                        {Object.entries(permission.actions).map(([actionKey, actionValue]) => (
                                                            <li key={actionKey} className="d-flex gap-2 align-items-center mb-2">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={permission.actionsState[actionKey]}
                                                                    onChange={() => togglePermission(permission.name, actionKey)}
                                                                />
                                                                <label>{actionKey}</label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    <hr className="my-4" />

                                                </div>
                                            ))}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="row px-2">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
                                        <button type="button" className="add-no px-4">No</button>
                                        <button type="submit" className="add-yes px-4">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
                {view === 'roleList' && (
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4 me-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 fw-semibold">Roles</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                            <button className="add-new"

                                onClick={toggleAddRoleForm}
                            >
                                <i className="fa fa-plus me-2"></i>
                                Add New
                            </button>

                        </div>
                        <div className="search-drop pt-2 px-4 pb-4 d-flex align-items-center justify-content-between flex-wrap gap-3">

                            <form
                                class="searchuser d-flex align-items-center gap-2"
                                onSubmit={(e) => e.preventDefault()}
                            >
                                <input
                                    type="search"
                                    className="form-input"
                                    name="search"
                                    placeholder="Search by role or permission"
                                    value={searchTerm}
                                    onChange={handleSearchChange} // Handle input change
                                />
                                <i className="fa fa-search"></i>
                            </form>
                        </div>



                        {Auth.innerPermission("role_list") || Auth.innerPermission("role_view") ? (
                            <div className="tableuser px-4 pb-0">
                                <table className="w-100">
                                    <thead className="pb-2">
                                        <tr>
                                            <th>Name</th>
                                            <th>Permissions</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRoles && filteredRoles.length > 0 ? (
                                            filteredRoles.map((role) => (
                                                <tr key={role.id} onClick={() => handleRoleDetails(role.id)}>
                                                    <td className="fw-bold">{role.name}</td>
                                                    {/* <td>
                                                        {role.permissions && role.permissions.length > 0 ? (
                                                            role.permissions.map((permission, index) => (
                                                                <span key={index} className="fw-medium badge bg-dark me-1">
                                                                    {permission} 
                                                                </span>
                                                            ))
                                                        ) : (
                                                            <span className="text-muted">No Permissions</span> 
                                                        )}
                                                    </td> */}

                                                    <td>
                                                        {role.permissions && role.permissions.length > 0 ? (
                                                            <>
                                                                {/* Display the first 8 permissions */}
                                                                {role.permissions.slice(0, 4).map((permission, index) => (
                                                                    <span key={index} className="fw-medium badge bg-dark me-1">
                                                                        {permission}
                                                                    </span>
                                                                ))}

                                                                {/* If there are more than 8 permissions, show a "Pending Permissions" label with a tooltip */}
                                                                {role.permissions.length > 4 && (
                                                                    <span
                                                                        className="fw-medium badge bg-secondary rounded-circle" // Add rounded-circle class for round shape
                                                                        style={{
                                                                            padding: '5px 10px', // Adjust padding to make it more circular
                                                                            minWidth: '30px',     // Set a minimum width
                                                                            height: '30px',       // Set a fixed height
                                                                            display: 'inline-flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center', // Center the text inside the circle
                                                                        }}
                                                                        title={`Pending Permissions: ${role.permissions.length - 4}`} // Tooltip text
                                                                    >
                                                                        +{role.permissions.length - 4}
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <span className="text-muted">No Permissions</span>
                                                        )}
                                                    </td>

                                                    <td className="d-flex align-items-center gap-2">
                                                        <div className="editicon" onClick={() => handleEditRole(role?.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                                <g id="write" transform="translate(-7.998 -8)">
                                                                    <path
                                                                        id="Path_3528"
                                                                        data-name="Path 3528"
                                                                        d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z"
                                                                        transform="translate(0 -7.297)"
                                                                        fill="#202529"
                                                                    />
                                                                    <path
                                                                        id="Path_3529"
                                                                        data-name="Path 3529"
                                                                        d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z"
                                                                        transform="translate(-20.425 0)"
                                                                        fill="#202529"
                                                                    />
                                                                    <path
                                                                        id="Path_3530"
                                                                        data-name="Path 3530"
                                                                        d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z"
                                                                        transform="translate(-14.877 -31.168)"
                                                                        fill="#202529"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="deticon" onClick={() => handleDeleteRole(role.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                                                <path
                                                                    id="Path_3741"
                                                                    data-name="Path 3741"
                                                                    d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z"
                                                                    transform="translate(-399 -1335)"
                                                                    fill="#202529"
                                                                    fill-rule="evenodd"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">No roles available.</td> {/* Show message if no roles */}
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (

                            <div>
                                <div><h4 className="fs-16 text-danger my-5 mx-auto text-center">You don’t have permission to view this information.</h4></div>
                            </div>
                        )}
                        <ReactPaginate
                            previousLabel="< Previous"
                            nextLabel="Next >"
                            breakLabel="..."
                            pageRangeDisplayed={3}
                            pageCount={pagePermissionCount}
                            marginPagesDisplayed={2}
                            onPageChange={handlePermissionPageClick}
                            containerClassName="pagination justify-content-end align-items-center mt-2 px-4"
                            pageClassName="page-item nowrap"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            activeClassName="active"
                            forcePage={currentPermissionPage - 1}
                        />

                    </div>

                )}
                {view === 'roleDetails' && selectedRole && (

                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4 me-4">
                        <div className="back px-4 pt-4 pb-0" onClick={handleBackToList}>
                            <a className="cursor-pointer d-flex align-items-center gap-1 text-blue me-2 fw-semibold justify-content-end" ><i className="fa fa-arrow-left"></i>Back to List</a>
                        </div>
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-2 pb-0">
                            <div>
                                <h4 className="fs-20 fw-semibold">Role Details</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                            <div className="rhead d-flex align-items-center gap-2">
                                <div className="editicon cursor-pointer" onClick={() => handleEditRole(selectedRole.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                        <g id="write" transform="translate(-7.998 -8)">
                                            <path id="Path_3528" data-name="Path 3528" d="M8.3,26.038,8,28.469a.731.731,0,0,0,.728.827.718.718,0,0,0,.093-.006L11.257,29a2.317,2.317,0,0,0,1.35-.663l6.3-6.3a.292.292,0,0,0,0-.412l-3.232-3.235a.292.292,0,0,0-.412,0l-6.3,6.3a2.317,2.317,0,0,0-.663,1.35Z" transform="translate(0 -7.297)" fill="#202529" />
                                            <path id="Path_3529" data-name="Path 3529" d="M41.979,9.951,40.472,8.443a1.512,1.512,0,0,0-2.138,0L36.918,9.859a.292.292,0,0,0,0,.412L40.151,13.5a.292.292,0,0,0,.412,0l1.416-1.416A1.512,1.512,0,0,0,41.979,9.951Z" transform="translate(-20.425 0)" fill="#202529" />
                                            <path id="Path_3530" data-name="Path 3530" d="M36.875,52.583A.583.583,0,0,0,36.291,52H29.583a.583.583,0,0,0,0,1.167h6.708A.583.583,0,0,0,36.875,52.583Z" transform="translate(-14.877 -31.168)" fill="#202529" />
                                        </g>
                                    </svg>
                                </div>
                                <div className="deticon cursor-pointer" onClick={() => handleDeleteRole(selectedRole.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.598" height="13.998" viewBox="0 0 12.598 13.998">
                                        <path id="Path_3741" data-name="Path 3741" d="M411.1,1337.268a.46.46,0,0,1,.193.042.5.5,0,0,1,.163.112.517.517,0,0,1,.107.168.5.5,0,0,1,.036.2v.266a.512.512,0,0,1-.144.357.491.491,0,0,1-.355.154H399.5a.49.49,0,0,1-.355-.154.51.51,0,0,1-.145-.357v-.266a.514.514,0,0,1,.143-.364.5.5,0,0,1,.163-.112.461.461,0,0,1,.193-.042h2.041a.865.865,0,0,0,.559-.2.892.892,0,0,0,.309-.5l.106-.476a1.391,1.391,0,0,1,.482-.777,1.412,1.412,0,0,1,.857-.308h2.891a1.418,1.418,0,0,1,.843.3,1.391,1.391,0,0,1,.486.749l.114.511a.9.9,0,0,0,.309.511.948.948,0,0,0,.559.2Zm-1.037,9.722c.21-1.981.586-6.7.586-6.747a.527.527,0,0,0-.023-.21.58.58,0,0,0-.1-.182.528.528,0,0,0-.166-.119.49.49,0,0,0-.2-.042h-9.708a.5.5,0,0,0-.2.042.52.52,0,0,0-.166.119.566.566,0,0,0-.133.392c0,.007.015.175.037.455.1,1.232.376,4.668.555,6.3a2.033,2.033,0,0,0,2.051,1.974c.879.021,1.784.028,2.71.028.872,0,1.757-.007,2.66-.028a2.017,2.017,0,0,0,1.459-.553,2.039,2.039,0,0,0,.64-1.428Z" transform="translate(-399 -1335)" fill="#202529" fill-rule="evenodd" />
                                    </svg>
                                </div>

                            </div>
                            {/* <button className="add-new-button"></button> */}
                        </div>
                        <div className="roles-description mt-3 px-4">
                            <h4 className="fs-20 fw-semibold">{selectedRole?.name}</h4>
                            <p className="mt-3 text-secondary fs-18">
                                The Super Administrator has the highest level of access to the accounting software. This role is responsible for managing user accounts, assigning roles, and configuring system settings. Super Admins ensure data security, oversee system performance, and conduct regular backups. They provide support and training to users and ensure compliance with financial regulations. This role is essential for maintaining the integrity and efficiency of the software.
                            </p>
                            <hr className="my-4" />
                        </div>

                        <div className="row px-2">
                            {Object.keys(groupedPermissions).map((module, index) => (
                                <div className="col-lg-3 mt-4" key={index}>
                                    <h6 className="fs-18 fw-semibold">
                                        {module.charAt(0).toUpperCase() + module.slice(1)} {/* Capitalize module name */}
                                    </h6>
                                    <ul className="ps-0 assignrole mt-2">
                                        {groupedPermissions[module].map((action, actionIndex) => (
                                            <li className="fs-16 fw-regular mb-2" key={actionIndex}>
                                                <span></span>{actionNames[action] || action} {module.charAt(0).toUpperCase() + module.slice(1)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                )}


                {view === 'editRole' && selectedRole && (
                    <div className="bg-white rounded-4 shadow-main h_800 overflow-y-scroll pb-4 me-4">
                        <div className="accord-head d-flex align-items-start justify-content-between px-4 pt-4 pb-0">
                            <div>
                                <h4 className="fs-20 fw-semibold">Edit Role</h4>
                                <p className="t_blue">Users & Roles</p>
                            </div>
                        </div>
                        <form onSubmit={handleUpdateRole}>
                            <div className="px-2">
                                <div className="row">
                                    <div className="col-lg-6 mt-3">
                                        <label htmlFor="roleName">Role Name <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-input mt-1"
                                            value={roleName}
                                            onChange={(e) => setRoleName(e.target.value)}
                                            placeholder="Type here..."
                                        />
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <label htmlFor="roleDescription">Description</label>
                                        <textarea
                                            className="mt-1 form-input"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            cols="10"
                                            rows="5"
                                            placeholder="Type here..."
                                        />
                                    </div>
                                </div>

                                <hr className="my-3 mx-2" />

                                <div className="row">
                                    <div className="col-lg-12 d-flex align-items-center gap-2">
                                        <h4 className="fs-18 fw-semibold">Set Permissions</h4>
                                        <div className="selectall d-flex align-items-center gap-1">
                                            <input
                                                type="checkbox"
                                                checked={permissionRoleList.every(permission =>
                                                    Object.values(permission.actionsState).every(value => value === true)
                                                )}
                                                onChange={handleGlobalSelectAll} // Global select all logic
                                            />
                                            <label htmlFor="selectall" className="text-blue">Select all</label>
                                        </div>
                                    </div>
                                    <div className="col-md-10 mx-auto permboxes mt-4">
                                        <>
                                            {permissionRoleList.map((permission, index) => (
                                                <div key={index}>
                                                    <div className="permtitle d-flex align-items-center gap-2">
                                                        <div className="selectall d-flex align-items-center gap-1">
                                                            <input
                                                                type="checkbox"
                                                                id={`selectAll_${permission.name}`}
                                                                checked={permission.selectAll}
                                                                onChange={() => toggleSelectAll(permission.name)}
                                                            />
                                                            <h6 className="fs-18 fw-semibold">{permission.name}</h6>
                                                        </div>
                                                    </div>
                                                    <ul className="mt-2">
                                                        {/* {Object.keys(permission.actions).map((actionKey) => (
                                                            permission.actions[actionKey] === 'yes' && (
                                                                <li key={actionKey} className="d-flex gap-2 align-items-center mb-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`${permission.name}_${actionKey}`}
                                                                        checked={permission.actionsState[actionKey]} 
                                                                        onChange={() => togglePermission(permission.name, actionKey)}
                                                                    />
                                                                    <label htmlFor={`${permission.name}_${actionKey}`}>
                                                                        {actionKey === 'create' ? `Create New ${permission.name}`
                                                                            : actionKey.charAt(0).toUpperCase() + actionKey.slice(1)}
                                                                    </label>
                                                                </li>
                                                            )
                                                        ))} */}
                                                        {/* 
                                                        <ul className="mt-2">
                                                            {Object.entries(permission.actions).map(([actionKey, actionValue]) => (
                                                                <li key={actionKey} className="d-flex align-items-center gap-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={permission.actionsState[actionKey] || false} // Safely access state
                                                                        onChange={() => togglePermission(permission.name, actionKey)}
                                                                    />
                                                                    <label>{actionKey.charAt(0).toUpperCase() + actionKey.slice(1)}</label>
                                                                </li>
                                                            ))}
                                                        </ul> */}

                                                        <ul className="mt-2">
                                                            {Object.entries(permission.actions).map(([actionKey, actionValue]) => (
                                                                <li key={actionKey} className="d-flex gap-2 align-items-center mb-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={permission.actionsState[actionKey] || false}
                                                                        onChange={() => togglePermission(permission.name, actionKey)}
                                                                    />
                                                                    <label>{actionKey}</label>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <hr className="my-4" />
                                                    </ul>
                                                </div>
                                            ))}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="d-flex align-items-center justify-content-end gap-2 mt-3 px-2">
                                        <button type="button" className="add-no px-4" onClick={handleBackToList}>Cancel</button>
                                        <button type="submit" className="add-yes px-4">Update Role</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )}

            </div>
        </>
    )
}
export default Roles;