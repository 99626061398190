
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Auth from "../../auth/Auth";
import AdminHeader from "../admin/header/AdminHeader";
import AdminSideBar from "../admin/sideBar/AdminSideBar";
import SuperAdminHeader from "../superAdmin/header/SuperAdminHeader";
import SuperAdminSideBar from "../superAdmin/sidebar/SuperAdminSideBar";
import Footer from "../common/Footer";

const DashboardLayout = ({ userRole, children }) => {

  const [isToggled, setIsToggled] = useState(false);

  const toggleSidebar = () => {
    setIsToggled(!isToggled);
  };
  const renderHeader = () => {
    switch (userRole) {
      case "admin":
        return <AdminHeader isToggled={isToggled} toggleSidebar={toggleSidebar} />;
      case "superAdmin":
        return <SuperAdminHeader isToggled={isToggled} toggleSidebar={toggleSidebar} />;
      default:
        return null;
    }
  };

  const renderSideBar = () => {
    switch (userRole) {
      case "admin":
        return <AdminSideBar isToggled={isToggled} toggleSidebar={toggleSidebar} />;
      case "superAdmin":
        return <SuperAdminSideBar isToggled={isToggled} toggleSidebar={toggleSidebar} />;
      default:
        return null;
    }
  };

  const renderDashboard = () => {
    switch (userRole) {
      case "admin":
        return Auth.isUserAuthenticated() ? (
          <div className="">{children}</div>
        ) : (
          <Navigate to="/admin/login" replace />
        );
      case "superAdmin":
        return Auth.isUserAuthenticated() ? (
          <div className="">{children}</div>
        ) : (
          <Navigate to="/superAdmin/login" replace />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className={`d-flex font-poppins ${isToggled ? 'toggled' : ''}`} id="wrapper">

        {renderSideBar()}
        <div id="page-content-wrapper" className="">
          {renderHeader()}
          {renderDashboard()}
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
