import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../App";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const RecoverPassword = () => {
    const { setShowLoader } = useContext(GlobalContext);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });

    const formValues = watch();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const otpEmail = JSON.parse(localStorage.getItem("recoveryemail"));

    // Function to handle password change
    const handleChangePassword = (data) => {
        // Removed e.preventDefault() because handleSubmit handles this
        if (data.password !== data.current_password) {
            toast.error("Passwords do not match");
            return;
        }
        setShowLoader(true);
        http(
            {
                method: "POST",
                url: "reset-password",
                isSecure: true,
                body: {
                    new_password: data.new_password,
                    new_password_confirmation: data.new_password_confirmation,
                    email: otpEmail?.email,
                },
            },
            (res) => {
                if (res) {
                    setShowLoader(false);
                    navigate("/admin/login");
                    toast.success(res.message);
                }
            },
            (err) => {

                setShowLoader(false);
                toast.error(err.message);
            }
        );
    };

    return (
        <div className="form-page otpverify">
            <div className="row m-auto">
                <div className="col-lg-6 bg-white formRight position-relative d-flex align-items-center justify-content-center py-5">
                    <img
                        src={require("../../../assets/images/assistlogo.png")}
                        alt="assistlogo"
                        className="assistlogo position-absolute pb-5"
                    />
                    <img
                        src={require("../../../assets/images/form-top.png")}
                        alt="formtop"
                        className="formtop position-absolute"
                    />
                    <img
                        src={require("../../../assets/images/form-bottom.png")}
                        alt="formbottom"
                        className="formbottom position-absolute bottom-0"
                    />
                    <div className="main-form font-poppins">
                        {/* Use handleSubmit from react-hook-form to wrap handleChangePassword */}
                        <form onSubmit={handleSubmit(handleChangePassword)}>
                            <div className="form-head">
                                <img
                                    src={require("../../../assets/images/otpicon.png")}
                                    className="mb-5 otpicon"
                                    alt=""
                                />
                                <h2 className="fw-bold">Recover Password</h2>
                                <h6 className="text-muted">
                                    To reset your password, please follow the instructions below.
                                </h6>
                            </div>

                            <div className="form-group input-group position-relative mt-3">
                                <label htmlFor="password" className="text-muted">
                                    New Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="lockIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showPassword ? "text" : "new_password"}
                                        className="form-control border-0 py-3 w-100 text-black"
                                        placeholder="**********"
                                        name="new_password"
                                        id="new_password"
                                        {...register("new_password", {
                                            required: true,
                                            minLength: 8,
                                        })}
                                    />
                                    {errors.new_password?.type === "required" && (
                                        <p role="alert" className="text-danger mb-0 Pt-1 -fs-7 ms-1">
                                            Required
                                        </p>
                                    )}
                                    {errors.new_password?.type === "minLength" && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            Password must be at least 8 characters
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group input-group position-relative mt-3">
                                <label htmlFor="current_password" className="text-muted">
                                    Confirm Password
                                </label>
                                <div className="icon-input w-100 rounded mt-2 position-relative">
                                    <img
                                        src={require("../../../assets/images/lock.png")}
                                        alt="lockIcon"
                                        className="position-absolute"
                                    />
                                    <input
                                        type={showConfirmPassword ? "text" : "new_password_confirmation"}
                                        className="form-control border-0 py-3 w-100 text-black"
                                        name="new_password_confirmation"
                                        id="new_password_confirmation"
                                        placeholder="Confirm Password"
                                        {...register("new_password_confirmation", {
                                            required: true,
                                            minLength: 8,
                                        })}
                                    />
                                    {errors.new_password_confirmation?.type === "required" && (
                                        <p role="alert" className="text-danger mb-0 Pt-1 -fs-7 ms-1">
                                            Required
                                        </p>
                                    )}
                                    {errors.new_password_confirmation?.type === "minLength" && (
                                        <p role="alert" className="text-danger mb-0 pt-1 -fs-7 ms-1">
                                            Password must be at least 8 characters
                                        </p>
                                    )}
                                    {/* EYES ICON */}
                                    <div className="eye-icons position-absolute">
                                        <>
                                            {showConfirmPassword ? (
                                                <img
                                                    src={require("../../../assets/images/eye.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    src={require("../../../assets/images/eye-hide.png")}
                                                    className="me-2"
                                                    alt="emailIcon"
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                />
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>

                            <div className="error-text mt-3 d-flex align-items-start">
                                <img
                                    src={require("../../../assets/images/tick.png")}
                                    alt="tickIcon"
                                    className="me-2"
                                />
                                <p className="mb-0">
                                    A combination of uppercase letters, lowercase letters,
                                    numbers, and symbols.
                                </p>
                            </div>

                            <div className="form-group mt-3 text-center">
                                <input
                                    type="submit"
                                    value="Done"
                                    className="w-100 sub-button rounded-4 border-none border py-3 text-white"
                                />
                            </div>
                        </form>
                    </div>
                </div>

                <div className="col-lg-6 formLeft vh-100">
                    <div className="left-text position-relative vh-100">
                        <div className="text-bottom pe-5 lg:pe-0 mt-0 lg:mt-5 position-absolute">
                            <h2 className="text-white font-impact mb-0 lg:mb-5">Assisterp</h2>
                            <p className="fw-light mt-4 text-white font-poppins">
                                It is a long-established fact that a reader will be distracted
                                by the readable content of a page when looking at its layout.
                                The point of using Lorem Ipsum is that it has a more-or-less
                                normal distribution of letters, as opposed to using 'Content
                                here, content here', making it look like readable English.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecoverPassword;
