import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { http } from "../../http/http";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
// import { handlePermissionData, handlePermissionIdsData } from '../../http/help';
import Auth from "../../../auth/Auth";

const Invoice_Details = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [invoiceList, setInvoiceList] = useState([]);
    const [reccuringInvoiceList, setReccuringInvoiceList] = useState([])
    const [activeFilter, setActiveFilter] = useState('All');
    const [activeFilterRecurring, setActiveFilterRecurring] = useState('All');
    const [showOptions, setShowOptions] = useState(false);
    const [showTotal, setShowTotal] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRecurring, setIsOpenRecurring] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPageRecurring, setCurrentPageRecurring] = useState(1);
    const [totalPagesRecurring, setTotalPagesRecurring] = useState(1);


    const filterOptions = {
        All: "0",
        Paid: "1",
        Unpaid: "2",
        Draft: "3",
        "Partially Paid": "4",
        Active: "5",
    };


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdownRecurring = () => {
        setIsOpenRecurring(!isOpenRecurring);
    };
    const getListHandle = (page) => {
        http(
            {
                method: "GET",
                url: `invoice/listing?page=${page}`,
                isSecure: true,

            },
            (res) => {
                setInvoiceList(res?.data?.invoice?.data)
                setCurrentPage(res?.data?.invoice.current_page);
                setTotalPages(res?.data?.invoice.last_page);
            },
            (err) => {

            }
        );
    }

    useEffect(() => {
        getListHandle(currentPage);
    }, [currentPage]);

    // const handlePageChange = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //         setCurrentPage(page);
    //     }
    // };
    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPage(selectedPage);
    };

    const getReccuringInvoiceList = () => {
        http(
            {
                method: "GET",
                url: "invoice/reccuring/listing",
                isSecure: true,

            },
            (res) => {
                setReccuringInvoiceList(res?.data?.invoice?.data)
                setCurrentPageRecurring(res?.data?.invoice.current_page);
                setTotalPagesRecurring(res?.data?.invoice.last_page);
            },
            (err) => {

            }
        );
    }
    const handlePageClickRecurring = (event) => {
        const selectedPage = event.selected + 1;
        setCurrentPageRecurring(selectedPage);
    };



    useEffect(() => {
        // getListHandle();
        getReccuringInvoiceList(currentPageRecurring);
    }, [currentPageRecurring]);

    const getTotalInvoice = () => {
        http(
            {
                method: "GET",
                url: "invoice/total",
                isSecure: true,

            },
            (res) => {

                setShowTotal(res?.data)
            },
            (err) => {

            }
        );
    }

    useEffect(() => {
        getTotalInvoice();
    }, []);

    const filterInvoices = (status) => {
        setActiveFilter(status);
        setIsOpen(false);
    };

    const filteredInvoices = () => {
        if (activeFilter === 'All') {
            return invoiceList;
        }

        if (activeFilter === 'Paid') {
            return invoiceList.filter(invoice => invoice.status == 1);
        }
        if (activeFilter === 'Unpaid') {
            return invoiceList.filter(invoice => invoice.status == 3);
        }
        if (activeFilter === 'Draft') {
            return invoiceList.filter(invoice => invoice.status == 2);
        }
        if (activeFilter === 'Partially Paid') {
            return invoiceList.filter(invoice => invoice.status == 4);
        }
        if (activeFilter === 'Active') {
            return invoiceList.filter(invoice => invoice.status == 5);
        }

        // Add more filters as needed
    };
    const filterInvoicesRecurring = (status) => {
        setActiveFilterRecurring(status);
        setIsOpenRecurring(false);
    };

    const filteredInvoicesRecurring = () => {
        if (activeFilterRecurring === 'All') {
            return reccuringInvoiceList;
        }
        if (activeFilterRecurring === 'Active') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 1);
        }
        if (activeFilterRecurring === 'InActive') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 2);
        }
        if (activeFilterRecurring === 'Stopped') {
            return reccuringInvoiceList.filter(invoice => invoice.status == 3);
        }


        // Add more filters as needed
    };



    // const getSerachInvoice = () => {
    //     http(
    //         {
    //             method: "POST",
    //             url: "invoice/search",
    //             isSecure: true,
    //             body: {
    //                 status: "3"
    //             }

    //         },
    //         (res) => {
    //         },
    //         (err) => {

    //         }
    //     );
    // }



    const handleAddInvoiceClick = () => {
        if (Auth.innerPermission("invoice_create")) {
            navigate('/admin/invoice/add_invoice');
        } else {
            toast.error("You are not authorized to access the resource");
        }
    };

    // onClick handler for the "Add New Invoice" button
    const handleAddRecurringInvoiceClick = () => {
        if (Auth.innerPermission("recurring_invoice_create")) {
            navigate('/admin/invoice/add_reccuring_invoice');
        } else {
            toast.error("You are not authorized to access the resource");
        }
    };

    return (
        <div className="right_section font-poppins">
            <div className="clients-area py-3">
                <div className="right_section font-poppins">

                    <div className="invoice-head-main mt-4 d-flex align-items-center justify-content-between gap-3 flex-wrap">

                        <div className="overdue" >
                            <h4>${showTotal?.total_due_amount}</h4>
                            <p className="overdue-badge">Overdue</p>
                        </div>
                        <span></span>
                        <div className="open">
                            <h4>${showTotal?.total_paid_amount}</h4>
                            <p className="paid-badge">Paid</p>
                        </div>
                        <span></span>
                        <div className="draft">
                            <h4>${showTotal?.total_draft_amount}</h4>
                            <p className="draft-badge">Draft</p>
                        </div>
                    </div>

                    <div className="invoice-area py-4">
                        <ul className="nav nav-tabs gap-2 mb-3" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="all-invoice-tab" data-bs-toggle="tab" data-bs-target="#all-invoice" type="button" role="tab" aria-controls="all-invoice" aria-selected="true">All Invoices</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="recurring-invoice-tab" data-bs-toggle="tab" data-bs-target="#recurring-invoice" type="button" role="tab" aria-controls="recurring-invoice" aria-selected="false">Recurring Invoices</button>
                            </li>
                        </ul>

                        <div className="tab-content" id="myTabContent mt-2">
                            <div className="tab-pane fade show active" id="all-invoice" role="tabpanel" aria-labelledby="all-invoice-tab">
                                <div className="invoice-area mt-2 align-items-center">
                                    <div className="row invoice-head">
                                        <div className="col-md-12 left_head d-flex align-items-center justify-content-between flex-wrap">
                                            <h4 className="fs-20 mt-2 fw-semibold">All Invoices</h4>

                                            <div className="right-inv-head d-flex align-items-center gap-2 flex-wrap">
                                                <div className="dropdown-custom">
                                                    <div className="filter-new position-relative">
                                                        <div className="custom-filter h-40 d-flex align-items-center justify-content-between" onClick={toggleDropdown}>
                                                            <h5 className="d-flex align-items-center gap-2">
                                                                <img className={`rotate ${isOpen ? 'open' : ''}`} src={require("../../../assets/images/filter_icon.png")} alt="" />
                                                                Filter
                                                            </h5>
                                                            <img className="rotate" src={require("../../../assets/images/drop_down_filter.png")} alt="" />
                                                        </div>
                                                        {isOpen && (
                                                            <div className="custom-filter-open shadow-main">
                                                                <ul className="pb-2 ps-0">
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('All')}>All</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('Paid')}>Paid</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('Unpaid')}>Unpaid</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('Draft')}>Draft</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('Partially Paid')}>Partial</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoices('Active')}>Active</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <a className="add-new text-white d-flex align-items-center py-3" onClick={handleAddInvoiceClick}>
                                                    <img src="assets/images/add.svg" className="me-2" alt="" />
                                                    Add New Invoice
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {canViewInvoice innerPermission() ? ( */}
                                    {Auth.innerPermission("invoice_list") ? (
                                        <div className="main-table-area mt-2 invoice-table">
                                            <div className="table-responsive">

                                                <table className="w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>Invoice No.</th>
                                                            <th>Customer</th>
                                                            <th>Order No.</th>
                                                            <th>Due Date</th>
                                                            <th>Invoice Date</th>
                                                            <th>Status</th>
                                                            <th>Total Amount</th>
                                                            <th>Paid Amount</th>
                                                            <th>Balance Due</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Array.isArray(filteredInvoices()) && filteredInvoices().length > 0 ? (
                                                                filteredInvoices().map((invoice, index) => (
                                                                    <tr
                                                                        key={invoice?.id}
                                                                        //   onClick={() => navigate(`/admin/invoice/invoice_preview/${invoice?.id}`)}
                                                                        onClick={() => {
                                                                            if (Auth.innerPermission('invoice_view')) { // Check for permission
                                                                                navigate(`/admin/invoice/invoice_preview/${invoice?.id}`);
                                                                            } else {
                                                                                toast.error('You do not have permission to view this invoice.');
                                                                            }
                                                                        }}
                                                                    >
                                                                        <td>{invoice?.invoice_number}</td>
                                                                        <td>
                                                                            {invoice?.client_type == 1
                                                                                ? invoice?.customer?.first_name
                                                                                : invoice?.client_type == 2
                                                                                    ? invoice?.customer?.name
                                                                                    : '--'}
                                                                        </td>
                                                                        <td>{invoice?.order_number}</td>
                                                                        <td>{invoice?.due_date ? moment(invoice?.due_date).format('DD MMM YYYY') : '--'}</td>
                                                                        <td>{moment(invoice?.invoice_date).format('DD MMM YYYY')}</td>
                                                                        <td>
                                                                            <span
                                                                                className="publish"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        invoice?.status == 1 ? '#D1E8D0' : // light green
                                                                                            invoice?.status == 2 ? '#F1F1F1' : // light gray
                                                                                                invoice?.status == 3 ? '#FED6D6' : // light red
                                                                                                    invoice?.status == 4 ? '#FFEECC' : // light yellow
                                                                                                        'transparent',
                                                                                    color:
                                                                                        invoice?.status == 1 ? '#1C8B15' : // green
                                                                                            invoice?.status == 2 ? '#202529' : // black
                                                                                                invoice?.status == 3 ? '#F93333' : // red
                                                                                                    invoice?.status == 4 ? '#FFAA27' : // yellow
                                                                                                        'inherit'
                                                                                }}
                                                                            >
                                                                                {invoice?.status == 1 ? 'Paid' :
                                                                                    invoice?.status == 2 ? 'Draft' :
                                                                                        invoice?.status == 3 ? 'Unpaid' :
                                                                                            invoice?.status == 4 ? 'Partially Paid' :
                                                                                                invoice?.status == 5 ? 'Active' : '--'}
                                                                            </span>
                                                                        </td>
                                                                        <td>${invoice?.total_price || "--"}</td>
                                                                        <td>{invoice?.payment_price || "--"}</td>
                                                                        <td>{invoice?.balance_due || "--"}</td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="9" className='text-center'>No invoices found.</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>

                                                </table>

                                                <ReactPaginate
                                                    previousLabel="<"
                                                    nextLabel=">"
                                                    breakLabel="..."
                                                    pageCount={totalPages}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClick}
                                                    containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                    pageClassName='page-item nowrap'
                                                    pageLinkClassName='page-link'
                                                    previousClassName='page-item'
                                                    previousLinkClassName='page-link'
                                                    nextClassName='page-item'
                                                    nextLinkClassName='page-link'
                                                    breakClassName='page-item'
                                                    breakLinkClassName='page-link'
                                                    activeClassName='active'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no-access-message">
                                            You are not authorized to access the resource.
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="tab-pane fade" id="recurring-invoice" role="tabpanel" aria-labelledby="recurring-invoice-tab">
                                <div className="invoice-area mt-2 align-items-center">
                                    <div className="row invoice-head">
                                        <div className="col-md-12 left_head d-flex align-items-center flex-wrap justify-content-between">
                                            <h4 className="fs-20 mt-2 fw-semibold">Recurring Invoices</h4>

                                            <div className="right-inv-head d-flex align-items-center gap-2 flex-wrap">
                                                <div className="dropdown-custom">
                                                    <div className="filter-new position-relative">
                                                        <div className="custom-filter h-40 d-flex align-items-center justify-content-between" onClick={toggleDropdownRecurring}>
                                                            <h5 className="d-flex align-items-center gap-2">
                                                                <img className={`rotate ${isOpenRecurring ? 'open' : ''}`} src={require("../../../assets/images/filter_icon.png")} alt="" />
                                                                Filter
                                                            </h5>
                                                            <img className="rotate" src={require("../../../assets/images/drop_down_filter.png")} alt="" />
                                                        </div>
                                                        {isOpenRecurring && (
                                                            <div className="custom-filter-open shadow-main">
                                                                <ul className="pb-2 ps-0">
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('All')}>All</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('Active')}>Active</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('InActive')}>Inactive</li>
                                                                    <li className="fs-14 mx-2" onClick={() => filterInvoicesRecurring('Stopped')}>Stopped</li>

                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <a className="add-new text-white d-flex align-items-center py-3" onClick={handleAddRecurringInvoiceClick}>
                                                    <img src="assets/images/add.svg" className="me-2" alt="" />
                                                    Add New Recurring Invoice
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {Auth.innerPermission("recurring_invoice_list") ? (
                                        <div className="main-table-area mt-2 invoice-table">
                                            <div className="table-responsive">
                                                <table className="w-100">
                                                    <thead>
                                                        <tr>


                                                            <th>Recurring Invoice No.</th>
                                                            <th>Customer</th>
                                                            <th>Order No.</th>
                                                            <th>Due Date</th>
                                                            <th>Last Invoice Date</th>
                                                            <th>Next Invoice Date</th>
                                                            <th>Total Amount</th>
                                                            <th>Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            Array.isArray(filteredInvoicesRecurring()) && filteredInvoicesRecurring().length > 0 ? (
                                                                filteredInvoicesRecurring().map((recurringList, index) => (
                                                                    <tr
                                                                        key={recurringList?.id}
                                                                        //   onClick={() => navigate(`/admin/invoice/reccuring_preview/${recurringList?.invoices?.[0]?.id}`)}
                                                                        onClick={() => {
                                                                            if (Auth.innerPermission('recurring_invoice_view')) { // Check for permission
                                                                                navigate(`/admin/invoice/reccuring_preview/${recurringList?.invoices?.[0]?.id}`);
                                                                            } else {
                                                                                toast.error('You do not have permission to view this invoice.');
                                                                            }
                                                                        }}

                                                                    >
                                                                        <td>{recurringList?.recurring_number}</td>
                                                                        <td>{recurringList?.invoices?.[0]?.client_type === "1"
                                                                            ? recurringList?.invoices?.[0]?.customer?.first_name
                                                                            : recurringList?.invoices?.[0]?.client_type === "2"
                                                                                ? recurringList?.invoices?.[0]?.customer?.name
                                                                                : '--'}</td>

                                                                        <td>{recurringList?.invoices?.[0]?.order_number || "--"}</td>
                                                                        <td>{recurringList?.due_date ? moment(recurringList?.due_date).format('DD MMM YYYY') : "--"}</td>
                                                                        <td>{recurringList?.invoice_date ? moment(recurringList?.invoice_date).format('DD MMM YYYY') : "--"}</td>
                                                                        <td>{recurringList?.recurring_start_next_date ? moment(recurringList?.recurring_start_next_date).format('DD MMM YYYY') : "--"}</td>
                                                                        <td>${recurringList?.invoices?.[0]?.total_price || "--"}</td>
                                                                        <td>
                                                                            <span
                                                                                className="publish"
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        recurringList?.recurring_status === "1" ? '#D5E2F6' : // light green
                                                                                            recurringList?.recurring_status === "2" ? '#F1F1F1' : // light gray
                                                                                                recurringList?.recurring_status === "3" ? '#FED6D6' : // light red
                                                                                                    'transparent',
                                                                                    color:
                                                                                        recurringList?.recurring_status === "1" ? '#024FC5' : // green
                                                                                            recurringList?.recurring_status === "2" ? '#202529' : // black
                                                                                                recurringList?.recurring_status === "3" ? '#F93333' : // red
                                                                                                    'inherit'
                                                                                }}
                                                                            >
                                                                                {recurringList?.recurring_status === "1" ? 'Active' :
                                                                                    recurringList?.recurring_status === "2" ? 'InActive' :
                                                                                        recurringList?.recurring_status === "3" ? 'Stopped' : '--'}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="8" className='text-center'>No recurring invoices found.</td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                                <ReactPaginate
                                                    previousLabel="<"
                                                    nextLabel=">"
                                                    breakLabel="..."
                                                    pageCount={totalPagesRecurring}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={handlePageClickRecurring}
                                                    containerClassName="pagination justify-content-end align-items-center mt-2 pe-0"
                                                    pageClassName='page-item nowrap'
                                                    pageLinkClassName='page-link'
                                                    previousClassName='page-item'
                                                    previousLinkClassName='page-link'
                                                    nextClassName='page-item'
                                                    nextLinkClassName='page-link'
                                                    breakClassName='page-item'
                                                    breakLinkClassName='page-link'
                                                    activeClassName='active'
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="no-access-message">
                                            You are not authorized to access the resource.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Invoice_Details;