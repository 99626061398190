import React, { useState, useEffect, useMemo, useContext, useRef, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ClientTabs from "../../common/ClientTabs";
import AddTask from "./taskModal/AddTask";
import { http } from "../../http/http";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import { GlobalContext } from "../../../App";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { handlePermissionData, handlePermissionIdsData } from "../../http/help";
import Auth from "../../../auth/Auth";

const Task = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        setError,
        clearErrors,
        watch,
    } = useForm({ mode: "all" });

    const watchedStaffIds = watch("staff_id", []);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const storedId = JSON.parse(localStorage.getItem("clientId"));
    const [activeTab, setActiveTab] = useState("tasks");
    const [addTaskModal, setAddTaskModal] = useState(false);
    const [priorityList, setPriorityList] = useState([]);
    const [interactionList, setInteractionList] = useState([]);
    const [StatusList, setStatusList] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [editTaskModal, setEditTaskModal] = useState(false);
    const [showEditAssignOpen, setShowEditAssignOpen] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState("All");
    const [selectedName, setSelectedName] = useState("All");
    const [selectedAssignee, setSelectedAssignee] = useState("All");
    const [searchInput, setSearchInput] = useState("");
    const [assigneeSearchInput, setAssigneeSearchInput] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredTasks, setFilteredTasks] = useState(taskList);
    const [recurringTaskList, setRecurringTaskList] = useState([]);
    const [filteredRecurringTasks, setFilteredRecurringTasks] = useState(recurringTaskList);
    const [filteredNames, setFilteredNames] = useState([]);
    const [filteredAssignees, setFilteredAssignees] = useState([]);
    const [isTaskTypeOpen, setIsTaskTypeOpen] = useState(false);
    const [isAssigneeOpen, setIsAssigneeOpen] = useState(false);
    const [isPriorityOpen, setIsPriorityOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [editTaskDetail, setEditTaskDetail] = useState({
        documents: [],
    });
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [deleteImageIDs, setDeleteImageIDs] = useState([]);
    const [assigneeIds, setAssigneeIds] = useState([]);
    const userDataId = JSON.parse(localStorage.getItem("__assist_erp"));
    const [totalPages, setTotalPages] = useState(1);
    let taskClientLimit = 200;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRecurringPage, setCurrentRecurringPage] = useState(1);
    const [totalRecurringPages, setRecurringTotalPages] = useState(1);
    let recurringTaskLimit = 200;
    const [startDateRecurring, setStartDateRecurring] = useState(null);
    const [nextMonthDateRecurring, setNextMonthDateRecurring] = useState(null);
    const [lastMonthDateRecurring, setLastMonthDateRecurring] = useState(null);
    const [selectedStatusRecurringType, setSelectedStatusRecurringType] = useState(null);
    const [selectedTaskType, setSelectedTaskType] = useState(null);
    const [isStatusOpenRecurring, setIsStatusOpenRecurring] = useState(false);
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSubTask, setShowSubTask] = useState(false);
    const [showRecurringSubTask, setShowRecurringSubTask] = useState(false);
    const [options, setOptions] = useState([]); // Initially empty
    const [optionsRecurring, setOptionRecurring] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedRecurringOption, setSelectedRecurringOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRecurring, setIsOpenRecurring] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

    const [searchRecuriingTerm, setSearchRecurringTerm] = useState("");

    const [debouncedRecurringSearchTerm, setDebouncedRecurringSearchTerm] = useState(searchRecuriingTerm);
    const dropdownRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorRecurringMessage, setErrorRecurringMessage] = useState("");
    const [openDropdown, setOpenDropdown] = useState(null);
    const [openRecurringDropdown, setOpenRecurringDropdown] = useState(null);
    const [expandedTasks, setExpandedTasks] = useState([]);
    const [openDropdownStatusId, setOpenDropdownStatusId] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const displayAssignedToLabel = selectedAssignee === "All" ? "Assigned" : selectedAssignee;
    const [taskDetails, setTaskDetails] = useState({
        interaction_type_id: 0,
        priority_id: 0,
        status_task_id: 0,
    });
    const [timeFormats, setTimeFormats] = useState({
        start_time_format: editTaskDetail?.start_time_format || "AM",
        end_time_format: editTaskDetail?.end_time_format || "AM",
    });
    const isDropdownOpen = (taskId) => openDropdownStatusId === taskId;
    const initialStatusSelectedOption = StatusList.find((option) => option.id === taskDetails.status_task_id);
    const [dropdownState, setDropdownState] = useState({
        isStatusOpen: false,
        statusSelectedOption: initialStatusSelectedOption || null,
    });
    const [permission, setPermission] = useState([]);
    const [permissionIds, setPermissionIds] = useState([]);
    const userID = JSON.parse(localStorage.getItem("__assist_erp"));
    const [openTemplateModal, setOpenTemplateModal] = useState(false);
    const [openRecurringTemplateModal, setOpenRecurringTemplateModal] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [recurringTemplateList, setRecurringTemplateList] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [recurringSelectedTemplateId, setRecurringSelectedTemplateId] = useState("");

    // Handle template selection
    const handleTemplateChange = (e) => {
        setSelectedTemplateId(e.target.value); // Update the selected template ID
    };

    const handleRecurringTemplateChange = (e) => {
        setRecurringSelectedTemplateId(e.target.value); // Update the selected template ID
    };

    // Function to check if user has the permission to add tasks
    // const canAddTasks = () => {
    //     const hasAddPermission = permission?.permissions?.includes("task_create");
    //     const hasFullAccess = permissionIds?.every((id) => id.id !== userID?.user?.id);
    //     return hasAddPermission || hasFullAccess;
    // };

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchRecuriingTerm); // Set debounced search term after delay
        }, 300);

        return () => clearTimeout(handler); // Cleanup timeout on component unmount or input change
    }, [searchRecuriingTerm]);
    // Debounce search term (wait 300ms after user stops typing to update)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedRecurringSearchTerm(searchTerm); // Set debounced search term after delay
        }, 300);

        return () => clearTimeout(handler); // Cleanup timeout on component unmount or input change
    }, [searchTerm]);


    // Memoize options and precompute lowercased names for faster filtering
    const processedOptions = useMemo(() => {
        return options.map((option) => ({
            ...option,
            lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
        }));
    }, [options]);

    // Memoize the filtered options based on debounced search term
    // Memoize the filtered options based on debounced search term
    const filteredOptions = useMemo(() => {
        if (!debouncedSearchTerm) return processedOptions; // If no search term, return full list
        const lowerCaseSearchTerm = debouncedSearchTerm.toLowerCase(); // Convert search term to lowercase
        return processedOptions.filter(option =>
            option.lowerCaseName.includes(lowerCaseSearchTerm) // Filter based on the lowercased name
        );
    }, [debouncedSearchTerm, processedOptions]);

    // Debounce search term (wait 300ms after user stops typing to update)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedRecurringSearchTerm(searchRecuriingTerm); // Set debounced search term after delay
        }, 300);

        return () => clearTimeout(handler); // Cleanup timeout on component unmount or input change
    }, [searchRecuriingTerm]);

    // Memoize options and precompute lowercased names for faster filtering
    const processedRecurringOptions = useMemo(() => {
        return optionsRecurring.map((option) => ({
            ...option,
            lowerCaseName: option.name.toLowerCase(), // Preprocess to include a lowercase version for easier filtering
        }));
    }, [optionsRecurring]);

    // Memoize the filtered options based on debounced search term
    const filteredRecurringOptions = useMemo(() => {
        if (!debouncedRecurringSearchTerm) return processedRecurringOptions; // If no search term, return full list
        const lowerCaseRecurringSearchTerm = debouncedRecurringSearchTerm.toLowerCase(); // Convert search term to lowercase
        return processedRecurringOptions.filter(
            (option) => option.lowerCaseName.includes(lowerCaseRecurringSearchTerm) // Filter based on the lowercased name
        );
    }, [debouncedRecurringSearchTerm, processedRecurringOptions]);

    // Handle search input changes
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term with user input
    };

    const handleOptionClick = useCallback((option) => {
        setSelectedOption(option); // Set selected option
        setIsOpen(false); // Close the dropdown
        setSearchTerm(""); // Clear the search input

        // Save selected option to localStorage
        localStorage.setItem("selectedOption", JSON.stringify(option)); // Save both name and id
    }, []);

    const handleRecurringOptionClick = useCallback((option) => {
        setSelectedRecurringOption(option); // Set selected option
        setIsOpenRecurring(false); // Close the dropdown
        setSearchRecurringTerm(""); // Clear the search input

        // Save selected option to localStorage
        localStorage.setItem("selectedRecurringOption", JSON.stringify(option)); // Save both name and id
    }, []);

    // Handle search input changes
    const handleRecurringSearchChange = (e) => {
        setSearchRecurringTerm(e.target.value); // Update search term with user input
    };

    // Toggle the dropdown open/close state
    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState); // Toggle the dropdown state
    };

    const toggleRecurringDropdown = () => {
        setIsOpenRecurring((prevState) => !prevState);
    };
    const handleSelectToggle = () => {
        setShowCheckboxes((prevShow) => !prevShow);
    };

    const convertHexToRGBA = (hex, opacity) => {
        // Validate hex input
        if (!hex || typeof hex !== "string" || (hex.length !== 4 && hex.length !== 7)) {
            console.error("Invalid hex input:", hex);
            return `rgba(0, 0, 0, ${opacity})`; // Default to black with given opacity
        }

        let r = 0,
            g = 0,
            b = 0;

        // 3 digits hex (#RGB)
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits hex (#RRGGBB)
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    // Handle Drag End
    const handleDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredTasks(reorderedTasks);
    };

    // Handle Drag End
    const handleDragEndRecurring = (result) => {
        const { destination, source } = result;
        if (!destination) return; // Exit if dropped outside the list

        // Reorder tasks
        const reorderedTasks = Array.from(filteredRecurringTasks);
        const [removed] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, removed);

        setFilteredRecurringTasks(reorderedTasks);
    };

    const handleTabClick = (tab) => {
        setShowLoader(true);
        setActiveTab(tab);
        setTimeout(() => {
            setShowLoader(false);
        }, 1000); // Simulate a delay of 1 second
    };

    const handleButtonClick = () => {
        setShowLoader(true);
        setTimeout(() => {
            if (activeTab === "tasks") {
                if (Auth.innerPermission("task_create")) {
                    navigate("/admin/client/individual_task/add_task");
                } else {
                    toast.error("You are not authorized to access the resource");
                }
            } else {
                navigate("/admin/client/individual_task/recurring_add_task");
            }

            setShowLoader(false);
        }, 500);
    };

    const handleDropdownOptionClick = (option) => {
        setShowLoader(true);
        setShowDropdown(false);
        setTimeout(() => {
            if (activeTab === "tasks") {
                if (Auth.innerPermission("task_create")) {
                    if (option === "add_task") {
                        navigate("/admin/client/individual_task/add_task");
                    } else if (option === "add_subtask") {
                        setShowSubTask(true);
                    }
                } else {
                    toast.error("You are not authorized to access the resource");
                }
            } else if (activeTab === "recurring") {
                if (option === "add_recurring_task") {
                    navigate("/admin/client/individual_task/recurring_add_task");
                } else if (option === "add_recurring_subtask") {
                    setShowRecurringSubTask(true);
                }
            }
            setShowLoader(false);
        }, 500);
    };

    // const handleButtonClick = () => {
    //     setShowLoader(true);
    //     setTimeout(() => {
    //         if (activeTab === 'tasks') {
    //             if (canAddTasks) {
    //                 navigate('/admin/client/individual_task/add_task');
    //             } else {
    //                 toast.error('You do not have access to add tasks.');
    //             }
    //         } else {
    //             navigate('/admin/client/individual_task/recurring_add_task');
    //         }
    //         setShowLoader(false);
    //     }, 500);
    // };

    // const handleDropdownOptionClick = (option) => {
    //     setShowLoader(true);
    //     setShowDropdown(false);
    //     setTimeout(() => {
    //         if (activeTab === 'tasks') {
    //             if (canAddTasks) {
    //                 if (option === 'add_task') {
    //                     navigate('/admin/client/individual_task/add_task');
    //                 } else if (option === 'add_subtask') {
    //                     setShowSubTask(true);
    //                 }
    //             } else {
    //                 toast.error('You do not have access to add tasks.');
    //             }
    //         } else if (activeTab === 'recurring') {
    //             if (option === 'add_recurring_task') {
    //                 navigate('/admin/client/individual_task/recurring_add_task');
    //             } else if (option === 'add_recurring_subtask') {
    //                 setShowRecurringSubTask(true);
    //             }
    //         }
    //         setShowLoader(false);
    //     }, 500);
    // };

    const handleFieldChange = (fieldName, value) => {
        setTaskDetails((prevTaskDetails) => ({
            ...prevTaskDetails,
            [fieldName]: value,
        }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditTaskDetail((prevDetail) => ({
            ...prevDetail,
            custom_field: {
                ...prevDetail.custom_field,
                [name]: value,
            },
        }));
    };
    const handleFormatChange = (e) => {
        const { name, value } = e.target;
        setTimeFormats({ ...timeFormats, [name]: value });
    };
    const toggleAssignOpen = () => {
        setShowEditAssignOpen(!showEditAssignOpen);
    };
    const toggleAssignee = (id) => {
        if (assigneeIds.includes(id)) {
            setAssigneeIds((prevIds) => prevIds.filter((assigneeId) => assigneeId !== id));
        } else {
            setAssigneeIds((prevIds) => [...prevIds, id]);
        }
    };
    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    };
    // const uniqueNames = useMemo(() => [
    //     ...new Set(
    //         taskList?.map(task =>
    //             task.task_type === "3"
    //                 ? task.client_self?.name
    //                 : `${task.client?.first_name} ${task.client?.last_name}`
    //         )
    //     )
    // ], [taskList]);

    // const uniqueAssignees = useMemo(() => [
    //     ...new Set(
    //         taskList?.flatMap(task => task.assignees.map(assignee => assignee.first_name))
    //     )
    // ], [taskList]);

    // useEffect(() => {
    //     setFilteredNames(uniqueNames.filter(name => name?.toLowerCase().includes(searchInput.toLowerCase())));
    // }, [searchInput, uniqueNames]);

    // Memoized list of unique assignees
    const uniqueAssignees = useMemo(() => [...new Set(taskList?.flatMap((task) => task.assignees.map((assignee) => `${assignee.first_name} ${assignee.last_name}`)))], [taskList]);

    // Effect to filter assignees based on the search input
    useEffect(() => {
        setFilteredAssignees(uniqueAssignees.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssignees]);

    // Handler for the search input change
    const handleSearchInputChange = (e) => {
        setAssigneeSearchInput(e.target.value); // Update search input state with the entered value
    };

    useEffect(() => {
        setFilteredAssignees(uniqueAssignees.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssignees]);

    const uniqueNamesRecurring = useMemo(() => [...new Set(recurringTaskList.flatMap((recurringTask) => recurringTask.tasks.map((task) => (task.task_type === "3" ? task.client_self?.name : `${task.client?.first_name} ${task.client?.last_name}`))))], [recurringTaskList]);
    const uniqueAssigneesRecurring = useMemo(() => [...new Set(recurringTaskList.flatMap((recurringTask) => recurringTask.tasks.flatMap((task) => task.assignees.map((assignee) => assignee.first_name))))], [recurringTaskList]);

    useEffect(() => {
        setFilteredNames(uniqueNamesRecurring.filter((name) => name?.toLowerCase().includes(searchInput.toLowerCase())));
    }, [searchInput, uniqueNamesRecurring]);

    useEffect(() => {
        setFilteredAssignees(uniqueAssigneesRecurring.filter((assignee) => assignee.toLowerCase().includes(assigneeSearchInput.toLowerCase())));
    }, [assigneeSearchInput, uniqueAssigneesRecurring]);

    // MODAL
    const closeModal = () => {
        setShowSubTask(false);
    };
    const closeRecurringSubTaskModal = () => {
        setShowRecurringSubTask(false);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const handleSubTaskData = async () => {
        try {
            http(
                {
                    method: "GET",
                    // url: `client/outertask/getoutertasklisting`,
                    url: `client/task/${storedId}/tasklisting`,
                    isSecure: true,
                },
                (res) => {
                    setOptions(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    useEffect(() => {
        handleSubTaskData();
    }, []);

    const handleRecurringSubTaskData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `client/task/${storedId}/recurringtasklisting`,
                    isSecure: true,
                },
                (res) => {
                    setOptionRecurring(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };
    useEffect(() => {
        handleRecurringSubTaskData();
    }, []);

    /*add Filter */

    const handleDropdownSubTaskClick = (event) => {
        event.stopPropagation(); // Prevent the dropdown from closing the button action
        setShowDropdown(!showDropdown);
    };

    const handleDropdownClick = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenDropdown(dropdownName); // Open the clicked dropdown and close others
            // event.stopPropagation();
        }
    };
    const handleStatusClick = () => handleDropdownClick("status");
    const handlePriorityClick = () => handleDropdownClick("priority");
    const handleNameClick = () => handleDropdownClick("Assigned");

    const handleDropdownRecurringClick = (dropdownName) => {
        if (openRecurringDropdown === dropdownName) {
            setOpenRecurringDropdown(null); // Close dropdown if it's already open
        } else {
            setOpenRecurringDropdown(dropdownName); // Open the clicked dropdown and close others
        }
    };

    const handleStatusRecurringClick = () => handleDropdownRecurringClick("status");
    const handleTaskTypeClick = () => handleDropdownRecurringClick("taskType");

    const handlePriorityOptionClick = (value) => {
        setSelectedPriority(value);
        setIsPriorityOpen(false);
    };
    const handleStatusOptionClick = (value) => {
        setSelectedStatus(value);
        setIsStatusOpen(false);
    };

    const handleAssigneeOptionClick = (value) => {
        setSelectedAssignee(value);
        setIsAssigneeOpen(false);
        setOpenDropdown(null);
    };

    // const handleNameOptionClick = (value) => {
    //     setSelectedName(value);
    //     setIsNameOpen(false);
    // };

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const handlePageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentPage(selectedPage);
    };
    const handleRecurringPageClick = (data) => {
        const selectedPage = data.selected + 1;
        setCurrentRecurringPage(selectedPage);
    };
    const handleStatusRecurringOptionClick = (value) => {
        setSelectedStatusRecurringType(value);
        setIsStatusOpenRecurring(false);
    };
    const handleTaskTypeOptionClick = (value) => {
        setSelectedTaskType(value);
        setIsTaskTypeOpen(false);
    };

    /* end Filter Function */

    const handlePriorityData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `priority/listing`,
                    isSecure: true,
                },
                (res) => {
                    setPriorityList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    const handleInteractionData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `interaction_type/listing`,
                    isSecure: true,
                },
                (res) => {
                    setInteractionList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    const handleStatusData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `status_task/listing`,
                    isSecure: true,
                },
                (res) => {
                    setStatusList(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    const handleAssisgnData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `staff/listing`,
                    isSecure: true,
                },
                (res) => {
                    setAssignData(res?.data?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
        }
    };

    const handleTaskList = (page) => {
        setShowLoader(true);
        try {
            const userId = userDataId?.user?.id;
            const url = `client/task/${storedId}/listing?page=${page}&limit=${taskClientLimit}`;
            http(
                {
                    method: "GET",
                    url: url,
                    isSecure: true,
                },
                (res) => {
                    const tasks = res?.data?.data;
                    setTaskList(tasks);
                    setTotalPages(res?.data?.last_page);
                    setShowLoader(false);

                    // Initialize selectedStatuses with the current statuses of fetched tasks
                    const initialStatuses = tasks.reduce((acc, task) => {
                        acc[task.id] = task.statustask; // Use the status task object from each task
                        return acc;
                    }, {});
                    setSelectedStatuses(initialStatuses);
                }
            );
        } catch (error) {
            console.error("Error fetching staff data:", error);
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleTaskList(currentPage);
    }, [currentPage]);

    // Function to update the subtask status
    const updateSubTaskStatusType = (StatusId, taskId) => {
        if (!taskId) {
            console.error("Task ID is required but is undefined inside updateSubTaskStatusType.");
            toast.error("Task ID is missing.");
            return;
        }

        try {
            http(
                {
                    method: "POST",
                    url: `task/update_status/${taskId}`,
                    isSecure: true,
                    body: {
                        status_task_id: StatusId,
                    },
                },
                (res) => {
                    toast.success(res.message || "Status updated successfully.");
                    handleTaskList();
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Unexpected error updating status:", error);
            toast.error("Unexpected error occurred.");
        }
    };

    const filteredOptionsStatus = StatusList.filter((option) => option.name.toLowerCase().includes((dropdownState.statusSearch || "").toLowerCase()));

    const handleRecurringTaskList = async (page) => {
        setShowLoader(true);
        try {
            http(
                {
                    method: "GET",
                    url: `client/task/${storedId}/recurring-listing?page=${page}&limit=${recurringTaskLimit}`,
                    isSecure: true,
                },
                (res) => {
                    setRecurringTaskList(res?.data?.data);
                    setRecurringTotalPages(res?.data?.last_page); // Set total pages from the response
                    setShowLoader(false);
                }
            );
        } catch (error) {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        handleRecurringTaskList(currentRecurringPage);
    }, [currentRecurringPage]);

    const deleteImage = async (documentId, isNew = false) => {
        if (isNew) {
            setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== documentId));
        } else {
            setDeleteImageIDs((prevState) => [...prevState, documentId]);
            setEditTaskDetail((prevState) => ({
                ...prevState,
                documents: prevState.documents.filter((doc) => doc.id !== documentId),
            }));
        }
    };
    const updateTaskHandle = async () => {
        try {
            const formData = new FormData();
            formData.append("name", editTaskDetail?.name);
            formData.append("description", editTaskDetail?.description);
            formData.append("interaction_type_id", taskDetails.interaction_type_id);
            formData.append("priority_id", taskDetails.priority_id);
            formData.append("status_task_id", taskDetails.status_task_id);
            formData.append("start_date", editTaskDetail?.start_date);
            formData.append("start_time", editTaskDetail?.start_time);
            formData.append("start_time_format", timeFormats?.start_time_format);
            formData.append("end_date", editTaskDetail?.end_date);
            formData.append("end_time", editTaskDetail?.end_time);
            formData.append("end_time_format", timeFormats?.end_time_format);
            formData.append("estimation_time", editTaskDetail?.estimation_time);
            formData.append("time_spent", editTaskDetail?.time_spent);
            formData.append("id", editTaskDetail?.id);
            formData.append("client_id", editTaskDetail?.client_id);
            formData.append("task_type", editTaskDetail?.task_type);
            formData.append("image_delete_ids", deleteImageIDs.join(","));

            assigneeIds.forEach((id) => {
                formData.append("staff_id[]", id);
            });
            selectedFiles.forEach((file) => {
                formData.append("file[]", file);
            });
            // Append custom fields
            Object.keys(editTaskDetail.custom_field || {}).forEach((key) => {
                formData.append(`custom_field[${key}]`, editTaskDetail.custom_field[key]);
            });

            http(
                {
                    method: "POST",
                    url: `client/task/update/${storedId}/${editTaskDetail?.id}`,
                    isSecure: true,
                    body: formData,
                    contentType: "multipart/form-data",
                },
                (res) => {
                    setSelectedFiles([]);
                    setDeleteImageIDs([]);
                    setEditTaskModal(false);
                    handleTaskList();
                }
            );
        } catch (error) {
            console.error("Error updating task:", error);
        }
    };
    useEffect(() => {
        handleInteractionData();
        handlePriorityData();
        handleStatusData();
        handleAssisgnData();
        handleTaskList();
    }, []);

    useEffect(() => {
        if (editTaskDetail) {
            setTaskDetails((prevTaskDetails) => ({
                ...prevTaskDetails,
                interaction_type_id: editTaskDetail?.interactiontype?.id || 0,
                priority_id: editTaskDetail?.priority?.id || 0,
                status_task_id: editTaskDetail?.statustask?.id || 0,
            }));
        }
    }, [editTaskDetail?.interactiontype?.id, editTaskDetail?.priority?.id, editTaskDetail?.statustask?.id]);

    useEffect(() => {
        if (editTaskDetail) {
            setTimeFormats((prevTimeFormats) => ({
                ...prevTimeFormats,
                start_time_format: editTaskDetail?.start_time_format || "AM",
                end_time_format: editTaskDetail?.end_time_format || "AM",
            }));
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);

    useEffect(() => {
        if (editTaskDetail && editTaskDetail.assignees) {
            const ids = editTaskDetail.assignees.map((assignee) => assignee.id);
            setAssigneeIds(ids);
        }
    }, [editTaskDetail?.start_time_format, editTaskDetail?.end_time_format]);

    useEffect(() => {
        let filtered = taskList;

        // if (selectedName !== "All") {
        //     filtered = filtered.filter(task =>
        //         task.task_type === "3"
        //             ? task.client_self?.name === selectedName
        //             : `${task.client?.first_name} ${task.client?.last_name}` === selectedName
        //     );
        // }

        if (selectedAssignee !== "All") {
            filtered = filtered.filter((task) =>
                // Check if any assignee's full name matches the selected assignee
                task.assignees.some((assignee) => `${assignee.first_name} ${assignee.last_name}` === selectedAssignee)
            );
        }

        if (selectedPriority !== "All") {
            filtered = filtered.filter((task) => task.priority?.id == selectedPriority);
        }

        if (selectedStatus !== "All") {
            filtered = filtered.filter((task) => task.statustask?.id == selectedStatus);
        }

        if (startDate) {
            const selectedDate = moment(startDate).startOf("day");
            filtered = filtered.filter((task) => {
                const taskDate = moment(task.created_at).startOf("day");
                return taskDate.isSame(selectedDate, "day");
            });
        }

        setFilteredTasks(filtered);
    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, startDate, endDate, taskList]);

    useEffect(() => {
        let filtered = recurringTaskList;

        if (selectedStatusRecurringType && selectedStatusRecurringType !== "All") {
            filtered = filtered.filter((task) => task.reccuring_status == selectedStatusRecurringType);
        }

        // Apply task type filter
        if (selectedTaskType && selectedTaskType !== "All") {
            filtered = filtered.filter((task) => task.reccuring_repeat == selectedTaskType);
        }

        if (startDateRecurring) {
            const selectedDate = moment(startDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_start_date).startOf("day");

                    return taskDate.isSame(selectedDate, "day");
                })
            );
        }

        // Filter by the next month date
        if (nextMonthDateRecurring) {
            const nextMonthSelectedDate = moment(nextMonthDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_start_next_date).startOf("day");

                    return taskDate.isSame(nextMonthSelectedDate, "day"); // Exact match for next month date
                })
            );
        }

        // Filter by the last month date
        if (lastMonthDateRecurring) {
            const lastMonthSelectedDate = moment(lastMonthDateRecurring).startOf("day");

            filtered = filtered.filter((recurringTask) =>
                recurringTask.tasks.some((task) => {
                    const taskDate = moment(recurringTask.reccuring_end_date).startOf("day"); // Convert task end date to a moment object at start of day

                    return taskDate.isSame(lastMonthSelectedDate, "day"); // Exact match for the last month date
                })
            );
        }

        setFilteredRecurringTasks(filtered);
    }, [selectedAssignee, selectedName, selectedPriority, selectedStatus, selectedTaskType, selectedStatusRecurringType, startDateRecurring, nextMonthDateRecurring, lastMonthDateRecurring, recurringTaskList]);
    // Handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Validation: Check if an option has been selected
        if (!selectedOption) {
            setErrorMessage("Please select at least one option."); // Set error message if no option is selected
            return; // Stop the form submission and do not navigate
        }

        // If validation passes, proceed to navigate and close the modal
        setErrorMessage(""); // Clear any previous error message
        navigate("/admin/client/individual_task/add_task?type=subtask"); // Change the route after submission
        setShowSubTask(false); // Optionally close the modal after navigation
    };

    const handleRecurringFormSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        // Validation: Check if an option has been selected
        if (!selectedRecurringOption) {
            setErrorRecurringMessage("Please select at least one option."); // Set error message if no option is selected
            return; // Stop the form submission and do not navigate
        }

        // If validation passes, proceed to navigate and close the modal
        setErrorRecurringMessage(""); // Clear any previous error message
        navigate("/admin/client/individual_task/recurring_add_task?type=recurring_subtask"); // Change the route after submission
        setShowRecurringSubTask(false); // Optionally close the modal after navigation
    };

    // Function to toggle the dropdown visibility
    const toggleStatusDropdown = (taskId) => {
        setOpenDropdownStatusId((prevId) => (prevId === taskId ? null : taskId));
    };

    // Function to handle status selection
    const handleStatusSelect = (option, taskId) => {
        if (!taskId) {
            toast.error("Task ID is missing. Please select a task first.");
            return;
        }

        // Close the dropdown
        setOpenDropdownStatusId(null);

        // Update the selected status state for the specific task or subtask
        setSelectedStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: option, // Update the selected status for the task/subtask by its ID
        }));

        // Call the function to update the subtask status in the backend or state
        updateSubTaskStatusType(option.id, taskId);
    };
    // Function to handle the toggling of subtasks visibility
    const handleRowAppend = (taskId) => {
        setExpandedTasks((prev) => (prev.includes(taskId) ? prev.filter((id) => id !== taskId) : [...prev, taskId]));
    };

    // Recursive component to render tasks and subtasks
    const TaskRow = ({ task, index, level = 0, className }) => {
        const hasSubtasks = task.subtasks && task.subtasks.length > 0;
        const isExpanded = expandedTasks.includes(task.id);

        // Helper function to get the selected status name for a task
        const getSelectedStatus = (taskId) => selectedStatuses[taskId]?.name || task.statustask?.name || "Select Status";

        return (
            <>
                <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                    {(provided) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                                if (Auth.innerPermission("task_view")) {
                                    navigate(`/admin/client/individual_task/individual_task_detail/${task.id}`);
                                } else {
                                    toast.error("You do not have permission to view this task.");
                                }
                            }}
                            className={className}
                        >

                            <td>
                                {hasSubtasks ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="9.263"
                                        viewBox="0 0 16 9.263"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRowAppend(task.id);
                                        }}
                                        style={{
                                            transform: isExpanded ? "rotate(360deg)" : "rotate(270deg)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <g id="arrow-right" transform="translate(16 -107.789) rotate(90)">
                                            <path id="Path_4085" data-name="Path 4085" d="M109.052,16a1.263,1.263,0,0,1-.893-2.156L114,8l-5.844-5.844A1.263,1.263,0,0,1,109.945.37l6.737,6.737a1.263,1.263,0,0,1,0,1.786l-6.737,6.737a1.256,1.256,0,0,1-.893.37Z" fill="#202529" />
                                        </g>
                                    </svg>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td>{task.name || "--"}</td>
                            <td>{task.task_type === "1" ? "Individual" : task.task_type === "2" ? "Business" : "Self"}</td>

                            <td>
                                {Array.isArray(task.assignees) && task.assignees.length > 0 ? (
                                    <div className="d-flex align-items-center justify-content-start">
                                        <img
                                            src={require("../../../assets/images/user-pic.png")}
                                            alt="Assignee"
                                            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                        />
                                        <label className="w-fit ms-2">{task.assignees[0]?.first_name || "--"}</label>
                                        {task.assignees.length > 1 && (
                                            <div
                                                className="allname rounded-circle ms-2 d-flex justify-content-center align-items-center position-relative"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    backgroundColor: "#004fc5",
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                +{task.assignees.length - 1}
                                                <div
                                                    className="tooltip-box"
                                                    style={{
                                                        position: "absolute",
                                                        top: "30px",
                                                        left: "0",
                                                        width: "fit-content",
                                                        backgroundColor: "#333",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        borderRadius: "4px",
                                                        fontSize: "12px",
                                                        whiteSpace: "normal",
                                                        zIndex: 10,
                                                        maxHeight: "fit-content",
                                                        overflowY: "auto",
                                                    }}
                                                >
                                                    {task.assignees
                                                        .slice(1)
                                                        .map((assignee) => `${assignee.first_name} ${assignee.last_name}`)
                                                        .join(", ")}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <span>--</span> // Show `--` when no assignees or empty array
                                )}
                            </td>
                            <td>{moment(task.created_at).format("DD MMM, YYYY")}</td>
                            <td>
                                {task.priority && (
                                    <span
                                        style={{
                                            backgroundColor: convertHexToRGBA(task.priority.color_code, 0.2),
                                            padding: "3px 10px",
                                            borderRadius: "8px",
                                            textAlign: "center",
                                            width: "fit-content",
                                            fontSize: "14px",
                                            color: task.priority.color_code,
                                        }}
                                    >
                                        {task.priority.name}
                                    </span>
                                )}
                            </td>
                            <td className="dropposition status-field">
                                <div
                                    className="statusbtn-new"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleStatusDropdown(task.id);
                                    }}
                                >
                                    <label>{getSelectedStatus(task.id)} </label>
                                    <svg className={`${isDropdownOpen(task.id) ? "" : "rot"}`} xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                    </svg>
                                </div>
                                <div className={`st-dropdown mainshadow p-2 rounded-md bg-white ${isDropdownOpen(task.id) ? "show" : ""}`} onClick={(e) => e.stopPropagation()}>
                                    <ul className="p-0">
                                        {filteredOptionsStatus.map((option) => (
                                            <li
                                                key={option.id}
                                                className="fs-14"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStatusSelect(option, task.id);
                                                }}
                                            >
                                                {option.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    )}
                </Draggable>

                {/* Recursively Render Subtasks if Parent Task is Expanded */}
                {isExpanded && hasSubtasks && task.subtasks.map((subtask, subIndex) => <TaskRow key={subtask.id} task={subtask} index={subIndex} level={level + 1} className={getSubtaskClassName(level + 1)} />)}
            </>
        );
    };

    const RecurringTaskRow = ({ recurringTask, task, index, level = 0, className }) => {
        if (!recurringTask || !task) {
            return null; // If recurringTask or task is undefined, skip rendering.
        }

        const hasSubtasks = task.subtasks && task.subtasks.length > 0;
        const isExpanded = expandedTasks.includes(task.id);

        // Helper function to get the selected status name for a task
        const getSelectedStatus = (taskId) => selectedStatuses[taskId]?.name || task.statustask?.name || "Select Status";

        return (
            <>
                <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                    {(provided) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // onClick={() => navigate(`/admin/client/individual_task/individual_recurring_task_detail/${task.id}`)}
                            onClick={() => {
                                if (Auth.innerPermission("task_view")) {
                                    navigate(`/admin/client/individual_task/individual_recurring_task_detail/${task.id}`);
                                } else {
                                    toast.error("You do not have permission to view this task.");
                                }
                            }}
                            className={className}

                        >
                            <td>
                                {hasSubtasks ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="9.263"
                                        viewBox="0 0 16 9.263"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRowAppend(task.id);
                                        }}
                                        style={{
                                            transform: isExpanded ? "rotate(360deg)" : "rotate(270deg)",
                                            transition: "transform 0.3s ease",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <g id="arrow-right" transform="translate(16 -107.789) rotate(90)">
                                            <path id="Path_4085" data-name="Path 4085" d="M109.052,16a1.263,1.263,0,0,1-.893-2.156L114,8l-5.844-5.844A1.263,1.263,0,0,1,109.945.37l6.737,6.737a1.263,1.263,0,0,1,0,1.786l-6.737,6.737a1.256,1.256,0,0,1-.893.37Z" fill="#202529" />
                                        </g>
                                    </svg>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td>{recurringTask?.reccuring_unique_id}</td>
                            <td>{task.name}</td>
                            <td>{recurringTask.reccuring_repeat === "1" ? "Weekly" : recurringTask.reccuring_repeat === "2" ? "Monthly" : recurringTask.reccuring_repeat === "3" ? "Half Yearly" : recurringTask.reccuring_repeat === "4" ? "Yearly" : recurringTask.reccuring_repeat === "5" ? "Daily" : ""}</td>
                            <td>{moment(recurringTask?.reccuring_start_date).format("DD MMM, YYYY")}</td>
                            <td>{moment(recurringTask?.reccuring_start_next_date).format("DD MMM, YYYY")}</td>
                            <td>{recurringTask.reccuring_expire === "1" || recurringTask.reccuring_end_date === null ? "--" : recurringTask.reccuring_expire === "0" ? <span>{moment(recurringTask?.reccuring_end_date).format("DD MMM, YYYY")}</span> : ""}</td>
                            <td>{recurringTask.reccuring_expire === "0" ? "Expire" : recurringTask.reccuring_expire === "1" ? "Never Expire" : "--"}</td>
                            <td>
                                <span
                                    className="active"
                                    style={{
                                        backgroundColor:
                                            recurringTask?.reccuring_status === "1"
                                                ? "#D5E2F6" // light green
                                                : recurringTask?.reccuring_status === "2"
                                                    ? "#F1F1F1" // light gray
                                                    : recurringTask?.reccuring_status === "3"
                                                        ? "#FED6D6" // light red
                                                        : "transparent",
                                        color:
                                            recurringTask?.reccuring_status === "1"
                                                ? "#024FC5" // green
                                                : recurringTask?.reccuring_status === "2"
                                                    ? "#202529" // black
                                                    : recurringTask?.reccuring_status === "3"
                                                        ? "#F93333" // red
                                                        : "inherit",
                                    }}
                                >
                                    {recurringTask?.reccuring_status === "1" ? "active" : recurringTask?.reccuring_status === "2" ? "inactive" : recurringTask?.reccuring_status === "3" ? "Stopped" : ""}
                                </span>
                            </td>
                        </tr>
                    )}
                </Draggable>

                {/* Recursively Render Subtasks if Parent Task is Expanded */}
                {isExpanded && hasSubtasks && task.subtasks.map((subtask, subIndex) => <RecurringTaskRow key={subtask.id} task={subtask} recurringTask={recurringTask} index={subIndex} level={level + 1} className={getSubtaskClassName(level + 1)} />)}
            </>
        );
    };

    // Helper function to determine class name based on level
    const getSubtaskClassName = (level) => {
        switch (level) {
            case 1:
                return "subtask-row"; // First level subtasks
            case 2:
                return "subtask-sub-row"; // Second level subtasks
            case 3:
                return "subtask-sub-sub-row"; // Third level subtasks
            default:
                return "subtask-sub-sub-row"; // Apply the deepest level styling or adjust as needed
        }
    };

    const templateListData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `templates-individual`,
                    isSecure: true,
                },
                (res) => {
                    setTemplateList(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleTemplateData = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (selectedTemplateId === "" || selectedTemplateId === "0") {
            toast.error("Please select a valid template.");
            return;
        }

        try {
            http(
                {
                    method: "GET",
                    url: `particulartemplate/${selectedTemplateId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    navigate("/admin/client/individual_task/add_task", { state: { templateData: res?.data } });
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleTemplateModal = () => {
        setOpenTemplateModal(false);
    };
    const handleOpenModal = () => {
        setOpenTemplateModal(true);
        templateListData();
    };

    const templateRecurringListData = async () => {
        try {
            http(
                {
                    method: "GET",
                    url: `templates-individual-rec`,
                    isSecure: true,
                },
                (res) => {
                    setRecurringTemplateList(res?.data);
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };
    const handleRecurringTemplateData = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (recurringSelectedTemplateId === "" || recurringSelectedTemplateId === "0") {
            toast.error("Please select a valid template.");
            return;
        }

        try {
            http(
                {
                    method: "GET",
                    url: `particulartemplate/${recurringSelectedTemplateId}`,
                    isSecure: true,
                },
                (res) => {
                    toast.success(res.message);
                    navigate("/admin/client/individual_task/recurring_add_task", { state: { templateData: res?.data } });
                },
                (error) => {
                    console.error("Error updating status:", error);
                    toast.error("Failed to update status.");
                }
            );
        } catch (error) {
            console.error("Error fetching permission IDs", error);
        }
    };

    const handleRecurringOpenModal = () => {
        setOpenRecurringTemplateModal(true);
        templateRecurringListData();
    };

    const handleRecurringTemplateModal = () => {
        setOpenRecurringTemplateModal(false);
    };

    useEffect(() => {
        // Fetch permission data when the component mounts
        handlePermissionData(setPermission);
        handlePermissionIdsData(setPermissionIds);
    }, []);

    return (
        <div className="right_section font-poppins">
            <div className="clients-detail-area py-3">
                <ClientTabs />
                <div className="tasks-area mt-4 align-items-center">
                    <div className="row tabs-content mt-2">
                        <div className="tab-content p-0">
                            <div className="tab-pane fade show active" id="individual">
                                <div className="row">
                                    {
                                        activeTab === "tasks" ? (
                                            <div className="col-md-4 left_head">
                                                <h4 className="fs-20 fw-semibold"> Tasks</h4>
                                            </div>
                                        ) : (
                                            <div className="col-md-4 left_head">
                                                <h4 className="fs-20 fw-semibold">Recurring Tasks</h4>
                                            </div>
                                        )
                                    }
                                    <div className="row right_head p-0">
                                        <div className="col-md-6 client-tabs">
                                            <ul className="nav nav-tabs border-0">
                                                <li className="nav-item me-2 mt-2">
                                                    <button data-bs-toggle="tab" className={`nav-link ${activeTab === "tasks" ? "active" : ""}`} onClick={() => handleTabClick("tasks")}>
                                                        Tasks
                                                    </button>
                                                </li>
                                                <li className="nav-item me-2 mt-2">
                                                    <button data-bs-toggle="tab" className={`nav-link ${activeTab === "recurring" ? "active" : ""}`} onClick={() => handleTabClick("recurring")}>
                                                        Recurring Tasks
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 right_head d-flex align-items-center flex-wrap justify-content-end gap-2">
                                            {activeTab === "tasks" && (
                                                <div className="btn-trans">
                                                    <button className="transbtn" onClick={handleOpenModal}>Create From Template</button>
                                                </div>
                                            )}
                                            {activeTab === "recurring" && (
                                                <div className="btn-trans">
                                                    <button className="transbtn" onClick={handleRecurringOpenModal}>
                                                        Create From Template recurring
                                                    </button>
                                                </div>
                                            )}
                                            <div className="add-btn">
                                                <div className="add-btn-icon">
                                                    <button
                                                        className="add-new-button"
                                                        onClick={handleButtonClick}
                                                        disabled={showLoader} // Disable button while loading
                                                    >
                                                        <span>Add New Task</span>
                                                        {activeTab === "tasks" && (
                                                            <>
                                                                <span className="divider-line mx-2"></span>
                                                                <i className="fa fa-caret-down" onClick={handleDropdownSubTaskClick}></i>
                                                            </>
                                                        )}
                                                        {activeTab === "recurring" && (
                                                            <>
                                                                <span className="divider-line mx-2"></span>
                                                                <i className="fa fa-caret-down" onClick={handleDropdownSubTaskClick}></i>
                                                            </>
                                                        )}
                                                    </button>
                                                    {showLoader && <div className="loader">Loading...</div>}
                                                    {showDropdown && activeTab === "tasks" && (
                                                        <div className="dropdown">
                                                            <div onClick={() => handleDropdownOptionClick("add_task")}>Add New Task</div>
                                                            <div onClick={() => handleDropdownOptionClick("add_subtask")}>Add Subtask</div>
                                                        </div>
                                                    )}
                                                    {/* Dropdown for Recurring Tasks */}
                                                    {showDropdown && activeTab === "recurring" && (
                                                        <div className="dropdown">
                                                            <div onClick={() => handleDropdownOptionClick("add_recurring_task")}>Add Task</div>
                                                            <div onClick={() => handleDropdownOptionClick("add_recurring_subtask")}>Add Subtask</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex items-start justify-content-end gap-3 mt-4 flex-wrap">
                                        {activeTab === "tasks" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleNameClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">{displayAssignedToLabel}</label>

                                                        <span className="dropdown__icon">
                                                            {openDropdown === "Assigned" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openDropdown === "Assigned" ? "dropdown__options--open" : ""}`} onClick={stopPropagation}>
                                                        <input
                                                            type="text"
                                                            value={assigneeSearchInput} // Bind the input value to the search state
                                                            onChange={handleSearchInputChange} // Handle input change
                                                            onMouseDown={stopPropagation}
                                                            placeholder="Search"
                                                        />
                                                        <div key="all-name" className="dropdown__option" onClick={() => handleAssigneeOptionClick("All")}>
                                                            All
                                                        </div>
                                                        {filteredAssignees.map((name) => (
                                                            <div key={name} className="dropdown__option" onClick={() => handleAssigneeOptionClick(name)}>
                                                                {name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleTaskTypeClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedTaskType === "All" ? "Type" : selectedTaskType === "All" ? "All" : selectedTaskType === "1" ? "Weekly" : selectedTaskType === "2" ? "Monthly" : selectedTaskType === "3" ? "Half Monthly" : selectedTaskType === "4" ? "Yearly" : selectedTaskType === "5" ? "Day" : "Type"}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {
                                                                // isTaskTypeOpen ?
                                                                openRecurringDropdown === "taskType" ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                        <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                    </svg>
                                                                )
                                                            }
                                                        </span>
                                                    </div>
                                                    <div
                                                        // className={`dropdown__options ${isTaskTypeOpen ? 'dropdown__options--open' : ''}`}
                                                        className={`dropdown__options ${openRecurringDropdown === "taskType" ? "dropdown__options--open" : ""}`}
                                                    >
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("All")}>
                                                            All
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("1")}>
                                                            Weekly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("2")}>
                                                            Monthly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("3")}>
                                                            Half Monthly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("4")}>
                                                            Yearly
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleTaskTypeOptionClick("5")}>
                                                            Day
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {activeTab === "tasks" && (
                                            <div className="drop_box datepick">
                                                <div className="date-picker-wrapper">
                                                    <DatePicker className="dropdown fs-14 date-picker-input" selected={startDate} onChange={(date) => setStartDate(date)} isClearable={true} placeholderText="Start Date" />
                                                    {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                    {!startDate && (
                                                        <span className="calendar-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="drop_box datepick">
                                                <div className="date-picker-wrapper">
                                                    <DatePicker className="dropdown fs-14 date-picker-input" selected={startDateRecurring} onChange={(date) => setStartDateRecurring(date)} isClearable={true} placeholderText="Start Date" />
                                                    {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                    {!startDateRecurring && (
                                                        <span className="calendar-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "tasks" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handlePriorityClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">{selectedPriority === "All" ? "Priority" : priorityList.find((option) => option.id == selectedPriority)?.name || "Priority"}</label>
                                                        <span className="dropdown__icon">
                                                            {openDropdown === "priority" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openDropdown === "priority" ? "dropdown__options--open" : ""}`}>
                                                        <div key="all-priority" className="dropdown__option" onClick={() => handlePriorityOptionClick("All")}>
                                                            All
                                                        </div>
                                                        {priorityList.map((option) => (
                                                            <div key={option.id} className="dropdown__option" onClick={() => handlePriorityOptionClick(option.id)}>
                                                                {option.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="drop_box datepick">
                                                <div className="date-picker-wrapper">
                                                    <DatePicker className="dropdown fs-14 date-picker-input" selected={nextMonthDateRecurring} onChange={(date) => setNextMonthDateRecurring(date)} isClearable={true} placeholderText="Next Month Date" />
                                                    {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                    {!nextMonthDateRecurring && (
                                                        <span className="calendar-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" ? (
                                            <div className="drop_box datepick">
                                                <div className="date-picker-wrapper">
                                                    <DatePicker className="dropdown fs-14 date-picker-input" selected={lastMonthDateRecurring} onChange={(date) => setLastMonthDateRecurring(date)} isClearable={true} placeholderText="End Month Date" />
                                                    {/* Conditionally render the calendar icon based on whether a date is selected */}
                                                    {!lastMonthDateRecurring && (
                                                        <span className="calendar-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1zm1-1h12a1 1 0 0 1 1 1v1H1V4a1 1 0 0 1 1-1zm3.5 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 3 8z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null // Show nothing when activeTab is "recurring" or anything else
                                        }

                                        {activeTab === "tasks" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">{selectedStatus === "All" ? "Status" : StatusList.find((option) => option.id == selectedStatus)?.name || "Status"}</label>
                                                        <span className="dropdown__icon">
                                                            {openDropdown === "status" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openDropdown === "status" ? "dropdown__options--open" : ""}`}>
                                                        <div key="all-status" className="dropdown__option" onClick={() => handleStatusOptionClick("All")}>
                                                            All
                                                        </div>
                                                        {StatusList.map((option) => (
                                                            <div key={option.id} className="dropdown__option" onClick={() => handleStatusOptionClick(option.id)}>
                                                                {option.name}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {activeTab === "recurring" && (
                                            <div className="drop_box">
                                                <div className="dropdown" onClick={handleStatusRecurringClick}>
                                                    <div className="dropdown__selected">
                                                        <label className="fs-14">
                                                            {/* Display "Type" if no type is selected; otherwise, show the selected type */}
                                                            {selectedStatusRecurringType === "All" ? "Status" : selectedStatusRecurringType === "All" ? "All" : selectedStatusRecurringType === "1" ? "Active" : selectedStatusRecurringType === "2" ? "InActive" : selectedStatusRecurringType === "3" ? "Stopped" : "Status"}
                                                        </label>
                                                        <span className="dropdown__icon">
                                                            {openRecurringDropdown === "status" ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1172.27c-.047.039-.244.213-.4.37a16.943,16.943,0,0,0-3.165,4.566,3.44,3.44,0,0,0-.268.921,1.452,1.452,0,0,0,.173.708,1.417,1.417,0,0,0,.708.63,6.241,6.241,0,0,0,.842.2,19.028,19.028,0,0,0,2.992.189,22.385,22.385,0,0,0,2.905-.165,6.826,6.826,0,0,0,1.047-.268,1.475,1.475,0,0,0,.567-.512,1.415,1.415,0,0,0,.213-.732v-.047a4.415,4.415,0,0,0-.323-1.039,16.914,16.914,0,0,0-3.125-4.456,4.013,4.013,0,0,0-.433-.386,1.443,1.443,0,0,0-.834-.268,1.475,1.475,0,0,0-.9.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.447" height="7.875" viewBox="0 0 9.447 7.875">
                                                                    <path id="drop_down" d="M1661.834,1179.579c-.047-.039-.244-.213-.4-.37a16.943,16.943,0,0,1-3.165-4.566,3.44,3.44,0,0,1-.268-.921,1.452,1.452,0,0,1,.173-.708,1.417,1.417,0,0,1,.708-.63,6.241,6.241,0,0,1,.842-.2,19.028,19.028,0,0,1,2.992-.189,22.385,22.385,0,0,1,2.905.165,6.826,6.826,0,0,1,1.047.268,1.475,1.475,0,0,1,.567.512,1.415,1.415,0,0,1,.213.732v.047a4.415,4.415,0,0,1-.323,1.039,16.914,16.914,0,0,1-3.125,4.456,4.013,4.013,0,0,1-.433.386,1.443,1.443,0,0,1-.834.268,1.475,1.475,0,0,1-.9-.291Z" transform="translate(-1658 -1171.987)" fill="#212529" />
                                                                </svg>
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className={`dropdown__options ${openRecurringDropdown === "status" ? "dropdown__options--open" : ""}`}>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("All")}>
                                                            All
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("1")}>
                                                            Active
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("2")}>
                                                            InActive
                                                        </div>
                                                        <div className="dropdown__option" onClick={() => handleStatusRecurringOptionClick("3")}>
                                                            Stopped
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {activeTab === "tasks" && (
                                        Auth.innerPermission("task_list") ? (
                                            <>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <Droppable droppableId="recurringTaskList">
                                                        {(provided) => (
                                                            <div className="col-md-12 table_part mt-2 p-0" ref={provided.innerRef} {...provided.droppableProps}>
                                                                <div className="table-responsive subtask-table">
                                                                    <table className="w-100">
                                                                        <thead className="pb-2">
                                                                            <tr>
                                                                                {/* <th className="fs-14 t_blue ps-0">Select</th> */}
                                                                                <th></th>
                                                                                <th>Task</th>
                                                                                <th>Type</th>
                                                                                <th>Assigned To</th>
                                                                                {/* <th>Created By</th> */}
                                                                                <th>Created On</th>
                                                                                <th>Priority</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {filteredTasks &&
                                                                                filteredTasks?.map((individualTask, index) => (
                                                                                    <React.Fragment key={individualTask.id}>
                                                                                        <TaskRow task={individualTask} index={index} />
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            {provided.placeholder}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                                <ReactPaginate previousLabel="<" nextLabel=">" breakLabel="..." pageCount={totalPages} marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handlePageClick} containerClassName="pagination justify-content-end align-items-center mt-2 pe-0" pageClassName="page-item nowrap" pageLinkClassName="page-link" previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link" breakClassName="page-item" breakLinkClassName="page-link" activeClassName="active" />
                                            </>
                                        ) : (
                                            <div><h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">You do not have permission to access this module.</h6></div>
                                            && null
                                        )
                                    )}
                                    {activeTab === "recurring" && (
                                        Auth.innerPermission("itask_list") ? (
                                            <>
                                                <DragDropContext onDragEnd={handleDragEndRecurring}>
                                                    <Droppable droppableId="recurringTaskList">
                                                        {(provided) => (
                                                            <div className="col-md-12 table_part mt-2 p-0" ref={provided.innerRef} {...provided.droppableProps}>
                                                                <div className="table-responsive subtask-table">
                                                                    <table className="w-100">
                                                                        <thead className="pb-2">
                                                                            <tr>
                                                                                {/* <th className="fs-14 t_blue ps-0">Select</th> */}
                                                                                <th></th>
                                                                                <th>Task</th>
                                                                                <th>Type</th>
                                                                                <th>Started On</th>
                                                                                <th>Next Month Date</th>
                                                                                <th>End Date</th>
                                                                                <th>Expires On</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Array.isArray(filteredRecurringTasks) &&
                                                                                filteredRecurringTasks.map((recurringTask, index) => {
                                                                                    const task = recurringTask.tasks && recurringTask.tasks[0];
                                                                                    if (!task) return null; // Skip if no tasks exist

                                                                                    return (
                                                                                        <React.Fragment key={recurringTask.id}>
                                                                                            <RecurringTaskRow recurringTask={recurringTask} task={task} index={index} />
                                                                                        </React.Fragment>
                                                                                    );
                                                                                })}
                                                                            {provided.placeholder}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>

                                                <ReactPaginate previousLabel="<" nextLabel=">" breakLabel="..." pageCount={totalRecurringPages} marginPagesDisplayed={2} pageRangeDisplayed={3} onPageChange={handleRecurringPageClick} containerClassName="pagination justify-content-end align-items-center mt-2 pe-0" pageClassName="page-item nowrap" pageLinkClassName="page-link" previousClassName="page-item" previousLinkClassName="page-link" nextClassName="page-item" nextLinkClassName="page-link" breakClassName="page-item" breakLinkClassName="page-link" activeClassName="active" />
                                            </>
                                        ) : (
                                            <div><h6 className="fs-14 text-danger text-center fw-semibold text-capitalize mt-3">You do not have permission to access this module.</h6></div>
                                            && null
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={editTaskModal} onHide={() => setEditTaskModal(false)} centered size="xl" contentClassName="mx-auto" className="modal modal-xl email-modal" backdrop="static" keyboard={false}>
                <div className="modal-body p-4 position-relative">
                    <form onSubmit={handleSubmit(updateTaskHandle)}>
                        <div className="row">
                            <div className="col-md-8 left-task-modal p-0">
                                <div className="d-flex align-items-end justify-content-between">
                                    <div>
                                        <h4 className="fs-20">Edit Task</h4>
                                        <input
                                            type="text"
                                            className="w-bold text-black p-0 fs-3 h-0 det-input w-100"
                                            placeholder="Add Details"
                                            name="name"
                                            value={editTaskDetail?.name} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, name: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <Modal.Header closeButton className="close-icon border-0 p-0" />
                                </div>
                                <hr />
                                <div className="mt-3">
                                    <div className="input-group">
                                        <textarea
                                            name="description"
                                            id="description"
                                            cols="30"
                                            rows="15"
                                            placeholder="Type here..."
                                            className="bg-white border-1 border-gray"
                                            value={editTaskDetail?.description} // Bind the value from editTaskDetail
                                            onChange={(e) => {
                                                setEditTaskDetail({ ...editTaskDetail, description: e.target.value });
                                            }}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="main-wrapper">
                                        <div className="upload-main-wrapper d-flex align-items-center justify-content-between w-100 border-1 border-dashed gap-2">
                                            <h4 className="fs-14">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="13" height="20" viewBox="0 0 13 20">
                                                    <path id="XMLID_197_" d="M68.356,0h0a4.663,4.663,0,0,0-3.283,1.331,4.469,4.469,0,0,0-1.36,3.214v8.182a2.786,2.786,0,0,0,5.571,0V8.006a.929.929,0,0,0-1.857,0v4.721a.929.929,0,0,1-1.857,0V4.545a2.681,2.681,0,0,1,.816-1.928,2.8,2.8,0,0,1,1.97-.8,2.76,2.76,0,0,1,2.786,2.727v9.091A4.6,4.6,0,0,1,66.5,18.182a4.663,4.663,0,0,1-3.283-1.331,4.468,4.468,0,0,1-1.36-3.214V4.545a.929.929,0,0,0-1.857,0v9.091a6.256,6.256,0,0,0,1.9,4.5A6.528,6.528,0,0,0,66.5,20,6.44,6.44,0,0,0,73,13.636V4.545A4.6,4.6,0,0,0,68.356,0Z" transform="translate(-59.999)" fill="#202529" />
                                                </svg>
                                                Drag &amp; drop here
                                            </h4>
                                            <div className="upload-wrapper cursor-pointer">
                                                <input type="file" id="file" name="file" multiple onChange={handleFileChange} />
                                                <i className="fa fa-upload me-2"></i>
                                                <span className="file-upload-text cursor-pointer">Browse Files</span>
                                            </div>
                                        </div>
                                        <div className="image-preview-area">
                                            {editTaskDetail?.documents.map((preview, index) => (
                                                <div key={`preview-${preview.id}`} className="image-preview position-relative">
                                                    <img src={preview?.document_path} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(preview.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                            {selectedFiles.map((file, index) => (
                                                <div key={`file-${index}`} className="image-preview position-relative">
                                                    <img src={URL.createObjectURL(file)} alt="Preview" style={{ width: 100, height: 100 }} />
                                                    <button className="border-0 bg-transparent" type="button" onClick={() => deleteImage(index, true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 36 36">
                                                            <g id="Group_1561" data-name="Group 1561" transform="translate(-1173 -1120)">
                                                                <rect id="Rectangle_245" data-name="Rectangle 245" width="36" height="36" rx="10" transform="translate(1173 1120)" fill="#aaa" />
                                                                <path id="Union_9" data-name="Union 9" d="M1.156,10A1.162,1.162,0,0,1,.343,8.014L3.352,5,.335,1.987A1.163,1.163,0,0,1,1.981.351L4.993,3.363,8.016.34A1.163,1.163,0,0,1,9.659,1.985L6.637,5.007,9.654,8.024A1.16,1.16,0,0,1,8.007,9.66L5,6.648,1.987,9.657A1.158,1.158,0,0,1,1.164,10Z" transform="translate(1186 1133)" fill="#000" />
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-btns gap-4 mt-4 d-flex align-items-center justify-content-between gap-2">
                                    <div className="view-full">
                                        <button className="view-full">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="15" height="15" viewBox="0 0 15 15">
                                                <path id="expand" d="M5.309.938a.938.938,0,0,1-.937.938h-2.5v2.5A.938.938,0,1,1,0,4.371V.938A.938.938,0,0,1,.938,0H4.371A.938.938,0,0,1,5.309.938ZM4.371,13.125h-2.5v-2.5a.938.938,0,0,0-1.875,0v3.434A.938.938,0,0,0,.938,15H4.371a.938.938,0,0,0,0-1.875Zm9.691-3.434a.938.938,0,0,0-.937.938v2.5h-2.5a.938.938,0,0,0,0,1.875h3.434A.938.938,0,0,0,15,14.063V10.629A.937.937,0,0,0,14.063,9.691Zm0-9.691H10.629a.938.938,0,0,0,0,1.875h2.5v2.5a.938.938,0,0,0,1.875,0V.938A.937.937,0,0,0,14.063,0Z" fill="#77a0df" />
                                            </svg>
                                            View in full page
                                        </button>
                                    </div>
                                    <span className="d-flex gap-2">
                                        <a>
                                            <button className="btn-cancel">Cancel</button>
                                        </a>
                                        <a>
                                            <button className="btn-blue" type="submit">
                                                Update
                                            </button>
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 px-0">
                                <div className="right-task-modal">
                                    <div className=" col-md-12 mt-4 px-4">
                                        <label for="" className="fs-18 px-1">
                                            Interaction Type
                                        </label>
                                        <select name="interaction_type_id" id="interaction_type_id" className="form-input bg-transparent fs-20 fw-bold px-1" value={taskDetails.interaction_type_id} onChange={(e) => handleFieldChange("interaction_type_id", parseInt(e.target.value, 10))}>
                                            <option value="0" disabled>
                                                Select
                                            </option>
                                            {interactionList &&
                                                interactionList.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4">
                                        <label className="fs-18 px-1">Priority</label>
                                        <select name="priority_id" id="priority_id" className="form-input bg-transparent fs-20 fw-bold px-1" value={taskDetails.priority_id} onChange={(e) => handleFieldChange("priority_id", parseInt(e.target.value, 10))}>
                                            <option value="0" disabled selected>
                                                Select
                                            </option>
                                            {priorityList &&
                                                priorityList.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mt-4 px-4 relative">
                                        <label className="fs-18 px-1" onClick={toggleAssignOpen}>
                                            Assignee
                                        </label>
                                        <h6 className="bg-transparent fs-20 fw-bold px-1" onClick={toggleAssignOpen}>
                                            Unassigned
                                        </h6>
                                        {showEditAssignOpen && (
                                            <div className="assign-open bg-white rounded-4 p-3 mt-3 position-absolute end-0 start-0 mx-4">
                                                <ul className="ps-0">
                                                    {assignData &&
                                                        assignData.map((staff) => (
                                                            <li key={staff.id} className="d-flex justify-content-between align-items-center my-1">
                                                                <label className="fw-semibold text-black">
                                                                    <img src={require("../../../assets/images/user-pic.png")} className="me-2" alt="user-pic" />
                                                                    {staff?.name}
                                                                </label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="staff_id"
                                                                    id="staff_id"
                                                                    value={staff.id} // Ensure the value is correctly set to staff.id
                                                                    checked={assigneeIds.includes(staff.id)}
                                                                    onChange={() => toggleAssignee(staff.id)}
                                                                />
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4">
                                        <label className="fs-18 px-1">Status</label>
                                        <select name="status_task_id" id="status_task_id" className="form-input bg-transparent fs-20 fw-bold px-1" value={taskDetails.status_task_id} onChange={(e) => handleFieldChange("status_task_id", parseInt(e.target.value, 10))}>
                                            <option value="0" disabled selected>
                                                Select
                                            </option>
                                            {StatusList &&
                                                StatusList.map((data) => (
                                                    <option key={data.id} value={data.id}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4 date mt-3">
                                        <h4 className="fs-18 px-1 fw-bold">Start Date</h4>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <label>Date</label>
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    className="form-input mt-1"
                                                    value={editTaskDetail?.start_date} // Ensure this is correctly bound
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, start_date: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Time</label>
                                                <InputMask
                                                    mask="99:99"
                                                    maskChar={null}
                                                    name="start_time"
                                                    className="form-input mt-1"
                                                    placeholder="HH:MM"
                                                    value={editTaskDetail?.start_time} // Ensure this is correctly bound
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, start_time: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Format</label>
                                                <select className="form-input mt-1 px-3" name="start_time_format" value={timeFormats.start_time_format} onChange={handleFormatChange}>
                                                    <option value="PM">PM</option>
                                                    <option value="AM">AM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4 date mt-3">
                                        <h4 className="fs-18 px-1 fw-bold">Due Date</h4>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <label>Date</label>
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    className="form-input mt-1"
                                                    value={editTaskDetail?.end_date} // Ensure this is correctly bound
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, end_date: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Time</label>
                                                <InputMask
                                                    mask="99:99"
                                                    maskChar={null}
                                                    name="end_time"
                                                    className="form-input mt-1"
                                                    placeholder="HH:MM"
                                                    value={editTaskDetail?.end_time}
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, end_time: e.target.value });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <label>Format</label>
                                                <select className="form-input mt-1 px-3" name="end_time_format" value={timeFormats.end_time_format} onChange={handleFormatChange}>
                                                    <option value="PM">PM</option>
                                                    <option value="AM">AM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4 date mt-3">
                                        <h4 className="fs-18 px-1 fw-bold">Estimation Date</h4>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <input
                                                    type="text"
                                                    name="estimation_time"
                                                    className="form-input mt-1"
                                                    placeholder="1w 1d 1h 1m"
                                                    value={editTaskDetail?.estimation_time}
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, estimation_time: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4 date mt-3 mb-5">
                                        <h4 className="fs-18 px-1 fw-bold">Time Spent</h4>
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <input
                                                    type="text"
                                                    name="time_spent"
                                                    className="form-input mt-1"
                                                    placeholder="1w 1d 1h 1m"
                                                    value={editTaskDetail?.time_spent}
                                                    onChange={(e) => {
                                                        setEditTaskDetail({ ...editTaskDetail, time_spent: e.target.value });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-md-12 mt-4 px-4 date mt-3 mb-5">
                                        {editTaskDetail.custom_field &&
                                            Object.keys(editTaskDetail.custom_field).map((key) => (
                                                <div className="col-md-12 mt-3" key={key}>
                                                    <input type="text" name={key} className="form-input mt-1" placeholder={key} value={editTaskDetail.custom_field[key] || ""} onChange={handleInputChange} />
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                show={showSubTask}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={closeModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add New Subtask</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>

                <div className="modal-body mt-2">
                    <form>
                        <div className="row">
                            <div className="col-lg-12 custom-select-container w-100">
                                <label htmlFor="">Select Task</label>

                                <div className="custom-select-display mt-2 form-input-select" onClick={toggleDropdown}>
                                    {selectedOption ? selectedOption.name : 'Select an option'} {/* Show selected option or default text */}
                                </div>
                                {isOpen && (
                                    <div className="custom-select-dropdown">
                                        <div className="srchinp position-relative">
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={handleSearchChange} // Handle search input change
                                                className="custom-select-search form-input bg-transparent w-100 ps-5"
                                            />
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <div className="custom-select-options">
                                            {filteredOptions.length > 0 ? (
                                                filteredOptions.map(option => (
                                                    <div
                                                        key={option.id} // Use option.id as the unique key
                                                        className="custom-select-option"
                                                        onClick={() => handleOptionClick(option)} // Handle option selection
                                                    >
                                                        {option.name} {/* Display option name */}
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="custom-select-no-options">No options found</div> // Message when no options match
                                            )}
                                        </div>
                                    </div>
                                )}
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer border-0 mt-4">
                    <button type="submit" className="btn-blue m-0" onClick={handleFormSubmit}>Add</button>
                </div>
            </Modal>

            <Modal
                show={showRecurringSubTask}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={closeRecurringSubTaskModal} // Handle closing the modal
            >
                <div className="modal-dialog modal-dialog-centered m-0">
                    <div className="modal-content font-poppins p-3 h_500">
                        <div className="modal-header border-0 justify-content-between p-0">
                            <h4 className="fs-20">Add New Recurring Subtask</h4>
                            <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                        </div>
                        <div className="modal-body p-0 mt-2">
                            <form>
                                <div className="custom-select-container w-100">
                                    <label htmlFor="">Select Task</label>

                                    <div className="custom-select-display mt-2 form-input-select" onClick={toggleRecurringDropdown}>
                                        {selectedRecurringOption ? selectedRecurringOption.name : "Select an option"}
                                    </div>
                                    {isOpenRecurring && (
                                        <div className="custom-select-dropdown">
                                            <div className="srchinp position-relative">
                                                <input type="text" value={searchRecuriingTerm} onChange={handleRecurringSearchChange} className="custom-select-search form-input bg-transparent w-100" />
                                                <i className="fa fa-search"></i>
                                            </div>
                                            <div className="custom-select-options">
                                                {filteredRecurringOptions.length > 0 ? (
                                                    filteredRecurringOptions.map((option) => (
                                                        <div key={option.id} className="custom-select-option" onClick={() => handleRecurringOptionClick(option)}>
                                                            {option.name}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="custom-select-no-options">No options found</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {errorRecurringMessage && <p style={{ color: "red" }}>{errorRecurringMessage}</p>}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer border-0 p-0 mt-4">
                            <button type="submit" className="btn-blue m-0" onClick={handleRecurringFormSubmit}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                show={openTemplateModal}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={handleTemplateModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Template</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body mt-2">
                    <form onSubmit={handleTemplateData}>
                        <div className="row">
                            <div className="col-md-12 custom-select-container w-100">
                                <label>Select Template</label>
                                <select
                                    className="form-input mt-1 px-3 bg-white"
                                    value={selectedTemplateId} // Bind selected value to state
                                    onChange={handleTemplateChange} // Handle change
                                >
                                    <option value="0">Select</option>
                                    {/* Dynamically map through templateList and display options */}
                                    {templateList.map((template) => (
                                        <option key={template.id} value={template.id}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-5 mb-2">
                                <button type="submit" className="btn-blue m-0 px-4 ms-auto">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                show={openRecurringTemplateModal}
                centered
                className="modal operating-modal"
                backdrop="static"
                keyboard={false}
                onHide={handleRecurringTemplateModal} // Handle closing the modal
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Add Template</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body mt-2">
                    <form onSubmit={handleRecurringTemplateData}>
                        <div className="row">
                            <div className="col-md-12 custom-select-container w-100">
                                <label>Select Template</label>
                                <select
                                    className="form-input mt-1 px-3 bg-white"
                                    value={recurringSelectedTemplateId} // Bind selected value to state
                                    onChange={handleRecurringTemplateChange} // Handle change
                                >
                                    <option value="0">Select</option>
                                    {/* Dynamically map through templateList and display options */}
                                    {recurringTemplateList.map((template) => (
                                        <option key={template.id} value={template.id}>
                                            {template.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto px-4">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <AddTask addTaskModal={addTaskModal} setAddTaskModal={setAddTaskModal} setPriorityList={setPriorityList} priorityList={priorityList} handlePriorityData={handlePriorityData} interactionList={interactionList} setInteractionList={setInteractionList} handleInteractionData={handleInteractionData} StatusList={StatusList} setStatusList={setStatusList} handleStatusData={handleStatusData} assignData={assignData} setAssignData={setAssignData} handleAssisgnData={handleAssisgnData} handleTaskList={handleTaskList} />
        </div>
    );
};
export default Task;
