import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { GlobalContext } from "../../App";

const BusinessTabs = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [businessId, setBusinessId] = useState(id);
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    useEffect(() => {
        if (id) {
            localStorage.setItem('BusinessId', id);
            setBusinessId(id);
        } else {
            const businessStoreId = localStorage.getItem('BusinessId');
            if (businessStoreId) {
                setBusinessId(businessStoreId);
            }
        }
    }, [id]);

    // Update id in state when a tab is clicked
    // const handleTabClick = (newId) => {
    //     setBusinessId(newId);
    //     localStorage.setItem('BusinessId', newId);
    // };

    const handleTabClick = (newId, path) => {
        setBusinessId(newId);
        localStorage.setItem('clientId', newId);
        setShowLoader(true); // Show loader

        navigate(path, {
            state: {
                from: location.pathname
            }
        });
        // Hide loader after navigation
        setTimeout(() => setShowLoader(false), 1000);
    };


    const getTabClassName = (path) => {
        return location.pathname === path ? 'nav-link active' : 'nav-link';
    };
    return (
        <div className="row client-detail-head align-items-center">
            <div className="col-md-12 client-detail-tabs">
                <ul className="nav nav-tabs border-0">
                    <li className="nav-item me-2 rounded mt-2">
                        <button
                            className={getTabClassName(`/admin/client/business_profile/${businessId}`)}
                            onClick={() => {
                                handleTabClick(businessId);
                                navigate(`/admin/client/business_profile/${businessId}`);
                            }}
                        >
                            Home
                        </button>


                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/communication')}
                        // onClick={() => navigate('/admin/communication')}
                        >
                            Communication
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/corporate_note')}
                            onClick={() => navigate('/admin/client/corporate_note')}
                        >
                            Notes
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/files')}
                        // onClick={() => navigate('/admin/files')}
                        >
                            Files
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/corporate_task')}
                            onClick={() => navigate('/admin/client/corporate_task')}
                        >
                            Tasks
                        </button>
                    </li>
                    <li className="nav-item me-2 mt-2">
                        <button
                            className={getTabClassName('/admin/client/Transactions')}
                        // onClick={() => navigate('/admin/client/Transactions')}
                        >
                            Transactions
                        </button>
                    </li>
                </ul>
            </div>
        </div>

    );
};

export default BusinessTabs;