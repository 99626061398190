import React, { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { http } from "../../http/http";
import { toast } from "react-toastify";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GlobalContext } from "../../../App";
import moment from "moment";

const Spouse = (props) => {
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        watch,
    } = useForm({ mode: "all" });
    const formValues = watch();
    const navigate = useNavigate();
    const clientId = JSON.parse(localStorage.getItem("clientId"));
    const { showLoader, setShowLoader } = useContext(GlobalContext);
    const { fetchSpouData, spouseModal, setSpouseModal } = props;
    const [usersSpouse, setUsersSpouse] = useState([]);
    const [address, setAddress] = useState('');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [clientList, setClientList] = useState([]); // Store client list


    const [filteredClients, setFilteredClients] = useState([]); // Filtered client list

    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false); // Handle loading state
    const [error, setError] = useState(null); // Handle errors
    const [names, setNames] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientProfile, setClientProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isLinked, setIsLinked] = useState(false);
    const [linkDirectorData, setLinkDirectorData] = useState([])
    const [isClientSinVisible, setIsClientSinSinVisible] = useState(false);
    const officeLocation = clientProfile?.office_location;

    // Safely split office location into its components, assuming it's in the expected format
    const addressParts = officeLocation?.split(",") || [];
    const streetAddress = (addressParts[0]?.trim() || "") + (addressParts[1] ? ", " + addressParts[1]?.trim() : ""); // e.g., "2565 Argentia Rd, Mississauga"
    const provinces = (addressParts[2]?.trim().split(" ")[0] || "N/A"); // e.g., "ON"
    const postalCode = (addressParts[2]?.trim().split(" ").slice(1).join(" ") || "N/A"); // e.g., "L5N 5V4"
    const [selectedLinkOption, setSelectedLinkOption] = useState("0");
    const [contactProfile, setContactProfile] = useState(null);



    const handleLinkOptionChange = (event) => {
        const selectedOption = event.target.value;


        setSelectedLinkOption(selectedOption);
        setSearchTerm('');
        setNames([]);
        setClientProfile(null); // Clear previous client profile
        setContactProfile(null); // Clear previous contact profile
    };
    const onSubmit = () => {
        setShowLoader(true);
        const newUser = {
            ...formValues,
        };
        setUsersSpouse([...usersSpouse, newUser]);
        http(
            {
                method: "POST",
                url: `client/spouse/${id}/add`,
                body: {
                    first_name: formValues.first_name,
                    middle_name: formValues.middle_name,
                    last_name: formValues.last_name,
                    sin: formValues.sin,
                    contact_number: formValues.contact_number,
                    email: formValues.email,
                    province: formValues.province,
                    postal_code: formValues.postal_code,
                    date_of_birth: formValues.date_of_birth,
                    country_id: formValues.country_id,
                    city: formValues.city,
                    date_of_landing: formValues.date_of_landing,
                    mis_notes: formValues.mis_notes,
                    address: formValues.address,
                },
                isSecure: true,
            },
            (res) => {
                setSpouseModal(false);
                toast.success("Individual client spouse successfully added");
                reset();
                fetchSpouData();
                setShowModal(false);
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
            },
            (err) => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 500);
                console.error("Error Add Contact:", err);
            }
        );
    }

    const onHideHandler = () => {
        setSpouseModal(false);

    };

    const handleSelect = async (value) => {
        setAddress(value);

        try {
            const results = await geocodeByAddress(value);

            if (results && results[0]) {
                const addressComponents = results[0].address_components;
                const formattedAddress = results[0].formatted_address;
                let selectedProvince = '';
                let postalCode = '';
                let selectedCity = '';

                // Extract the province (administrative_area_level_1) and postal code
                addressComponents.forEach((component) => {
                    if (component.types.includes('administrative_area_level_1')) {
                        selectedProvince = component.short_name; // Use `long_name` for full province name
                    }
                    if (component.types.includes('postal_code')) {
                        postalCode = component.short_name; // or long_name if you want the full postal code
                    }
                    if (component.types.includes('locality')) {
                        selectedCity = component.long_name; // City name
                    }
                });


                // Set the selected address, province, and postal code in the form
                setValue('address', formattedAddress);
                setValue('province', selectedProvince);
                setValue('postal_code', postalCode);
                setValue('city', selectedCity);
                setCity(selectedCity);
                setProvince(selectedProvince); // Update the province state
            } else {
                console.warn('No results found for the selected address.');
            }
        } catch (error) {
            // Improved error logging
            console.error('Error selecting place:', error?.message || 'No message', error?.stack || 'No stack trace');
        }
    };


    const handleChange = (value) => {
        setAddress(value);
    };



    // Function to handle search input changes
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value) {
            handleSinSearch(event.target.value); // Trigger API call on search
        }
    };
    // Function to make API request when user searches
    // const handleSinSearch = async (searchQuery) => {
    //     try {
    //         setLoading(true); // Set loading to true when API call starts
    //         http(
    //             {
    //                 method: "POST",
    //                 url: "clients/search/clientlistingbyname",
    //                 isSecure: true,
    //                 body: {
    //                     search: searchQuery, // Sending search query as the body
    //                 }
    //             },
    //             (res) => {
    //                 setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
    //                 setLoading(false); // Set loading to false after data is fetched
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 setError("Failed to fetch names"); // Set error message
    //                 setLoading(false); // Ensure loading is set to false on error
    //             }
    //         );
    //     } catch (err) {
    //         console.error(err);
    //         setError("Failed to fetch names"); // Set error message
    //         setLoading(false); // Ensure loading is set to false on error
    //     }
    // };

    const handleSinSearch = async (searchQuery) => {
        try {
            setLoading(true); // Set loading to true when API call starts
            const url =
                selectedLinkOption === "1"
                    ? "clients/search/clientlistingbyname" // API for Individual
                    : "contact/search/contactbyname";      // API for Contact

            http(
                {
                    method: "POST",
                    url: url,
                    isSecure: true,
                    body: {
                        search: searchQuery, // Sending search query as the body
                        client_type: "1",
                        type: "individual"
                    }
                },
                (res) => {
                    setNames(res.data?.data || []); // Assuming the response has a 'data' field with the names list
                    setLoading(false); // Set loading to false after data is fetched
                },
                (err) => {
                    console.error(err);
                    setError("Failed to fetch names"); // Set error message
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError("Failed to fetch names"); // Set error message
            setLoading(false); // Ensure loading is set to false on error
        }
    };


    // Filter the names based on the search term, filtering by the `full_name` field
    // const filteredNames = names.filter((client) =>
    //     client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    const filteredNames = names.filter((item) => {
        // Check if `full_name` exists (client) and filter based on it
        if (item.full_name) {
            return item.full_name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        // If `full_name` does not exist, check for `first_name` or `company_name` (contact) and filter based on them
        return (
            (item.first_name && item.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item.company_name && item.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });
    // Function to handle clicking on a name and show the modal
    // const handleNameClick = (client) => {
    //     setSelectedClient(client); // Store the clicked client's information
    //     handleClientProfile(client.id); // Fetch the client profile using the selected client's ID
    //     setShowModal(true); // Show the modal
    // };

    const handleNameClick = (item) => {
        setSelectedClient(item);

        const isClient = item.hasOwnProperty("full_name");
        const profileType = isClient ? "client" : "contact";


        handleClientProfile(item.id, profileType);
        setShowModal(true);
    };

    // Function to close the modal
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedClient(null); // Clear the selected client when modal closes
    };
    // Function to make API request to get client profile
    // const handleClientProfile = (clientId) => {
    //     try {
    //         setLoading(true); // Set loading to true when API call starts
    //         http(
    //             {
    //                 method: "GET",
    //                 url: `clients/${clientId}/view`, // API call with selected client's ID
    //                 isSecure: true
    //             },
    //             (res) => {
    //                 setClientProfile(res.data || []); // Store the client profile data
    //                 setLoading(false); // Set loading to false after data is fetched
    //             },
    //             (err) => {
    //                 console.error(err);
    //                 setError("Failed to fetch client profile"); // Set error message
    //                 setLoading(false); // Ensure loading is set to false on error
    //             }
    //         );
    //     } catch (err) {
    //         console.error(err);
    //         setError("Failed to fetch client profile"); // Set error message
    //         setLoading(false); // Ensure loading is set to false on error
    //     }
    // };


    const handleClientProfile = (id, type) => {
        // Dynamic URL for fetching client or contact profile
        const url = type === "client" ? `clients/${id}/view` : `contact/${id}/view`;

        try {
            setLoading(true); // Set loading to true before making the API request
            http(
                {
                    method: "GET",
                    url,
                    isSecure: true,
                },
                (res) => {
                    // Depending on the type, set the appropriate profile data
                    if (type === "client") {
                        setClientProfile(res.data); // Set client profile data
                    } else {
                        setContactProfile(res.data); // Set contact profile data
                    }
                    setLoading(false); // Set loading to false after fetching the data
                },
                (err) => {
                    console.error(err);
                    setError(`Failed to fetch ${type} profile`);
                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);
            setError(`Failed to fetch ${type} profile`);
            setLoading(false); // Ensure loading is set to false on error
        }
    };

    const handleLinkSpouse = () => {
        const profileId = clientProfile?.id || contactProfile?.id;
        try {
            setLoading(true); // Set loading to true when API call starts
            http(
                {
                    method: "POST",
                    // url: `corporate/director/${clientProfile?.id}/${businessId}/addclient`,
                    url: `client/spouse/${profileId}/${clientId}/addclient`,
                    isSecure: true,
                    body: {
                        link_to: selectedLinkOption,
                        id: contactProfile?.id || clientProfile?.id // Dynamically assign id

                    }

                },
                (res) => {
                    setLinkDirectorData(res || []); // Assuming the response has a 'data' field with the names list
                    setIsLinked(true);
                    setLoading(false); // Set loading to false after data is fetched
                    onHideHandler();
                    setShowModal(false);
                },
                (err) => {
                    console.error(err);

                    setLoading(false); // Ensure loading is set to false on error
                }
            );
        } catch (err) {
            console.error(err);

            setLoading(false); // Ensure loading is set to false on error
        }
    };

    const renderClientDetails = () => (
        <>
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">
                            {(clientProfile ? clientProfile.first_name : contactProfile?.first_name) || 'N/A'}{' '}
                            {(clientProfile ? clientProfile.last_name : contactProfile?.last_name) || ''}
                        </h6>
                        <span className="d-flex align-items-center gap-2">
                            <img src={require("../../../assets/images/email.png")} className="emailicon" alt="" />
                            <p className="fs-14 text-muted">
                                {(clientProfile ? clientProfile.email : contactProfile?.email) || 'N/A'}
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <hr className="my-3" />
            <div className="row">
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">First Name</p>
                    <h4 className="fs-18">{clientProfile?.first_name}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Last Name</p>
                    <h4 className="fs-18">{clientProfile?.last_name}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Email</p>
                    <h4 className="fs-18">{clientProfile?.email || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">
                        SIN
                        {isClientSinVisible ? (
                            <img
                                src={require("../../../assets/images/eye-hide.png")}
                                className="cursor-pointer ms-2 w--14"
                                alt="Hide SIN"
                                onClick={toggleClientSinVisibility} // On click, hide the SIN
                            />
                        ) : (
                            <img
                                src={require("../../../assets/images/eye.png")}
                                className="cursor-pointer ms-2 w--14"
                                alt="Show SIN"
                                onClick={toggleClientSinVisibility} // On click, show the SIN
                            />
                        )}
                    </p>

                    <h4 className="fs-18">
                        {clientProfile?.sin
                            ? isClientSinVisible
                                ? clientProfile.sin // Show full SIN if visible
                                : clientProfile.sin.replace(/\d(?=\d{2})/g, "*") // Mask SIN except last 2 digits
                            : ""}
                    </h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Phone No.</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.primary_contact_person || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Street Address</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.office_location || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">City</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.city || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Province</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.province || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Postal Code</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.postal_code || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Shareholder (%)</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.share_holder || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Date of Birth</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.date_of_birth ? moment(clientProfile.date_of_birth).format('DD MMM, YYYY') : 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Date of Landing</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.date_of_landing ? moment(clientProfile.date_of_landing).format('DD MMM, YYYY') : 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Marital Status</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.marital_status ? (clientProfile.marital_status === "1" ? "Married" : "Single") : 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Family Head</p>
                    <h4 className="fs-18 fw-semibold">{clientProfile?.family_head || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Gender</p>
                    <h4 className="fs-18">{clientProfile?.gender ? (clientProfile.gender === "1" ? "Male" : "Female") : 'N/A'}</h4>
                </div>
                <div className="d-flex justify-content-between gap-4 mt-4 px-0">
                    <button
                        type="button"
                        className="btn btn-gray text-dark mt-3"
                        onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}
                    >
                        Go to Profile
                    </button>
                    <div className="d-flex align-items-center gap-2">
                        <button
                            type="button"
                            className="btn btn-lightblue mt-3"
                            onClick={handleLinkSpouse} // Call the link function directly
                        >
                            {loading ? "Linking..." : isLinked ? "Linked" : "Link to ShareHolder"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );

    const renderContactDetails = () => (
        <>
            <div className="modal-header p-0 justify-content-between align-items-center border-0">
                <div className="lhead d-flex align-items-center gap-3">
                    <img src={require("../../../assets/images/userman.jpg")} className="rounded-circle" alt="" />
                    <div>
                        <h6 className="fs-18 fw-semibold">
                            {contactProfile?.first_name} {contactProfile?.last_name}
                            {contactProfile?.primary_contact === "1" && (
                                <span className="fs-12 text-blue"> Primary</span>
                            )}
                        </h6>

                        <span className="d-flex align-items-center gap-2">
                            <p className="fs-14 text-muted">{contactProfile?.designation?.name}</p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">First Name</p>
                    <h4 className="fs-18">{contactProfile?.first_name}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Last Name</p>
                    <h4 className="fs-18">{contactProfile?.last_name}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Email</p>
                    <h4 className="fs-18">{contactProfile?.email || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Phone No.</p>
                    <h4 className="fs-18">{contactProfile?.phone_number || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Designation</p>
                    <h4 className="fs-18">{contactProfile?.designation?.name || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Street Address</p>
                    <h4 className="fs-18">{contactProfile?.address || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">City</p>
                    <h4 className="fs-18">{contactProfile?.city || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Province</p>
                    <h4 className="fs-18">{contactProfile?.province || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Postal Code</p>
                    <h4 className="fs-18">{contactProfile?.postal_code || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Notes</p>
                    <h4 className="fs-18">{contactProfile?.notes || 'N/A'}</h4>
                </div>
                <div className="col-lg-6 mb-3 p-0">
                    <p className="fs-14">Shareholder (%)</p>
                    <h4 className="fs-18">{contactProfile?.share_holder || 'N/A'}</h4>
                </div>
                <div className="d-flex justify-content-between gap-4 mt-4">
                    {/* <button
                        type="button"
                        className="btn btn-gray text-dark mt-3"
                        onClick={() => navigate(`/admin/client/individual_profile/${clientProfile?.id}`)}
                    >
                        Go to Profile
                    </button> */}
                    <div className="d-flex align-items-center gap-2">
                        {/* {clientProfile?.share_holder ? (
                            <button
                                type="button"
                                className="btn btn-lightblue mt-3"
                                onClick={handleLinkDirector} // Call the link function directly
                            >
                                {loading ? "Linking..." : isLinked ? "Linked" : "Link to Director"}
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-gray mt-3"
                                onClick={handleButtonClick} // Open modal to enter shareholder info
                            >
                                Enter Shareholder
                            </button>
                        )} */}
                        <button
                            type="button"
                            className="btn btn-lightblue mt-3"
                            onClick={handleLinkSpouse} // Call the link function directly
                        >
                            {loading ? "Linking..." : isLinked ? "Linked" : "Link to Spouse"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
    const toggleClientSinVisibility = () => {
        setIsClientSinSinVisible(!isClientSinVisible); // Toggle the state
    };

    return (
        <>
            <Modal
                show={spouseModal}
                onHide={onHideHandler}
                centered
                size="xl"
                contentClassName="mx-auto"
                className="modal operating-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-header border-0 justify-content-between">
                    <h4 className="fs-20 fw-semibold">Spouse</h4>
                    <Modal.Header closeButton className="close-icon border-0"></Modal.Header>
                </div>
                <div className="modal-body">
                    <div className="row my-3">
                        <div className="col-md-6 dctor-search">
                            <label htmlFor="">Link To</label>
                            <div className="d-flex align-items-center gap-4 mt-2">
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="custom"
                                        value="0"
                                        checked={selectedLinkOption === "0"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="custom" className="fs-14">Custom</label>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="individual"
                                        value="1"
                                        checked={selectedLinkOption === "1"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="individual" className="fs-14">Individual</label>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="radio"
                                        id="contact"
                                        value="2"
                                        checked={selectedLinkOption === "2"}
                                        onChange={handleLinkOptionChange}
                                    />
                                    <label htmlFor="contact" className="fs-14">Contact</label>
                                </div>
                            </div>
                        </div>
                        {selectedLinkOption !== "0" && (
                            <div className="col-md-6 dctor-search">
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-input dtr-srch"
                                        placeholder="Search for a name"
                                        value={searchTerm}
                                        onChange={handleSearch} // Handle input changes
                                        disabled={selectedLinkOption === "0"}
                                    />
                                    <i className="fa fa-search"></i>
                                    <i className="fa fa-times" onClick={() => setSearchTerm('')}></i>
                                    {searchTerm && (
                                        <ul>
                                            {loading ? (
                                                <li>Loading...</li>
                                            ) : error ? (
                                                <li>{error}</li>
                                            ) : filteredNames.length > 0 ? (
                                                filteredNames.map((item) => (
                                                    <li className="justify-content-start" key={item.id} onClick={() => handleNameClick(item)}>
                                                        {/* Check if item has `full_name` (client) or `first_name` and `company_name` (contact) */}
                                                        {item.full_name ? (
                                                            <>
                                                                <strong>{item.full_name}</strong> {/* Display client full name */}
                                                                <p>{item.company_name}</p> {/* Display company name if available */}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <strong>{item.first_name}</strong> {/* Display contact first name */}
                                                                {item.company_name && (
                                                                    <>

                                                                        <strong>{item.company_name}</strong>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No names found</li>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="advance-form row">
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className="form-input mt-2"
                                    {...register("first_name", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.first_name && <span className="error">{errors?.first_name.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Middle Name</label>
                                <input
                                    type="text"
                                    name="middle_name"
                                    id="middle_name"
                                    className="form-input mt-2"
                                    {...register("middle_name", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Last Name <span className="text-danger">*</span></label>
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className="form-input mt-2"
                                    {...register("last_name", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.last_name && <span className="error">{errors?.last_name.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">SIN</label>
                                <input
                                    type="password"
                                    name="sin"
                                    id="sin"
                                    placeholder="Type here..."
                                    className="form-input mt-2"
                                    maxLength={9} // Limit to 9 digits
                                    inputMode="numeric" // Display numeric keyboard on mobile devices
                                    {...register("sin", {
                                        pattern: {
                                            value: /^\d+$/, // Allow only numbers

                                        },
                                    })}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Replace non-numeric characters
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Email Address <span className="text-danger">*</span></label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="form-input mt-2"
                                    {...register("email", {
                                        required: "Required ",
                                    })}
                                />
                                {errors?.email && <span className="error">{errors?.email.message}</span>}
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Phone No.</label>
                                <input
                                    type="tel"
                                    name="contact_number"
                                    id="contact_number"
                                    className="form-input mt-2"
                                    {...register("contact_number", {

                                    })}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === "e" || // Prevent 'e'
                                            e.key === "E" || // Prevent 'E'
                                            e.key === "+" || // Prevent '+'
                                            e.key === "-" || // Prevent '-'
                                            e.key === "." || // Prevent '.'
                                            (isNaN(Number(e.key)) &&
                                                e.key !== "Backspace" &&
                                                e.key !== "ArrowLeft" &&
                                                e.key !== "ArrowRight")
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Date of Birth</label>
                                <input
                                    type="date"
                                    name="date_of_birth"
                                    id="date_of_birth"
                                    className="form-input mt-2"
                                    {...register("date_of_birth", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Date of Landing</label>
                                <input
                                    type="date"
                                    name="date_of_landing"
                                    id="date_of_landing"
                                    className="form-input mt-2"
                                    {...register("date_of_landing", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 mt-3">
                                <label className="fs-18">Country of Residence</label>
                                <select
                                    className="form-input mt-2"
                                    name="country_id"
                                    id="country_id"
                                    {...register("country_id", {

                                    })}

                                >
                                    <option value="" disabled selected>Select</option>
                                    <option value="1" >Canada</option>
                                    <option value="2">USA</option>
                                    {/* <option value="3">India</option>
                                    <option value="4">Other</option> */}
                                </select>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">City</label>
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={city} // Bind the province state to this input field
                                    readOnly
                                    className="form-input mt-2"
                                    {...register("city", {

                                    })}

                                />
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                <label className="fs-18">Street Address</label>
                                {/* <input
                                type="text"
                                name="address"
                                id="address"
                                className="form-input mt-2 google-address"
                                {...register("address", {

                                })}
                            /> */}

                                <PlacesAutocomplete
                                    value={address}
                                    onChange={handleChange}  // Handle input change here
                                    onSelect={handleSelect}  // Handle address selection here
                                    searchOptions={{
                                        componentRestrictions: { country: ['us', 'ca'] },
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Type address here...',
                                                    className: 'form-input mt-2 google-address',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map((suggestion) => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                            })}
                                                            key={suggestion.placeId}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Province</label>
                                {/* <select className="form-input mt-2"
                                name="province"
                                id="province"

                                {...register("province", {


                                })}
                            >
                                <option value="" disabled selected>Select</option>
                                <option value="AB">AB</option>
                                <option value="BC">BC</option>
                                <option value="MB">MB</option>
                                <option value="NB">NB</option>
                                <option value="NL">NL</option>
                                <option value="NS">NS</option>
                                <option value="NT">NT</option>
                                <option value="NU">NU</option>
                                <option value="ON" selected="">ON</option>
                                <option value="PE">PE</option>
                                <option value="QC">QC</option>
                                <option value="SK">SK</option>
                                <option value="YT">YT</option>
                            </select> */}
                                <input
                                    className="form-input mt-2"
                                    name="province"
                                    id="province"
                                    value={province} // Bind the province state to this input field
                                    readOnly
                                    {...register("province", {})}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-md-12 mt-3">
                                <label className="fs-18">Postal Code</label>
                                {/* <input
                                type="text"
                                nmae="postal_code"
                                id="postal_code"
                                className="form-input mt-2"
                                {...register("postal_code", {


                                })}

                            /> */}
                                <input
                                    type="text"
                                    name="postal_code"
                                    id="postal_code"
                                    className="form-input mt-2"
                                    placeholder="Type here..."
                                    readOnly
                                    {...register("postal_code", {

                                    })}
                                />
                            </div>
                            <div className="col-lg-8 col-md-12 col-md-12 mt-3">
                                <label className="fs-18">Misc. Note</label>
                                <input
                                    type="text"
                                    name="mis_notes"
                                    id="mis_notes"
                                    className="form-input mt-2"
                                    {...register("mis_notes", {
                                    })}
                                />
                            </div>
                            <div className="col-lg-12 mt-4 mb-2">
                                <button type="submit" className="btn-blue ms-auto">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="lg"
                contentClassName="mx-auto p-4 h-auto"
                className="modal pin-modal font-poppins"
                backdrop="static"
                keyboard={false}
            >

                <Modal.Header closeButton className="close-icon border-0 p-0"></Modal.Header>
                <div className="services-form row">
                    <div className="col-lg-12 p-0">
                        <form >
                            {clientProfile ? renderClientDetails() : contactProfile ? renderContactDetails() : <p>No details available</p>}
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default Spouse;