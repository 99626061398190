import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {},
    userSuperData: {},
    userProfileData: {},
    clientContatDetail: {},
    hstListData: {},
    interaction_type_List: {},
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    userSuperData: (state, action) => {
      state.userSuperData = action.payload;
    },
    userProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    clientContatDetail: (state, action) => {
      state.clientContatDetail = action.payload;
    },
    hstList: (state, action) => {
      state.hstListData = action.payload;
    },
    setInteraction_List: (state, action) => {
      state.interaction_type_List = action.payload;
    },
  },
});

export const { setUserData, userSuperData, userProfileData, clientContatDetail, hstListData, setInteraction_List } = userSlice.actions;
export const selectUser = (state) => state.user;

export default userSlice.reducer;
